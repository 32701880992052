import {mapActions, mapGetters, mapMutations} from "vuex";

export const DELIVERME_MODULE_NAME = "deliverme";
export const DELIVERME_MODAL_ACTIONS = {
    ADD: "add",
    EDIT: "edit",
};

const initState = {
    is_connected: false,
    videos: [],
    selected_video: null,
    modal: null,

    video_ffprobe: null
};

const DeliverMEModule = {
    namespaced: true,
    state: () => initState,
    mutations: {
        // COMMON
        RESET(state) {
            state.is_connected = false;
            state.videos = [];
            state.selected_video = null;
            state.modal = null;
        },
        connected(state, flag) {
            state.is_connected = flag;
        },

        // List Video
        index(state, {body: videos, params}) {
            state.videos = videos.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );
        },

        // Create Video
        create(state, {body: video, params}) {
            // console.log("DELIVER_ME", { body: video, params });
            state.videos.unshift(video);
        },

        // Fetch Single Video Data
        edit(state, {body: video, params}) {
            // console.log("DELIVER_ME_EDIT", { body: video, params });
            state.selected_video = video || null;
        },

        // Delete Video
        destroy(state, {body: video, params}) {
            let index = state.videos.findIndex(
                (v) => v.video_hashid === video.table.video_hashid
            );
            if (index > -1) {
                state.videos.splice(index, 1);
            }
        },

        // Update Video
        update(state, {body: video, params}) {
            // console.log("DELIVER_ME_Update", { body: video, params });

            let index = state.videos.findIndex(
                (v) => v.video_hashid === video.video_hashid
            );
            if (index > -1) {
                state.videos.splice(index, 1, video);
            }
        },

        ffprobe(state, {body: data}) {
            state.video_ffprobe = JSON.parse(data || "");
        },

        modalData(state, data) {
            if (state.modal && state.modal.action === DELIVERME_MODAL_ACTIONS.EDIT) {
                state.selected_video = null;
            }
            state.modal =
                (typeof data === "string" ? {action: data} : data) || null;
            state.video_ffprobe = null;
        },
    },

    actions: {
        // close - this.manageModal();
        // add - this.manageModal("add")
        // edit - this.manageModal("edit")
        // OR edit - this.manageModal({action: "edit", payload: "dad23dg34"})
        manageModal({commit}, data) {
            commit("modalData", data);
            $("#deliverme-modal").modal(data ? "show" : "hide");
        },
    },
    getters: {
        is_connected: (s) => s.is_connected,
        videos: (s) =>
            s.videos.map((v) => {
                return {
                    hash: `${v.name} ${v.number} ${v.filename}`.toUpperCase(),
                    ...v,
                };
            }),
        selected_video: (s) => s.selected_video,
        video_ffprobe: (s) => s.video_ffprobe,

        modal: (s) => s.modal,
    },
};

export const mapDeliverMeActions = (data) =>
    mapActions(DELIVERME_MODULE_NAME, data);
export const mapDeliverMeGetters = (data) =>
    mapGetters(DELIVERME_MODULE_NAME, data);

export const mapDeliverMeMutations = (data) =>
    mapMutations(DELIVERME_MODULE_NAME, data);

export default DeliverMEModule;
