import {RouteConfig} from "vue-router";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "views" <<
 * where ever we mention this, it will group all components in one group.
 */
const ViewRoutes = [
    {
        path: "dashboard",
        name: "admin.view",
        component: () => import(/* webpackChunkName: "views" */ "./View.vue"),
        meta:{sidebar:"AdminSidebar"},
        children: [
            {
                path: "",
                name: 'admin.view.index',
                component: () => import(/* webpackChunkName: "views" */'./pages/AdminIndex.vue'),
                meta:{sidebar:"AdminSidebar"},
            },
            {
                path: "projects/:project_hashid/billing",
                name: 'admin.view.billing',
                component: () => import(/* webpackChunkName: "views" */'./pages/components/ProjectBilling.vue'),
                meta:{sidebar:"AdminSidebar"},
            },

            {
                path: "pricing-plans",
                name: 'admin.pricing',
                component: () => import(/* webpackChunkName: "views" */'./pages/components/PricingView.vue'),
                meta:{sidebar:"AdminSidebar"},
            },
            {
                path: ":pricing_plan_hashid/projects",
                name: "admin.pricing.projects",
                component: () => import(/* webpackChunkName: "views" */'./pages/components/PlanProjectsView.vue'),
                meta:{sidebar:"AdminSidebar"},
            },
            {
                path: ":pricing_plan_hashid/billing",
                name: "admin.pricing.billing",
                component: () => import(/* webpackChunkName: "views" */'./pages/components/BillingPlanProjectsView.vue'),
                meta:{sidebar:"AdminSidebar"},
            },

            {
                path: "invoices",
                name: 'admin.invoices',
                component: () => import(/* webpackChunkName: "views" */'./pages/components/InvoicesView.vue'),
                meta:{sidebar:"AdminSidebar"},
            },
            {
                path: "new-users",
                name: 'admin.new_users',
                component: () => import(/* webpackChunkName: "views" */'./pages/components/NewUserView.vue'),
                meta:{sidebar:"AdminSidebar"},
            },

        ]
    },
];

export default ViewRoutes;
