import { RouteConfig } from "vue-router";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "profilesettings" <<
 * where ever we mention this, it will group all components in one group.
 */
const ProfileSettingsRoutes = [
  {
    path: "/profile",
    name: "profile",
    redirect: { name: "profilesettings.index" },
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: 'profilesettings' */ "./ProfileSettings.vue"),
    children: [
      {
        path: "",
        name: "profilesettings.index",
        component: () =>
          import(
            /* webpackChunkName: "profilesettings" */ "./pages/ProfileSettingsIndexv2.vue"
          ),
        meta: {
          sidebar: "none",
          topbar: true,
          mesuite_logo: true,
        },
      },
    ],
  },
];

export default ProfileSettingsRoutes;
