import {mapActions, mapGetters, mapMutations} from "vuex";
import {DIRECTME_MODAL_ACTIONS} from "../../DirectME/store/directme.vuex";

export const PAYME_MODULE_NAME = "pay_mes";
export const PAYME_MODAL_ACTIONS = {
    IMPORT: "import",
    EDIT: "edit",
    ACCOUNT: "account",
    NOTES: "notes",
    REVIEW: "review",
};

const initState = {
    is_connected: false,
    pay_mes: [],
    accounts: [],
    modal: null,
    selected_receipt: null,
    analyze_data: null,
};

const PayMEModule = {
    namespaced: true,
    state: () => initState,
    mutations: {
        // COMMON
        RESET(state) {
            state.is_connected = false;
            state.pay_mes = [];
            state.accounts = [];
            state.modal = null;
            state.selected_receipt = null;
            state.selected_account = null;
            state.analyze_data = {};
        },
        connected(state, flag) {
            state.is_connected = flag;
        },

        // Paymes //

        //LIST Paymes
        index(state, {body: pay_mes, params}) {
            pay_mes.receipts.sort(
                (a, b) =>
                    new Date(b.created_at) - new Date(a.created_at)
            );
            state.pay_mes = pay_mes.receipts;
            state.accounts = pay_mes.accounts;
        },

        //CREATE Payme
        create(state, {body: payload, params}) {
            if (payload) {
                state.pay_mes.unshift(payload);
            }
        },

        // Analyze
        analyze(state, {body: payload}) {
            state.analyze_data = payload;
        },

        //UPDATE single Receipt data
        update(state, {body: receipt, params}) {
            if (receipt) {
                const index = state.pay_mes.findIndex(
                    (b) => b.payme_hashid === receipt.payme_hashid
                );
                if (index > -1) {
                    state.pay_mes.splice(index, 1, receipt);
                }
            }
        },

        //FETCH single Receipt data
        set_payme(state, receipt) {
            if (receipt) {
                state.selected_receipt = receipt || null;
            }
        },

        //DELETE Receipt
        destroy(state, {body: receipt, params}) {
            const index = state.pay_mes.findIndex(
                (b) => b.receipt_hashid === receipt.table.receipt_hashid
            );
            if (index > -1) {
                state.pay_mes.splice(index, 1);
            }
        },

        // Account //
        //CREATE Account
        create_account(state, {body: account, params}) {
            if (account) {
                state.accounts.push(account);
            }
        },

        //FETCH single Account data
        update_account(state, {body: account, params}) {
            if (account) {
                const index = state.accounts.findIndex(
                    (b) => b.account_hashid === account.account_hashid
                );
                if (index > -1) {
                    state.accounts.splice(index, 1, account);
                }
            }
        },

        //DELETE Account
        destroy_account(state, {body: account, params}) {
            const index = state.accounts.findIndex(
                (b) => b.account_hashid === account.table.account_hashid
            );
            if (index > -1) {
                state.accounts.splice(index, 1);
            }
        },

        // NOTES //
        // Fetch Notes
        index_notes(state, {body: payload, params}) {
            state.selected_receipt.notes = payload.notes;
        },

        // Create Note
        create_note(state, {body: note, params}) {
            if (note) {
                state.selected_receipt.notes.unshift(note);
                const index = state.pay_mes.findIndex(p => p.payme_hashid === params.payme_hashid);
                if (index > -1) {
                    state.pay_mes.splice(index, 1, {
                        ...state.pay_mes[index],
                        ...state.selected_receipt,
                        note: note,
                        note_total: state.selected_receipt.notes.length
                    });
                }
            }
        },
        update_note(state, {body: note, params}) {
            if (note) {
                let index = state.selected_receipt.notes.findIndex(
                    (b) => b.id === note.id
                );
                if (index > -1) {
                    state.selected_receipt.notes.splice(index, 1, note);
                }
                index = state.pay_mes.findIndex(p => p.payme_hashid === params.payme_hashid);
                if (index > -1) {
                    state.pay_mes.splice(index, 1, {
                        ...state.pay_mes[index],
                        ...state.selected_receipt,
                        note: note,
                        note_total: state.selected_receipt.notes.length
                    });
                }
            }
        },
        // Delete Note
        destroy_note(state, {body: payload, params}) {
            if (payload) {
                let index = state.selected_receipt.notes.findIndex(
                    (b) => b.id === payload.table.id
                );
                if (index > -1) {
                    state.selected_receipt.notes.splice(index, 1);
                }
                index = state.pay_mes.findIndex(p => p.payme_hashid === params.payme_hashid);
                if (index > -1) {
                    state.pay_mes.splice(index, 1, {
                        ...state.pay_mes[index],
                        ...state.selected_receipt,
                        note: state.selected_receipt.notes.length > 0 ? state.selected_receipt.notes[state.selected_receipt.notes.length - 1] : null,
                        note_total: state.selected_receipt.notes.length
                    });
                }
            }
        },

        modalData(state, data) {
            if (state.modal) {
                switch (state.modal.action) {
                    case PAYME_MODAL_ACTIONS.EDIT:
                    case PAYME_MODAL_ACTIONS.NOTES:
                    case PAYME_MODAL_ACTIONS.REVIEW:
                        this.selected_receipt = null;
                        break;
                }
            }
            state.modal =
                (typeof data === "string" ? {action: data} : data) || null;
        },
    },
    actions: {
        // close - this.manageModal();
        // add - this.manageModal("add")
        // edit - this.manageModal("edit")
        // OR edit - this.manageModal({action: "edit", payload: "dad23dg34"})
        manageModal({commit}, data) {
            commit("modalData", data);
            $("#payme-modal").modal(data ? "show" : "hide");
        },
    },
    getters: {
        is_connected: (s) => s.is_connected,
        pay_mes: (s) => s.pay_mes.map(p => ({...p, hash: `${p.company_name} `.toUpperCase()})),
        accounts: (s) => s.accounts,
        selected_receipt: (s) => s.selected_receipt,
        modal: (s) => s.modal,
        analyze_data: (s) => s.analyze_data,
    },
};

export const mapPayMEActions = (data) => mapActions(PAYME_MODULE_NAME, data);
export const mapPayMEGetters = (data) => mapGetters(PAYME_MODULE_NAME, data);

export const mapPayMEMutations = (data) =>
    mapMutations(PAYME_MODULE_NAME, data);

export default PayMEModule;
