import { version } from "os";
import { RELEASEME_MODAL_ACTIONS } from "./store/releaseme.vuex";

export const FIELD_TYPES = {
  TEXT: "text",
  NUMERIC: "numeric",
  CURRENCY: "currency",
  DATE: "date",
  DATETIME: "datetime",
  CHECKBOX: "checkbox",
  PHOTOS: "photos",
  FILES: "files",
  BUSINESS_DOCUMENTS: "business_docs",
  SIGNATURE: "signature",
  PAGE_BREAK: "pagebreak",
  SONG_DETAILS: "songdetails",
};

export const FIELD_LIST = [
  { title: "Text", field_type: FIELD_TYPES.TEXT, icon: "text_field.svg" },
  { title: "Signature", field_type: FIELD_TYPES.SIGNATURE, icon: "signature.svg" },
  { title: "Date", field_type: FIELD_TYPES.DATE, icon: "date.svg" },
  { title: "Date & Time", field_type: FIELD_TYPES.DATETIME, icon: "date.svg" },
  { title: "Images", field_type: FIELD_TYPES.PHOTOS, icon: "image.svg" },
  { title: "Files", field_type: FIELD_TYPES.FILES, icon: "file.svg" },
  { title: "Currency", field_type: FIELD_TYPES.CURRENCY, icon: "dollar.svg" },
  { title: "Checkbox", field_type: FIELD_TYPES.CHECKBOX, icon: "checkbox.svg" },
  { title: "Tax Documents", field_type: FIELD_TYPES.BUSINESS_DOCUMENTS, icon: "file.svg" },
  { title: "Page Break", field_type: FIELD_TYPES.PAGE_BREAK, icon: "file.svg" },
  { title: "Numeric", field_type: FIELD_TYPES.NUMERIC, icon: "numeric.svg" },
  { title: "Music Song Details", field_type: FIELD_TYPES.SONG_DETAILS, icon: "file.svg" },
];

export const INPUT_TYPES = {
  TEXT: "text",
  EMAIL: "email",
  PHONE: "tel",
  DATE: "date",
  CHECK_BOX: "checkbox",
  FILES: "file",
  SIGNATURE: "SIGNATURE",
  INITIAL: "SIGNATURE",
};

export const TOKENS = {
  PERSON_NAME: "PERSON_NAME",
  PERSON_TITLE: "PERSON_TITLE",
  PERSON_FAX: "PERSON_FAX",
  PERSON_COMPANY: "PERSON_COMPANY",
  PERSON_EMAIL: "PERSON_EMAIL",
  PERSON_PHONE_NUMBER: "PERSON_PHONE_NUMBER",
  PERSON_ADDRESS: "PERSON_ADDRESS",
  CHECKBOX: "CHECKBOX",
  FILES: "FILES",
  DATE: "DATE",
  DATE_RANGE: "DATE_RANGE",

  FILMING_STARTED_ON: "FILMING_STARTED_ON",
  FILMING_HOURS: "FILMING_HOURS",
  FILMING_ENDED_ON: "FILMING_ENDED_ON",

  MATERIAL_NAME: "MATERIAL_NAME",
  MATERIAL_DESCRIPTION: "MATERIAL_DESCRIPTION",
  MATERIAL_FILES: "MATERIAL_FILES",

  PHOTOS: "PHOTOS",

  COMPANY_ADDRESS: "COMPANY_ADDRESS",

  OTHER: "OTHER",

  PERSON_DATE_OF_BIRTH: "PERSON_DATE_OF_BIRTH",
  DATE_OF_BIRTH: "DATE_OF_BIRTH",

  SIGNATURE: "SIGNATURE",
  PERSON_INITIALS: "PERSON_INITIALS",

  DYNAMIC_FEE: "DYNAMIC_FEE",
  FIXED_FEE: "FIXED_FEE",

  NUMBER: "NUMBER",

  AMOUNT: "AMOUNT",

  IS_PERSON_MINOR: "IS_PERSON_MINOR",

  W9_DOCUMENT: "W9_DOCUMENT",
  I9_DOCUMENT: "I9_DOCUMENT",

  GUARDIAN_NAME: "GUARDIAN_NAME",
  GUARDIAN_EMAIL: "GUARDIAN_EMAIL",
  GUARDIAN_PHONE_NUMBER: "GUARDIAN_PHONE_NUMBER",
  GUARDIAN_ADDRESS: "GUARDIAN_ADDRESS",
  GUARDIAN_SIGNATURE: "GUARDIAN_SIGNATURE",
  GUARDIAN_INITIALS: "GUARDIAN_INITIALS",
  GUARDIAN_PHOTOS_SELFIES: "GUARDIAN_PHOTOS_SELFIES",

  GUARDIAN_2_NAME: "GUARDIAN_2_NAME",
  GUARDIAN_2_EMAIL: "GUARDIAN_2_EMAIL",
  GUARDIAN_2_PHONE_NUMBER: "GUARDIAN_2_PHONE_NUMBER",
  GUARDIAN_2_ADDRESS: "GUARDIAN_2_ADDRESS",
  GUARDIAN_2_SIGNATURE: "GUARDIAN_2_SIGNATURE",
  GUARDIAN_2_INITIALS: "GUARDIAN_2_INITIALS",
  GUARDIAN_2_PHOTOS_SELFIES: "GUARDIAN_2_PHOTOS_SELFIES",

  PROPERTY_NAME: "PROPERTY_NAME",
  PROPERTY_DESCRIPTION: "PROPERTY_DESCRIPTION",

  LOCATION_NAME: "LOCATION_NAME",
  LOCATION_ADDRESS: "LOCATION_ADDRESS",
  LOCATION_PHOTOS: "LOCATION_PHOTOS",

  LICENSE_MATERIAL_NAME: "LICENSE_MATERIAL_NAME",
  LICENSE_MATERIAL_DESCRIPTION: "LICENSE_MATERIAL_DESCRIPTION",

  MUSIC_NAME: "MUSIC_NAME",
  MUSIC_FILES: "MUSIC_FILES",

  ACQUIRED_MEDIA_NAME: "ACQUIRED_MEDIA_NAME",
  ACQUIRED_MEDIA_DESCRIPTION: "ACQUIRED_MEDIA_DESCRIPTION",
  ACQUIRED_MEDIA_CATEGORIES: "ACQUIRED_MEDIA_CATEGORIES",
  ACQUIRED_MEDIA_FILES: "ACQUIRED_MEDIA_FILES",

  OTEHR_NAME: "OTEHR_NAME",
  OTHER_ADDRESS: "OTHER_ADDRESS",

  OTHER_TEXT: "OTHER_TEXT",
  OTHER_NUMBER: "OTHER_NUMBER",
  OTHER_DATE: "OTHER_DATE",
  OTHER_IMAGE: "OTHER_IMAGE",
  OTHER_FILE: "OTHER_FILE",
  OTHER_SIGN: "OTHER_SIGN",
  OTHER_INITIALS: "OTHER_INITIALS",
  PAGE_BREAK: "PAGE_BREAK",

  MUSIC_SONG_DETAILS: "MUSIC_SONG_DETAILS",

  COUNTERSIGNER_NAME: "COUNTERSIGNER_NAME",
  COUNTERSIGNER_TITLE: "COUNTERSIGNER_TITLE",
  COUNTERSIGNER_ADDRESS: "COUNTERSIGNER_ADDRESS",
  COUNTERSIGNER_PHONE_NUMBER: "COUNTERSIGNER_PHONE_NUMBER",
  COUNTERSIGNER_EMAIL: "COUNTERSIGNER_EMAIL",
  COUNTERSIGNER_DATE: "COUNTERSIGNER_DATE",

  COUNTERSIGNER_SIGNATURE: "COUNTERSIGNER_SIGNATURE",
  COUNTERSIGNER_INITIALS: "COUNTERSIGNER_INITIALS",
};

export const RELEASE_TYPES = {
  TALENT: "talent",
  APPEARANCE: "appearance",
  ACQUIRED_MEDIA: "acquired_media",
  LOCATION: "location",
  MATERIAL: "material",
  MUSIC: "music",
  MEDICAL: "medical",
  MISC: "misc",
  BUSINESS_DOCUMENTS: "business",
};

export const LIST_RELEASE_TYPES = [
  { title: "Talent Release", value: RELEASE_TYPES.TALENT },
  { title: "Appearance Release", value: RELEASE_TYPES.APPEARANCE },
  { title: "Acquired Media Release", value: RELEASE_TYPES.ACQUIRED_MEDIA },
  { title: "Location Release", value: RELEASE_TYPES.LOCATION },
  { title: "Material Release", value: RELEASE_TYPES.MATERIAL },
  { title: "Music Release", value: RELEASE_TYPES.MUSIC },
  { title: "Medical Release", value: RELEASE_TYPES.MEDICAL },
  { title: "Misc Release", value: RELEASE_TYPES.MISC },
  { title: "Business Documents", value: RELEASE_TYPES.BUSINESS_DOCUMENTS },
];

export const ContractFormData = {
  // TAB - INFO
  name: "",
  release_type: RELEASE_TYPES.TALENT,

  tag_list: [], // for tags
  accessibility: false, // If public

  fee: "",
  has_fee: false,
  fee_type: "",
  currency: "",

  applicable_medium_id: 2,
  applicable_medium_text: "",

  territory_id: 2,
  territory_text: "",

  tokens: [],

  term_id: 2,
  term_text: "",

  restriction_id: 2,
  restriction_text: "",
  version: 2,

  // TAB - CONTRACT BUILDER
  body: "",

  welcome_message: "",
  submit_message: "",

  // signer - settings
  allow_to_send_signed_copy: false,
  required_to_confirm_all_info_confirm: false,
};

export const currency_list = [
  { name: "Afghan Afghani", code: "AFA", symbol: "؋" },
  { name: "Albanian Lek", code: "ALL", symbol: "Lek" },
  { name: "Algerian Dinar", code: "DZD", symbol: "دج" },
  { name: "Angolan Kwanza", code: "AOA", symbol: "Kz" },
  { name: "Argentine Peso", code: "ARS", symbol: "$" },
  { name: "Armenian Dram", code: "AMD", symbol: "֏" },
  { name: "Aruban Florin", code: "AWG", symbol: "ƒ" },
  { name: "Australian Dollar", code: "AUD", symbol: "$" },
  { name: "Azerbaijani Manat", code: "AZN", symbol: "m" },
  { name: "Bahamian Dollar", code: "BSD", symbol: "B$" },
  { name: "Bahraini Dinar", code: "BHD", symbol: ".د.ب" },
  { name: "Bangladeshi Taka", code: "BDT", symbol: "৳" },
  { name: "Barbadian Dollar", code: "BBD", symbol: "Bds$" },
  { name: "Belarusian Ruble", code: "BYR", symbol: "Br" },
  { name: "Belgian Franc", code: "BEF", symbol: "fr" },
  { name: "Belize Dollar", code: "BZD", symbol: "$" },
  { name: "Bermudan Dollar", code: "BMD", symbol: "$" },
  { name: "Bhutanese Ngultrum", code: "BTN", symbol: "Nu." },
  { name: "Bitcoin", code: "BTC", symbol: "฿" },
  { name: "Bolivian Boliviano", code: "BOB", symbol: "Bs." },
  { name: "Bosnia-Herzegovina Convertible Mark", code: "BAM", symbol: "KM" },
  { name: "Botswanan Pula", code: "BWP", symbol: "P" },
  { name: "Brazilian Real", code: "BRL", symbol: "R$" },
  { name: "British Pound Sterling", code: "GBP", symbol: "£" },
  { name: "Brunei Dollar", code: "BND", symbol: "B$" },
  { name: "Bulgarian Lev", code: "BGN", symbol: "Лв." },
  { name: "Burundian Franc", code: "BIF", symbol: "FBu" },
  { name: "Cambodian Riel", code: "KHR", symbol: "KHR" },
  { name: "Canadian Dollar", code: "CAD", symbol: "$" },
  { name: "Cape Verdean Escudo", code: "CVE", symbol: "$" },
  { name: "Cayman Islands Dollar", code: "KYD", symbol: "$" },
  { name: "CFA Franc BCEAO", code: "XOF", symbol: "CFA" },
  { name: "CFA Franc BEAC", code: "XAF", symbol: "FCFA" },
  { name: "CFP Franc", code: "XPF", symbol: "₣" },
  { name: "Chilean Peso", code: "CLP", symbol: "$" },
  { name: "Chilean Unit of Account", code: "CLF", symbol: "CLF" },
  { name: "Chinese Yuan", code: "CNY", symbol: "¥" },
  { name: "Colombian Peso", code: "COP", symbol: "$" },
  { name: "Comorian Franc", code: "KMF", symbol: "CF" },
  { name: "Congolese Franc", code: "CDF", symbol: "FC" },
  { name: "Costa Rican Colón", code: "CRC", symbol: "₡" },
  { name: "Croatian Kuna", code: "HRK", symbol: "kn" },
  { name: "Cuban Convertible Peso", code: "CUC", symbol: "$, CUC" },
  { name: "Czech Republic Koruna", code: "CZK", symbol: "Kč" },
  { name: "Danish Krone", code: "DKK", symbol: "Kr." },
  { name: "Djiboutian Franc", code: "DJF", symbol: "Fdj" },
  { name: "Dominican Peso", code: "DOP", symbol: "$" },
  { name: "East Caribbean Dollar", code: "XCD", symbol: "$" },
  { name: "Egyptian Pound", code: "EGP", symbol: "ج.م" },
  { name: "Eritrean Nakfa", code: "ERN", symbol: "Nfk" },
  { name: "Estonian Kroon", code: "EEK", symbol: "kr" },
  { name: "Ethiopian Birr", code: "ETB", symbol: "Nkf" },
  { name: "Euro", code: "EUR", symbol: "€" },
  { name: "Falkland Islands Pound", code: "FKP", symbol: "£" },
  { name: "Fijian Dollar", code: "FJD", symbol: "FJ$" },
  { name: "Gambian Dalasi", code: "GMD", symbol: "D" },
  { name: "Georgian Lari", code: "GEL", symbol: "ლ" },
  { name: "German Mark", code: "DEM", symbol: "DM" },
  { name: "Ghanaian Cedi", code: "GHS", symbol: "GH₵" },
  { name: "Gibraltar Pound", code: "GIP", symbol: "£" },
  { name: "Greek Drachma", code: "GRD", symbol: "₯, Δρχ, Δρ" },
  { name: "Guatemalan Quetzal", code: "GTQ", symbol: "Q" },
  { name: "Guinean Franc", code: "GNF", symbol: "FG" },
  { name: "Guyanaese Dollar", code: "GYD", symbol: "$" },
  { name: "Haitian Gourde", code: "HTG", symbol: "G" },
  { name: "Honduran Lempira", code: "HNL", symbol: "L" },
  { name: "Hong Kong Dollar", code: "HKD", symbol: "$" },
  { name: "Hungarian Forint", code: "HUF", symbol: "Ft" },
  { name: "Icelandic Króna", code: "ISK", symbol: "kr" },
  { name: "Indian Rupee", code: "INR", symbol: "₹" },
  { name: "Indonesian Rupiah", code: "IDR", symbol: "Rp" },
  { name: "Iranian Rial", code: "IRR", symbol: "﷼" },
  { name: "Iraqi Dinar", code: "IQD", symbol: "د.ع" },
  { name: "Israeli New Sheqel", code: "ILS", symbol: "₪" },
  { name: "Italian Lira", code: "ITL", symbol: "L,£" },
  { name: "Jamaican Dollar", code: "JMD", symbol: "J$" },
  { name: "Japanese Yen", code: "JPY", symbol: "¥" },
  { name: "Jordanian Dinar", code: "JOD", symbol: "ا.د" },
  { name: "Kazakhstani Tenge", code: "KZT", symbol: "лв" },
  { name: "Kenyan Shilling", code: "KES", symbol: "KSh" },
  { name: "Kuwaiti Dinar", code: "KWD", symbol: "ك.د" },
  { name: "Kyrgystani Som", code: "KGS", symbol: "лв" },
  { name: "Laotian Kip", code: "LAK", symbol: "₭" },
  { name: "Latvian Lats", code: "LVL", symbol: "Ls" },
  { name: "Lebanese Pound", code: "LBP", symbol: "£" },
  { name: "Lesotho Loti", code: "LSL", symbol: "L" },
  { name: "Liberian Dollar", code: "LRD", symbol: "$" },
  { name: "Libyan Dinar", code: "LYD", symbol: "د.ل" },
  { name: "Litecoin", code: "LTC", symbol: "Ł" },
  { name: "Lithuanian Litas", code: "LTL", symbol: "Lt" },
  { name: "Macanese Pataca", code: "MOP", symbol: "$" },
  { name: "Macedonian Denar", code: "MKD", symbol: "ден" },
  { name: "Malagasy Ariary", code: "MGA", symbol: "Ar" },
  { name: "Malawian Kwacha", code: "MWK", symbol: "MK" },
  { name: "Malaysian Ringgit", code: "MYR", symbol: "RM" },
  { name: "Maldivian Rufiyaa", code: "MVR", symbol: "Rf" },
  { name: "Mauritanian Ouguiya", code: "MRO", symbol: "MRU" },
  { name: "Mauritian Rupee", code: "MUR", symbol: "₨" },
  { name: "Mexican Peso", code: "MXN", symbol: "$" },
  { name: "Moldovan Leu", code: "MDL", symbol: "L" },
  { name: "Mongolian Tugrik", code: "MNT", symbol: "₮" },
  { name: "Moroccan Dirham", code: "MAD", symbol: "MAD" },
  { name: "Mozambican Metical", code: "MZM", symbol: "MT" },
  { name: "Myanmar Kyat", code: "MMK", symbol: "K" },
  { name: "Namibian Dollar", code: "NAD", symbol: "$" },
  { name: "Nepalese Rupee", code: "NPR", symbol: "₨" },
  { name: "Netherlands Antillean Guilder", code: "ANG", symbol: "ƒ" },
  { name: "New Taiwan Dollar", code: "TWD", symbol: "$" },
  { name: "New Zealand Dollar", code: "NZD", symbol: "$" },
  { name: "Nicaraguan Córdoba", code: "NIO", symbol: "C$" },
  { name: "Nigerian Naira", code: "NGN", symbol: "₦" },
  { name: "North Korean Won", code: "KPW", symbol: "₩" },
  { name: "Norwegian Krone", code: "NOK", symbol: "kr" },
  { name: "Omani Rial", code: "OMR", symbol: ".ع.ر" },
  { name: "Pakistani Rupee", code: "PKR", symbol: "₨" },
  { name: "Panamanian Balboa", code: "PAB", symbol: "B/." },
  { name: "Papua New Guinean Kina", code: "PGK", symbol: "K" },
  { name: "Paraguayan Guarani", code: "PYG", symbol: "₲" },
  { name: "Peruvian Nuevo Sol", code: "PEN", symbol: "S/." },
  { name: "Philippine Peso", code: "PHP", symbol: "₱" },
  { name: "Polish Zloty", code: "PLN", symbol: "zł" },
  { name: "Qatari Rial", code: "QAR", symbol: "ق.ر" },
  { name: "Romanian Leu", code: "RON", symbol: "lei" },
  { name: "Russian Ruble", code: "RUB", symbol: "₽" },
  { name: "Rwandan Franc", code: "RWF", symbol: "FRw" },
  { name: "Salvadoran Colón", code: "SVC", symbol: "₡" },
  { name: "Samoan Tala", code: "WST", symbol: "SAT" },
  { name: "São Tomé and Príncipe Dobra", code: "STD", symbol: "Db" },
  { name: "Saudi Riyal", code: "SAR", symbol: "﷼" },
  { name: "Serbian Dinar", code: "RSD", symbol: "din" },
  { name: "Seychellois Rupee", code: "SCR", symbol: "SRe" },
  { name: "Sierra Leonean Leone", code: "SLL", symbol: "Le" },
  { name: "Singapore Dollar", code: "SGD", symbol: "$" },
  { name: "Slovak Koruna", code: "SKK", symbol: "Sk" },
  { name: "Solomon Islands Dollar", code: "SBD", symbol: "Si$" },
  { name: "Somali Shilling", code: "SOS", symbol: "Sh.so." },
  { name: "South African Rand", code: "ZAR", symbol: "R" },
  { name: "South Korean Won", code: "KRW", symbol: "₩" },
  { name: "South Sudanese Pound", code: "SSP", symbol: "£" },
  { name: "Special Drawing Rights", code: "XDR", symbol: "SDR" },
  { name: "Sri Lankan Rupee", code: "LKR", symbol: "Rs" },
  { name: "St. Helena Pound", code: "SHP", symbol: "£" },
  { name: "Sudanese Pound", code: "SDG", symbol: ".س.ج" },
  { name: "Surinamese Dollar", code: "SRD", symbol: "$" },
  { name: "Swazi Lilangeni", code: "SZL", symbol: "E" },
  { name: "Swedish Krona", code: "SEK", symbol: "kr" },
  { name: "Swiss Franc", code: "CHF", symbol: "CHf" },
  { name: "Syrian Pound", code: "SYP", symbol: "LS" },
  { name: "Tajikistani Somoni", code: "TJS", symbol: "SM" },
  { name: "Tanzanian Shilling", code: "TZS", symbol: "TSh" },
  { name: "Thai Baht", code: "THB", symbol: "฿" },
  { name: "Tongan Pa'anga", code: "TOP", symbol: "$" },
  { name: "Trinidad & Tobago Dollar", code: "TTD", symbol: "$" },
  { name: "Tunisian Dinar", code: "TND", symbol: "ت.د" },
  { name: "Turkish Lira", code: "TRY", symbol: "₺" },
  { name: "Turkmenistani Manat", code: "TMT", symbol: "T" },
  { name: "Ugandan Shilling", code: "UGX", symbol: "USh" },
  { name: "Ukrainian Hryvnia", code: "UAH", symbol: "₴" },
  { name: "United Arab Emirates Dirham", code: "AED", symbol: "إ.د" },
  { name: "Uruguayan Peso", code: "UYU", symbol: "$" },
  { name: "US Dollar", code: "USD", symbol: "$" },
  { name: "Uzbekistan Som", code: "UZS", symbol: "лв" },
  { name: "Vanuatu Vatu", code: "VUV", symbol: "VT" },
  { name: "Venezuelan BolÃvar", code: "VEF", symbol: "Bs" },
  { name: "Vietnamese Dong", code: "VND", symbol: "₫" },
  { name: "Yemeni Rial", code: "YER", symbol: "﷼" },
  { name: "Zambian Kwacha", code: "ZMK", symbol: "ZK" },
  { name: "Zimbabwean dollar", code: "ZWL", symbol: "$" },
];

export const ONBOARDING_STEPS = {
  STEP_1: 1,
  STEP_1_1: 1.1,
  STEP_2: 2,
  STEP_3: 3,
  STEP_3_1: 3.1,
  STEP_4: 4,
  STEP_5: 5,
  STEP_6: 6,
  STEP_7: 7,
};
export const ONBOARDING_ACTIONS = {
  CREATE_PROJECT: "create_project",
  EXPLORE_SAMPLE_PROJECT: "explore_sample_project",
  WATCH_VIDEO: "watch_video",
  TUTORIAL_VIDEO: "tutorial_video",
  ONBOARDING: "ONBOARDING",
  SIGN_SAMPLE_CONTRACT: "sign_sample_contract",
  SIGN_CONTRACT: "sign_contract",
  FINISH_ONBOARDING: "finish_onboarding",
  MOBILE_CREATE_CONTRACT: "mobile_create_contract",
  CREATE_CONTRACT: "create_contract",
  PREFERENCES: "preferences",
  PREFERENCE_CATEGORIES: "preference_categories",
};

export const ONBOARDING_ACTIONS_LIST = (_this) => {
  return {
    [ONBOARDING_ACTIONS.CREATE_CONTRACT]: {
      id: ONBOARDING_ACTIONS.CREATE_CONTRACT,
      title: { default: "Create A Contract" },
      custom_tag: {
        type: "component",
        component: "CreateContract",
      },
      disabled: {
        projects: false,
      },
    },
    [ONBOARDING_ACTIONS.SIGN_CONTRACT]: {
      id: ONBOARDING_ACTIONS.SIGN_CONTRACT,
      title: { default: "Sign A Contract" },
      custom_tag: {
        type: "component",
        component: "SignContract",
      },
    },
    [ONBOARDING_ACTIONS.SIGN_SAMPLE_CONTRACT]: {
      id: ONBOARDING_ACTIONS.SIGN_SAMPLE_CONTRACT,
      title: { default: "Sign A Sample Contract" },
      custom_tag: {
        type: "component",
        component: "SignSampleContract",
      },
    },
    [ONBOARDING_ACTIONS.MOBILE_CREATE_CONTRACT]: {
      id: ONBOARDING_ACTIONS.MOBILE_CREATE_CONTRACT,
      title: { default: "Sign A Sample Contract" },
      custom_tag: {
        type: "component",
        component: "MobileCreateContract",
      },
    },
    [ONBOARDING_ACTIONS.CREATE_PROJECT]: {
      id: ONBOARDING_ACTIONS.CREATE_PROJECT,
      title: { default: "Get Started" },
      onClick: { clickAction: _this.createProjectClicked },
    },
    [ONBOARDING_ACTIONS.EXPLORE_SAMPLE_PROJECT]: {
      id: ONBOARDING_ACTIONS.EXPLORE_SAMPLE_PROJECT,
      title: { default: "Explore Project" },
      onClick: { clickAction: _this.setOldUser },
    },
    [ONBOARDING_ACTIONS.WATCH_VIDEO]: {
      id: ONBOARDING_ACTIONS.WATCH_VIDEO,
      title: { default: "Watch Tutorial Video" },
      onClick: { clickAction: _this.goToStep, options: { step: ONBOARDING_STEPS.STEP_1_1 } },
    },
    [ONBOARDING_ACTIONS.TUTORIAL_VIDEO]: {
      id: ONBOARDING_ACTIONS.TUTORIAL_VIDEO,
      title: { default: "Watch Tutorial Video custom" },

      custom_tag: {
        type: "video",
        tag_options: {
          controls: true,
          controlslist: "nodownload noremoteplayback noplaybackrate",
          disablepictureinpicture: true,
          src: "https://easy-release-storage-production.s3.amazonaws.com/intro_to_signme.mp4",
          poster: "https://easy-release-storage-production.s3.amazonaws.com/signme_get_started.jpeg",
        },
      },
      onClick: null,
    },
    [ONBOARDING_ACTIONS.PREFERENCE_CATEGORIES]: {
      id: ONBOARDING_ACTIONS.PREFERENCE_CATEGORIES,
      title: { default: "Preference Categories" },
      custom_tag: {
        type: "component",
        component: "ReleaseMePreferencesCategories",
      },
    },
    [ONBOARDING_ACTIONS.PREFERENCES]: {
      id: ONBOARDING_ACTIONS.PREFERENCES,
      title: { default: "Checkboxes" },
      custom_tag: {
        type: "component",
        component: "ReleaseMeOnboardingPreferences",
      },
    },
  };
};

export const ONBOARDING_PREFERENCE_CATEGORIES = {
  CREATE_CLIENT_PROPOSALS: { id: "create_client_proposals", title: "Create Client Proposals" },
  CREATE_BUSINESS_CONTRACTS: { id: "create_business_contracts", title: "Create Business Contracts and Walvers" },
  MANAGE_BRANDS: { id: "manage_brands", title: "Manage Brand Partnerships" },
};

export const ONBOARDING_PREFERENCES = {
  send_proposals: {
    title: "Send Proposals",
    description: "Send Proposals",
    category: ONBOARDING_PREFERENCE_CATEGORIES.CREATE_CLIENT_PROPOSALS.id,
  },
  send_invoices: {
    title: "Send Invoices",
    description: "Send invoices",
    category: ONBOARDING_PREFERENCE_CATEGORIES.CREATE_CLIENT_PROPOSALS.id,
  },
  recieve_track_payments: {
    title: "Recieve and Track Payments",
    description: "Recieve and Track Payments",
    category: ONBOARDING_PREFERENCE_CATEGORIES.CREATE_CLIENT_PROPOSALS.id,
  },
  deal_memos: {
    title: "Create Deal Memos",
    description: "Create Deal Memos",
    category: ONBOARDING_PREFERENCE_CATEGORIES.CREATE_BUSINESS_CONTRACTS.id,
  },
  release_forms: {
    title: "Create Release Forms",
    description: "Create Release Forms",
    category: ONBOARDING_PREFERENCE_CATEGORIES.CREATE_BUSINESS_CONTRACTS.id,
  },
  create_other_contracts: {
    title: "Create Other Contracts",
    description: "Create Other Contracts",
    category: ONBOARDING_PREFERENCE_CATEGORIES.CREATE_BUSINESS_CONTRACTS.id,
  },
  partnership_agreements: {
    title: "Create Brand Partnership Agreements",
    description: "Create Brand Partnership Agreements",
    category: ONBOARDING_PREFERENCE_CATEGORIES.MANAGE_BRANDS.id,
  },

  brand_send_invoics: {
    title: "Send Invoices",
    description: "Send Invoices",
    category: ONBOARDING_PREFERENCE_CATEGORIES.MANAGE_BRANDS.id,
  },
  brand_recieve_track_payments: {
    title: "Recieve and Track Payments",
    description: "Recieve and Track Payments",
    category: ONBOARDING_PREFERENCE_CATEGORIES.MANAGE_BRANDS.id,
  },
};
