
import {RouteConfig} from "vue-router";
/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "call_sheets" <<
 * where ever we mention this, it will group all components in one group.
 */
const CallSheetRoutes = [
    {
        path: "callsheets",
        name: 'call_sheets',
        component: () => import(/* webpackChunkName: "call_sheets" */'./CallSheet.vue'),
        children: [
            {
                path: "",
                name: 'documents.call_sheets',
                component: () => import(/* webpackChunkName: "call_sheets" */'./pages/CallSheetIndex.vue'),
            },
            {
                path: "new",
                name: 'documents.call_sheets.new',
                component: () => import(/* webpackChunkName: "call_sheets" */'./pages/CallSheetNew.vue'),
            },
            {
                path: "edit/:call_sheet_hashid",
                name: 'documents.call_sheets.edit',
                component: () => import(/* webpackChunkName: "call_sheets" */'./pages/CallSheetEdit.vue'),
            },
            // THIS ROUTE IS USED FOR USER VIEW
            {
                path: 'show_pdf/:call_sheet_hashid',
                name: "documents.call_sheets.show_pdf",
                component: () => import(/* webpackChunkName: "call_sheets" */'./pages/CallSheetUserView.vue'),
                meta: {
                    sidebar: true
                }
            },
            
            // NOT SURE WHY WE USED THIS 
            {
                path: 'view_pdf/:call_sheet_hashid',
                name: "documents.call_sheets.view_pdf",
                component: () => import(/* webpackChunkName: "call_sheets" */'./pages/CallSheetPdf.vue'),
                meta: {
                    //sidebar: false
                    sidebar: false
                }
            },

            // THIS URL IS ONLY USED FOR DOWNLOAD PURPOSE AS BACKEND CALL THIS URL ---
            {
                path: 'view/:call_sheet_hashid',
                name: "documents.call_sheets.view",
                component: () => import(/* webpackChunkName: "call_sheets" */'./pages/CallSheetView.vue'),
                meta: {
                    sidebar: false 
                },
            },

        ]
    }
];

export default CallSheetRoutes;