import {mapActions, mapGetters, mapMutations} from 'vuex';
import {PROJECT_MODULE_NAME} from "../../Project/store/project.vuex";

export const PROFILESETTINGS_MODULE_NAME = 'profilesettings';

const initState = {
    is_connected: false,
    settings: [],
    vendors: [],
    teams: [],
    groups: [],
    others: []
};

const ProfileSettingsModule = {
    namespaced: true,
    state: () => initState,
    mutations: {
        // COMMON
        connected(state, flag) {
            state.is_connected = flag;
        },

        show(state, {body: data, params}) {
            state.settings = data;
        },


        //Vendor Listing
        index_vendor(state, {body: data, params}) {
            console.log(data)
            state.vendors = data
        },

        //Others Listing
        index_other(state, {body: data, params}) {
            console.log(data)
            state.others = data
        },

        //Groups Listing
        index_group(state, {body: data, params}) {
            console.log(data)
            state.groups = data
        },

        //Teams Listing
        index_team(state, {body: data, params}) {
            console.log(data)
            state.teams = data
        }
    },
    actions: {
        update_profile({commit}, {body}) {
            commit(`${PROJECT_MODULE_NAME}/set_current_user`, body.current_user, {root: true});
        }
    },
    getters: {
        is_connected: s => s.is_connected,
        settings: s => s.settings,
        vendors: s => s.vendors,
        groups: s => s.groups,
        teams: s => s.teams,
        others: s => s.others,
    }
};

export const mapProfileSettingsActions = data => mapActions(PROFILESETTINGS_MODULE_NAME, data);
export const mapProfileSettingsGetters = data => mapGetters(PROFILESETTINGS_MODULE_NAME, data);

export const mapProfileSettingsMutations = data => mapMutations(PROFILESETTINGS_MODULE_NAME, data);

export default ProfileSettingsModule