import {RouteConfig} from "vue-router";
import ViewRoutes from "./View/view.routes";
import AnnouncementRoutes from "./Announcements/announcement.routes";
import VideoTranscriptsRoutes from "./VideoTranscripts/videotranscript.routes";
import ProductSettingsRoutes from "./ProductSettings/productSettings.routes";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "admins" <<
 * where ever we mention this, it will group all components in one group.
 */
const AdminRoutes = [
    {
        path: "/admin",
        redirect: {name: "admin.view.index"},
        component: () => import(/* webpackChunkName: "admins" */ "./Admin.vue"),
        meta:{sidebar:"AdminSidebar"},
        children: [...ViewRoutes, ...AnnouncementRoutes, ...VideoTranscriptsRoutes, ...ProductSettingsRoutes],
    },
];

export default AdminRoutes;
