export const SIDEBAR_MODULE_NAME = "SidebarModule";

const initState = {
  isMobileSidebarActive: false,
  selectedSubSidebarOption: null,
  searchQuery: "",
};

const SidebarModule = {
  namespaced: true,
  state: () => {
    return { ...initState };
  },
  getters: {
    isMobileSidebarActive: (s) => s.isMobileSidebarActive,
    selectedSubSidebarOption: (s) => s.selectedSubSidebarOption,
    searchQuery: (s) => s.searchQuery,
  },
  mutations: {
    RESET(state, property = "all") {
      if (property === "all")
        for (const eachStateKey in initState)
          state[eachStateKey] = initState[eachStateKey];
      else state[property] = initState[property];
    },
    toggleSidebarActive(state) {
      console.log("Toggling Sidebar");
      state.isMobileSidebarActive = !state.isMobileSidebarActive;
    },
    setSidebarState(state, intent = "active") {
      if (intent === "active" || intent === true)
        state.isMobileSidebarActive = true;
      else if (intent === "inactive" || intent === false)
        state.isMobileSidebarActive = false;
    },
    changeSelectedSubSidebarOption(state, { newOption }) {
      state.selectedSubSidebarOption = newOption;
    },
    setSearchQuery(state, query) {
      state.searchQuery = query;
    },
  },
};

export default SidebarModule;
