import {mapActions, mapGetters, mapMutations} from "vuex";
import moment from "moment";

export const ADMIN_MODULE_NAME = "admin";

export const ADMIN_MODAL_ACTIONS = {
    EDIT_USER: "edit_user",
    EDIT_PROJECT: "edit_project",
    EDIT_TEAM: "edit_team",

    ADD_INVOICE: "add_invoice",
    UPDATE_INVOICE: "update_invoice",
    VIEW_INVOICE: "view_invoice",

    ADD_PRICING_PLAN: "add.plan",
    UPDATE_PRICING_PLAN: "update.plan",

    UPDATE_PROJECT_PRICING_PLAN: "update.project.plan",

    UPDATE_PROJECT_PRODUCT_BILLING: "update.project.product.billing",

    SHOW_PROJECT_INVOICES: "show.projects.invoices",
    MANAGE_WORKSPACES: "manage_workspaces",
};

export const PRICING_PLANS = [
    {title: "Basic", key: "basic"},
    {title: "Fixed", key: "fixed"},
    {title: "Pay As You Go", key: "pay_as_you_go"},
    {title: "Enterprise", key: "enterprise"},
    {title: "MESuite Student", key: "mesuite_student"},
];

const initState = {
    is_connected: false,
    modal: null,
    cached_time: null,

    users: [],
    selected_user: null,
    users_summary: {
        c_new_users: 0,
        p_new_users: 0,

        c_users: 0,
        p_users: 0,

        c_projects: 0,
        p_projects: 0,
    },

    accounts: [],
    selected_account: null, // selected account
    account_projects: [],
    accounts_summary: {
        c_projects: 0,
        p_projects: 0,

        c_accounts: 0,
        p_accounts: 0,

        c_new_accounts: 0,
        p_new_accounts: 0,
    },

    projects: [],
    selected_project: null,
    projects_summary: {
        c_new_projects: 0,
        p_new_projects: 0,

        c_active_projects: 0,
        p_active_projects: 0,

        c_releaseme_signed: 0,
        p_releaseme_signed: 0,

        c_deliverme: 0,
        p_deliverme: 0,

        c_directme: 0,
        p_directme: 0,
    },

    project_invoices: [],
    invoices: [],
    plans: [],
    new_users: [],

    billing_information: [],
    selected_invoice: null,

    loading: true
};

const AdminModule = {
    namespaced: true,
    state: () => initState,
    mutations: {
        // Admin
        index(state, {body: data, params}) {
        },

        // Team/Accounts Index
        index_accounts(state, {body: data, params}) {
            state.accounts = data.map((t) => {
                t.hash = `${t.name}`.toUpperCase();
                return t;
            });
        },
        show_accounts_summary(state, {body: data}) {
            state.accounts_summary = data;
        },
        update_account(state, {body: data}) {
            if (state.selected_account) {
                if (state.selected_account.account_hashid === data.account_hashid) {
                    state.selected_account.name = data.name;
                    state.selected_account.description = data.description;
                }
            }
            const index = state.accounts.findIndex(a => a.account_hashid = data.account_hashid);
            if (index > -1) {
                state.accounts[index].name = data.name;
                state.accounts[index].description = data.description;
                state.accounts[index].updated_at = data.updated_at;
            }
        },
        lock_unlock_account(state, {body: data}) {
            const index = state.accounts.findIndex(a => a.account_hashid = data.account_hashid);
            if (index > -1) {
                state.accounts[index].locked = data.locked;
                state.accounts[index].updated_at = data.updated_at;
            }
        },


        // Account_Project Index
        index_account_projects(state, {body: data, params}) {
            state.account_projects = data;
        },

        index_users(state, {body: data, params}) {
            state.users = data.map((t) => {
                t.first_name = t.first_name || "";
                t.last_name = t.last_name || "";
                t.hash = `${t.email} ${t.first_name} ${t.last_name}`.toUpperCase();
                return t;
            });
        },
        show_users_summary(state, {body: data}) {
            state.users_summary = data;
        },

        //FETCH single User data
        show_user(state, {body: data, params}) {
            state.selected_user = data || null;
        },

        update_users(state, user) {
            const index = state.users.findIndex(
                (b) => b.user_hashid === user.user_hashid
            );
            if (index > -1) {
                state.users.splice(index, 1, user);
            }
        },

        // show single account/Team detail
        show_account(state, {body: data, params}) {
            state.selected_account = data;
        },

        // update account/Team detail

        // Projects Index
        index_projects(state, {body: data, params}) {
            state.projects = data.map(p => {
                p.client_name = p.client_name || "";
                p.hash = `${p.name} ${p.account_name} ${p.client_name}`.toUpperCase();
                if (p.latest_activity_at) {
                    p.monthValue = new Date(p.latest_activity_at).getMonth();
                }
                return p;
            });
            state.loading = false;
        },
        show_projects_summary(state, {body: data}) {
            state.projects_summary = data;
        },

        // Projects Index
        show_project(state, {body: data, params}) {
            if (data) {
                state.selected_project = data.project;
            }
        },
        // Project Archive/Unarchive
        archive(state, {body: data}) {
            const index = state.projects.findIndex(p => p.project_hashid === data.project_hashid);
            if (index > -1) {
                state.projects[index].archived = data.archived;
                state.projects[index].updated_at = data.updated_at;
            }
        },
        // Projects Update
        update_project(state, {body: project, params}) {
            if (project) {
                const index = state.projects.findIndex(
                    (b) => b.project_hashid === project.project_hashid
                );
                if (index > -1) {
                    state.projects.splice(index, 1, project);
                }
            }
        },
        // update Role
        update_role(state, {params}) {
            const index = state.selected_project.members.findIndex(
                (b) => b.user_hashid === params.user_hashid
            );
            if (index > -1) {
                state.selected_project.members[index].role = params.role;
            }
        },

        index_invoices(state, {body: invoices}) {
            state.invoices = invoices;
        },

        destroy_invoice(state, {params}) {
            const index = state.invoices.findIndex(i => i.stripe_invoice_id === params.stripe_invoice_id);
            if (index > -1) {
                state.invoices.splice(index, 1);
            }
        },

        index_project_invoices(state, {body}) {
            state.project_invoices = body || [];
        },

        index_new_users(state, {body}) {
            state.new_users = body;
        },

        index_pricing_plan(state, {body}) {
            state.plans = body;
        },
        create_pricing_plan(state, {body}) {
            state.plans.push(body);
        },
        update_pricing_plan(state, {body}) {
            const index = state.plans.findIndex(p => p.pricing_plan_hashid === body.pricing_plan_hashid);
            if (index > -1) {
                state.plans.splice(index, 1, body);
            } else {
                state.plans.push(body);
            }
        },
        destroy_pricing_plan(state, {params}) {
            const index = state.plans.findIndex(p => p.pricing_plan_hashid === params.pricing_plan_hashid);
            if (index > -1) {
                state.plans.splice(index, 1);
            }
        },

        connected(state, flag) {
            state.is_connected = flag;
        },
        set_cached_time(state, time) {
            // console.log('M C TIME', time);
            state.cached_time = time;
        },
        modalData(state, data) {
            state.modal =
                (typeof data === "string" ? {action: data} : data) || null;
            if (!data) {
                state.selected_project = null;
                state.selected_account = null;
                state.selected_user = null;
                state.selected_invoice = null;
            }
        },
        set_loading(state, flag) {
            state.loading = flag;
        }
    },

    actions: {
        manageModal({commit}, data) {
            commit("modalData", data);
            $("#view-modal").modal(data ? "show" : "hide");
        },
    },

    getters: {
        is_connected: (s) => s.is_connected,
        modal: (s) => s.modal,
        cached_time: (s) => s.cached_time,
        e_cached_time_m: (s) => (typeof s.cached_time === "string" ? moment(s.cached_time) : moment()).add(5, "hours"),

        users: (s) => s.users,
        projects: (s) => s.projects,
        accounts: (s) => s.accounts,

        account_projects: (s) => s.account_projects,

        selected_user: (s) => s.selected_user,
        selected_account: (s) => s.selected_account,
        selected_project: (s) => s.selected_project,

        projects_summary: (s) => s.projects_summary,
        users_summary: (s) => s.users_summary,
        accounts_summary: (s) => s.accounts_summary,

        data_loading: s => s.loading,
        project_invoices: s => s.project_invoices,
        invoices: s => s.invoices,
        plans: s => s.plans,
        new_users: s=> s.new_users
    },
};

export const mapAdminActions = (data) => mapActions(ADMIN_MODULE_NAME, data);

export const mapAdminGetters = (data) => mapGetters(ADMIN_MODULE_NAME, data);

export const mapAdminMutations = (data) =>
    mapMutations(ADMIN_MODULE_NAME, data);

export default AdminModule;