import { RouteConfig } from "vue-router";

const ProductSettingsRoutes = [
  {
    path: "product-settings",
    name: "admin.productPricing",
    meta: { sidebar: "AdminSidebar" },
    component: () => import(/* webpackChunkName: "announcement" */ "./ProductSettings.vue"),
    children: [
      {
        path: "",
        name: "admin.productSettings.index",
        meta: { sidebar: "AdminSidebar" },
        component: () => import(/* webpackChunkName: "announcement" */ "./components/products/ProductIndex.vue"),
      },
    ],
  },
];

export default ProductSettingsRoutes;
