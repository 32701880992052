import {mapActions, mapGetters, mapMutations} from 'vuex';
import {railsToJSObj} from "../../../DirectME/constants";
import SuiteAppUtils from "../../utils.suite-app";

export const PROJECT_DASHBOARD_MODULE = 'projectdashboard';

const initState = {
    is_connected: false,
    recent_activity: [],
    counts: [],
    tasks: [],
    quick_setup: {}
};

const ProjectDashboardModule = {
    namespaced: true,
    state: () => initState,
    mutations: {
        // COMMON
        RESET(state) {
            state.is_connected = false;
            state.recent_activity = [];
            state.counts = [];
            state.tasks = [];
        },
        connected(state, flag) {
            state.is_connected = flag;
        },

        //LIST projects
        index_recent_activity(state, {body: data, params}) {
            state.recent_activity = data;
        },

        show(state, {body: data, params}) {
            state.counts = data.counts;
            state.quick_setup = data.quick_setup;
        },

        index_tasks(state, {body: data, params}) {
            state.tasks = data;
        },

        update_quick_setup(state, {body, params}) {
            state.quick_setup[params.key] = true;
        }

    },
    actions: {},
    getters: {
        is_connected: s => s.is_connected,
        recent_activity: s => s.recent_activity,
        counts: s => s.counts,
        tasks: s => s.tasks,
        quick_setup: s => s.quick_setup,
    }
};

export const mapProjectDashboardActions = data => mapActions(PROJECT_DASHBOARD_MODULE, data);
export const mapProjectDashboardGetters = data => mapGetters(PROJECT_DASHBOARD_MODULE, data);

export const mapProjectDashboardMutations = data => mapMutations(PROJECT_DASHBOARD_MODULE, data);

export default ProjectDashboardModule