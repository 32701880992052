import {mapActions, mapGetters, mapMutations} from "vuex";

export const WORKSPACE_MODULE_NAME = "workspace";

export const WORKSPACE_MODAL_ACTIONS = {
    WORKSPACE_CREATE_MODAL: 'workspace_create_modal',
    PAYMENT_METHOD_MODAL: 'payment_method_modal',
    UPDATE_SUBSCRIPTION_MODAL: 'update_subscription_modal'
};

const initState = {
    is_connected: false,
    modal: null,
    payment_methods: [],
    products: [],
    accounts: [],
    is_open_subscription_form: false,
};

const WorkspaceModule = {
    namespaced: true,
    state: () => initState,
    mutations: {
        connected(state, flag) {
            state.is_connected = flag;
        },
        modalData(state, data) {
            state.modal =
                (typeof data === "string" ? {action: data} : data) || null;
        },
        index_payment_methods(state, {body: data, params}) {
            data.payment_methods = data.payment_methods.map(m => {
                let res = [m.response.brand, m.response.funding, m.response.ending, m.response.last4];
                m.title = res.filter(i => !!i).join(" ") + " - " + m.response.exp_month + "/" + m.response.exp_year;
                return m;
            });

            state.payment_methods = data.payment_methods;
        },
        get_product_pricings(state, {body}) {
            state.products = body;
        },
        create_subscription(state, {body}) {
            state.is_open_subscription_form = false;
        },
        toggle_subscription_form(state) {
            state.is_open_subscription_form = !state.is_open_subscription_form;
        }
    },
    actions: {
        manageModal({commit}, data) {
            commit("modalData", data);
            $("#workspaces-modal").modal(data ? "show" : "hide");
        },
        toggleSubscriptionForm({commit}) {
            commit('toggle_subscription_form');
        }
    },
    getters: {
        is_connected: (s) => s.is_connected,
        modal: (s) => s.modal,
        products: (s) => s.products,
        is_open_subscription_form: (s) => s.is_open_subscription_form,
    }
};

export const mapWorkspaceActions = (data) => mapActions(WORKSPACE_MODULE_NAME, data);

export const mapWorkspaceGetters = (data) => mapGetters(WORKSPACE_MODULE_NAME, data);

export const mapWorkspaceMutations = (data) => mapMutations(WORKSPACE_MODULE_NAME, data);

export default WorkspaceModule;