import {RouteConfig} from "vue-router";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "announcement" <<
 * where ever we mention this, it will group all components in one group.
 */
const AnnouncementRoutes = [
    {
        path: "announcements",
        name: "admin.announcements",
        component: () =>
            import(/* webpackChunkName: "announcement" */ "./Announcement.vue"),
        meta:{sidebar:"AdminSidebar"},
        children: [
            {
                path: "",
                name: "admin.announcements.index",
                component: () =>
                    import(
                        /* webpackChunkName: "announcement" */ "./pages/AnnouncementIndex.vue"
                        ),
                meta:{sidebar:"AdminSidebar"},
            },
            {
                path: "new",
                name: "admin.announcements.new",
                component: () =>
                    import(
                        /* webpackChunkName: "announcement" */ "./pages/CreateAnnouncement.vue"
                        ),
                meta:{sidebar:"AdminSidebar"},
            },
        ],
    },
];

export default AnnouncementRoutes;
