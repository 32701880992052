import Vue from "vue";
import Vuex, {Store} from "vuex";
import {IS_DEV} from "../utils";
import ProjectModule, {PROJECT_MODULE_NAME} from "./Project/store/project.vuex";

import DirectMEModule, {DIRECTME_MODULE_NAME} from "./DirectME/store/directme.vuex";
import ReleaseMEModule, {RELEASEME_MODULE_NAME} from "./ReleaseME/store/releaseme.vuex";
import DeliverMeListModule, {DELIVERME_MODULE_NAME} from "./DeliverME/store/deliverme.vuex";
import TaskManagerModule, {TASKMANAGER_MODULE_NAME} from "./TaskManager/store/taskmanager.vuex";
import DocumentManagerModule, {DOCUMENT_MANAGER_MODULE_NAME} from "./Documents/store/document.vuex";
import PayMEModule, {PAYME_MODULE_NAME} from "./PayME/store/payme.vuex";
import ContactManagerModule, {CONTACTMANAGER_MODULE_NAME} from './ContactManager/store/contactmanager.vuex'
import ProfileSettingsModule, {PROFILESETTINGS_MODULE_NAME} from "./ProfileSettings/store/profilesettings.vuex";
import ProjectDashboardModule, {PROJECT_DASHBOARD_MODULE} from "./ProjectDashboard/store/projectdashboard.vuex";
import AdminModule, {ADMIN_MODULE_NAME} from "./Admin/View/store/admin.vuex";
import AnnouncementModule, {ANNOUNCEMENT_MODULE_NAME} from "./Admin/Announcements/store/announcement.vuex";
import VideoTranscriptModule, {VIDEOTRANSCRIPT_MODULE_NAME} from "./Admin/VideoTranscripts/store/videotranscript.vuex";
import cache, {CACHE_KEYS, CacheUtils} from "./Services/Cache/cache.suite-app";
import moment from "moment";
import CallSheetModule, {CALL_SHEET_MODULE_NAME} from "./Documents/CallSheet/store/call-sheet.vuex";
import MediaMEModule, {MEDIAME_MODULE_NAME} from "./MediaME/store/mediame.vuex";
import CalendarModule, {CALENDAR_MODULE_NAME} from "./Calendar/store/calendar.vuex";
import ProductModule, {PRODUCT_MODULE_NAME} from "./Admin/ProductSettings/store/productSettings.vuex";
import WorkspaceModule, {WORKSPACE_MODULE_NAME} from "./Workspace/store/workspace.vuex";
import SidebarModule,{SIDEBAR_MODULE_NAME} from "./Common/Sidebar/sidebar.vuex"

// apply Vuex to Vue
Vue.use(Vuex);

const store = new Store({
    devtools: IS_DEV,
    modules: {

        [PROJECT_MODULE_NAME]: ProjectModule,

        [PROJECT_DASHBOARD_MODULE]: ProjectDashboardModule,
        [DOCUMENT_MANAGER_MODULE_NAME]: DocumentManagerModule,
        [CONTACTMANAGER_MODULE_NAME]: ContactManagerModule,

        [TASKMANAGER_MODULE_NAME]: TaskManagerModule,
        [RELEASEME_MODULE_NAME]: ReleaseMEModule,
        [DIRECTME_MODULE_NAME]: DirectMEModule,
        [DELIVERME_MODULE_NAME]: DeliverMeListModule,
        [PAYME_MODULE_NAME]: PayMEModule,

        [PROFILESETTINGS_MODULE_NAME]: ProfileSettingsModule,

        [ADMIN_MODULE_NAME]: AdminModule,
        [ANNOUNCEMENT_MODULE_NAME]: AnnouncementModule,
        [VIDEOTRANSCRIPT_MODULE_NAME]: VideoTranscriptModule,
        [CALL_SHEET_MODULE_NAME]: CallSheetModule,
        [MEDIAME_MODULE_NAME]: MediaMEModule,
        [CALENDAR_MODULE_NAME]: CalendarModule,
        [PRODUCT_MODULE_NAME]: ProductModule,
        [WORKSPACE_MODULE_NAME]: WorkspaceModule,
        [SIDEBAR_MODULE_NAME]:SidebarModule
    }
});

(async () => {
    await cache.ready(async () => {
        // if key exists in DB
        if ((await cache.keys()).find(k => CACHE_KEYS.ADMIN_CACHE_TIME)) {
            // console.log("ADMIN CACHE exits");

            const time = await cache.getItem(CACHE_KEYS.ADMIN_CACHE_TIME);
            store.commit(`${ADMIN_MODULE_NAME}/set_cached_time`, time);

            if (typeof time === "string" && store.getters[`${ADMIN_MODULE_NAME}/e_cached_time_m`] > moment()) {
                let payload = await cache.getItem(CACHE_KEYS.ADMIN_PROJECTS);
                store.commit(`${ADMIN_MODULE_NAME}/index_projects`, {body: payload});

                payload = await cache.getItem(CACHE_KEYS.ADMIN_USERS);
                store.commit(`${ADMIN_MODULE_NAME}/index_users`, {body: payload || []});

                payload = await cache.getItem(CACHE_KEYS.ADMIN_ACCOUNTS);
                store.commit(`${ADMIN_MODULE_NAME}/index_accounts`, {body: payload || []});
            } else {
                // data has expired so remove it
                await CacheUtils.clearAdmin();
            }

        }
    });
})();

export default store;