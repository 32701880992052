import { RouteConfig } from "vue-router";
import CallSheetRoutes from "./CallSheet/call-sheet.routes";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "documents" <<
 * where ever we mention this, it will group all components in one group.
 */
const DocumentsRoutes = [
  {
    path: "document",
    component: () => import(/* webpackChunkName: "documents" */ "./Documents.vue"),
    children: [
      ...CallSheetRoutes,

      {
        path: "",
        name: "documents.index",
        component: () => import(/* webpackChunkName: "documents" */ "./pages/DocumentIndex.vue"),
        meta: {
          topbar: true,
          project_title: true,
          sub_sidebar: "FilesSubSidebar",
        },
      },
      {
        path: "signed",
        name: "documents.signed_release",
        component: () => import(/* webpackChunkName: "documents" */ "./pages/SignedReleases.vue"),
        meta: { topbar: true, sub_sidebar: "FilesSubSidebar", project_title: true },
      },
      {
        path: "report",
        name: "documents.video",
        component: () => import(/* webpackChunkName: "documents" */ "./pages/releases/Report.vue"),
        meta: { topbar: true, sub_sidebar: "FilesSubSidebar", project_title: true },
      },
      {
        path: "download",
        name: "documents.download",
        component: () => import(/* webpackChunkName: "documents" */ "./pages/releases/Download.vue"),
        meta: { topbar: true, project_title: true, sub_sidebar: "FilesSubSidebar" },
      },
      {
        path: "email-documents",
        name: "documents.emailed_documents",
        component: () => import(/* webpackChunkName: "documents" */ "./pages/releases/EmailDocumentsV2.vue"),
        meta: { topbar: true, project_title: true },
      },
      {
        path: "business",
        name: "documents.business_documents",
        component: () => import(/* webpackChunkName: "documents" */ "./pages/releases/Business.vue"),
        meta: { topbar: true, project_title: true, sub_sidebar: "FilesSubSidebar" },
      },
      {
        path: "smart-tag/:id",
        name: "documents.smart-tags.index",
        component: () => import(/* webpackChunkName: "documents" */ "./pages/smartTags/SmartTagIndex.vue"),
        props: true,
        meta: { sub_sidebar: "FilesSubSidebar", topbar: true, project_title: true },
      },
      {
        path: "custom_folder/:id",
        name: "documents.custom-folder.index",
        component: () => import(/* webpackChunkName: "documents" */ "./pages/custom/CustomIndex.vue"),
        props: true,
        meta: { sub_sidebar: "FilesSubSidebar", project_title: true },
      },
      {
        path: "counter-sign/:id",
        name: "documents.counter-signature",
        component: () => import(/* webpackChunkName: "documents" */ "./pages/CounterSignature.vue"),
        meta: { topbar: true, sub_sidebar: "FilesSubSidebar", project_title: true },
      },
      {
        path: "media",
        name: "documents.media",
        component: () => import(/* webpackChunkName: "mediames" */ "../MediaME/pages/MediaMEIndex.vue"),
        meta: { topbar: true, sub_sidebar: "FilesSubSidebar", project_title: true },
      },
    ],
  },
];

export default DocumentsRoutes;
