import { RouteConfig } from "vue-router";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "directmes" <<
 * where ever we mention this, it will group all components in one group.
 */
const DirectMERoutes = [
  {
    path: "directme",
    component: () => import(/* webpackChunkName: 'directmes' */ "./DirectME.vue"),
    children: [
      {
        path: "",
        name: "directmes.index",
        component: () => import(/* webpackChunkName: "directmes" */ "./pages/DirectMEIndex.vue"),
        meta: { topbar: true, project_title: true },
      },
    ],
  },
];

export default DirectMERoutes;
