import { mapActions, mapGetters, mapMutations } from "vuex";

export const CALENDAR_MODULE_NAME = "calendar";
export const CALENDAR_MODAL_ACTIONS = {
  EVENT: "event",
};

const initState = {
  is_connected: false,
  modal: null,
  events: [],
  event: {},
  users: [],
  callsheets: [],
  config: {},
};

const CalendarModule = {
  namespaced: true,
  state: () => initState,
  mutations: {
    // COMMON
    RESET(state) {
      state.is_connected = false;
      state.events = [];
      state.event = {};
      state.users = [];
      state.callsheets = [];
      state.config = {};
    },
    connected(state, flag) {
      state.is_connected = flag;
    },
    index_calendar_event(state, { body: calendar, params }) {
      let schedules = []; // Create an empty array to store schedule data

      let formattedStartTime = null;
      let formattedEndTime = null;

      // schedule
      state.callsheets.forEach((inputObject) => {
        let scheduleData = JSON.parse(inputObject.schedule);

        let scheduleItems = scheduleData.map((scheduleItem) => {
          if (scheduleItem.start_time && scheduleItem.end_time) {
            // Parse the date string and extract year, month, and day
            const [year, month, day] = inputObject.date.split("-").map(Number);

            // Parse the start time string and extract hours and minutes
            const [startHours, startMinutes] = scheduleItem.start_time
              .split(":")
              .map(Number);

            // Parse the end time string and extract hours and minutes
            const [endHours, endMinutes] = scheduleItem.end_time
              .split(":")
              .map(Number);

            // Create a new Date object for the start time
            const startTime = new Date(
              year,
              month - 1,
              day,
              startHours,
              startMinutes
            );

            // Create a new Date object for the end time
            const endTime = new Date(
              year,
              month - 1,
              day,
              endHours,
              endMinutes
            );

            // Format the resulting dates as strings in the desired format
            formattedStartTime = startTime.toISOString();
            formattedEndTime = endTime.toISOString();
          }
          return {
            id: scheduleItem.callsheet_hashid,
            title: scheduleItem.description,
            start: formattedStartTime,
            end: formattedEndTime,
            color: "rgb(60 186 155)",
            extendedProps: {
              icon: "https://i.ibb.co/MBpHZkg/icons8-eye-30.png",
              allDay: true,
              rrule: "",
              description: "",
              repeatFrequency: "",
              includedPeople: "",
              meetingLocation: scheduleItem.location,
              specificEventInstanceId: "",
              notes: scheduleItem.notes,
              isRecurring: "",
              color: "rgb(60 186 155)",
              type: "schedule",
              callsheet_hashid: inputObject.callsheet_hashid,
              url: `/#/projects/${inputObject.project_hashid}/document/callsheets/edit/${inputObject.callsheet_hashid}`,
            },
          };
        });

        schedules = schedules.concat(scheduleItems);
      });

      // events
      let events = calendar.map((inputObject) => {
        let start_data, end_data;
        if (inputObject.all_day) {
          start_data = new Date(`${inputObject.start_date}`)
            .toISOString()
            .split("T")[0];
          // end_data = new Date(`${inputObject.end_date}`)
          //   .toISOString()
          //   .split("T")[0];
          const endDate = new Date(`${inputObject.end_date}`);
          endDate.setDate(endDate.getDate() + 1);
          end_data = endDate.toISOString().split("T")[0];
        } else {
          start_data = inputObject.start_date;
          end_data = inputObject.end_date;
        }
        return {
          id: inputObject.id,
          title: inputObject.title,
          start: start_data,
          end: end_data,
          color: inputObject.color,
          all_day: inputObject.all_day,
          rrule: inputObject.rrule,
          extendedProps: {
            allDay: inputObject.all_day,
            rrule: inputObject.rrule,
            description: inputObject.description,
            repeatFrequency: inputObject.repeat_frequency,
            includedPeople: inputObject.included_people,
            meetingLocation: inputObject.meeting_location,
            specificEventInstanceId: inputObject.id,
            notes: inputObject.notes,
            isRecurring: inputObject.is_recurring,
            color: inputObject.color,
            type: "event",
          },
        };
      });

      // Callsheeets
      const combinedArray = state.callsheets
        .map((inputObject) => {
          return {
            id: inputObject.callsheet_hashid,
            title: "Call Sheet - " + inputObject.start_time,
            start: inputObject.date,
            end: inputObject.date,
            icon: "https://i.ibb.co/MBpHZkg/icons8-eye-30.png",
            color: "rgb(60 186 155)",
            extendedProps: {
              icon: "https://i.ibb.co/MBpHZkg/icons8-eye-30.png",
              cameraIcon: "https://i.ibb.co/WGBD4bR/icons8-camera-50.png",
              allDay: true,
              rrule: "",
              url: `/#/projects/${inputObject.project_hashid}/document/callsheets/edit/${inputObject.callsheet_hashid}`,
              description: "",
              repeatFrequency: "",
              includedPeople: "",
              meetingLocation: "",
              specificEventInstanceId: "",
              notes: "",
              isRecurring: "",
              color: "",
              type: "callsheet",
            },
          };
        })
        .concat(schedules)
        .concat(events);

      state.events = combinedArray;
    },
    create_calendar_event(state, { body: payload, params }) {
      if (payload) {
        let start_data, end_data;
        if (payload.all_day) {
          start_data = new Date(`${payload.start_date}`)
            .toISOString()
            .split("T")[0];
          const endDate = new Date(`${payload.end_date}`);
          endDate.setDate(endDate.getDate() + 1);
          end_data = endDate.toISOString().split("T")[0];
        } else {
          start_data = payload.start_date;
          end_data = payload.end_date;
        }
        console.log(payload, "payload in create event");
        state.events.unshift({
          id: payload.id,
          title: payload.title,
          start: start_data,
          end: end_data,
          color: payload.color,
          all_day: payload.all_day,
          rrule: payload.rrule,
          extendedProps: {
            allDay: payload.all_day,
            description: payload.description,
            repeatFrequency: payload.repeat_frequency,
            includedPeople: payload.included_people,
            meetingLocation: payload.meeting_location,
            specificEventInstanceId: payload.id,
            notes: payload.notes,
            isRecurring: payload.is_recurring,
            color: payload.color,
            type: "event",
          },
        });
      }
    },
    update_calendar_event(state, { body: payload, params }) {
      if (payload) {
        let start_data, end_data;
        if (payload.all_day) {
          start_data = new Date(`${payload.start_date}`)
            .toISOString()
            .split("T")[0];

          const endDate = new Date(`${payload.end_date}`);
          endDate.setDate(endDate.getDate() + 1);
          end_data = endDate.toISOString().split("T")[0];
          // end_data = new Date(`${payload.end_date}`)
          //   .toISOString()
          //   .split("T")[0];
        } else {
          start_data = payload.start_date;
          end_data = payload.end_date;
        }
        const index = state.events.findIndex((b) => b.id === payload.id);
        if (index > -1) {
          let setPayload = {
            id: payload.id,
            title: payload.title,
            start: start_data,
            end: end_data,
            color: payload.color,
            all_day: payload.all_day,
            rrule: payload.rrule,
            extendedProps: {
              allDay: payload.all_day,
              description: payload.description,
              repeatFrequency: payload.repeat_frequency,
              includedPeople: payload.included_people,
              meetingLocation: payload.meeting_location,
              specificEventInstanceId: payload.id,
              notes: payload.notes,
              isRecurring: payload.is_recurring,
              color: payload.color,
              type: "event",
            },
          };
          state.events.splice(index, 1, setPayload);
        }
      }
    },
    destroy_calendar_event(state, { body: event, params }) {
      const index = state.events.findIndex((b) => b.id === event.id);
      if (index > -1) {
        state.events.splice(index, 1);
      }
    },

    // Users
    index_users(state, { body: users, params }) {
      state.users = users;
    },

    // Callsheets
    index_callsheets(state, { body: data, params }) {
      const filteredCallsheets = data.callsheets.filter(
        (callsheet) => callsheet.status === "published"
      );

      state.callsheets = filteredCallsheets;
    },

    modalData(state, data) {
      if (state.modal) {
        switch (state.modal.action) {
          case CALENDAR_MODAL_ACTIONS.EVENT:
        }
      }
      state.modal =
        (typeof data === "string" ? { action: data } : data) || null;
    },

    setData(state, data) {
      state.config = data;
    },
  },
  actions: {
    init({ commit, rootGetters }) {
      let data = {
        user: rootGetters["project/current_user"],
        project_id: rootGetters["project/selected_project_id"],
        account_slug: rootGetters["project/selected_account_slug"],
      };
      commit("setData", data);
    },
    manageModal({ commit }, data) {
      commit("modalData", data);
      $("#calendar-modal").modal(data ? "show" : "hide");
    },
  },
  getters: {
    is_connected: (s) => s.is_connected,
    modal: (s) => s.modal,
    events: (s) => s.events,
    event: (s) => s.event,
    users: (s) => s.users,
    callsheets: (s) => s.callsheets,
  },
};

export const mapCalendarActions = (data) =>
  mapActions(CALENDAR_MODULE_NAME, data);
export const mapCalendarGetters = (data) =>
  mapGetters(CALENDAR_MODULE_NAME, data);

export const mapCalendarMutations = (data) =>
  mapMutations(CALENDAR_MODULE_NAME, data);

export default CalendarModule;
