import { mapActions, mapGetters, mapMutations } from "vuex";

export const DOCUMENT_MODAL_ACTIONS = {
  ADD_TAG: "add_tag",
  ADD_NOTE: "add_note",
  EDIT: "edit",
  UPDATE_PERMISSION: "update_permission",
  EDIT_CUSTOM_FOLDER: "edit_custom_folder",
  EDIT_SMART_TAG: "edit_smart_tag",
  DELETE_SMART_TAG: "delete_smart_tag",

  MEDIA: "MEDIA",
  SELFIE: "SELFIE",

  IMPORT_RELEASE: "IMPORT_RELEASE",
  EDIT_RELEASE: "EDIT_RELEASE",
  EDIT_SMART_TAG_RELEASE: "EDIT_SMART_TAG_RELEASE",
  SHOW_IMAGE: "SHOW_IMAGE",
  SHOW_FIELDS: "SHOW_FIELDS",
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DOCUMENT_MANAGER_MODULE_NAME = "documentmanager";

const isTagItem = (item, tag_id) => {
  if (!item.tags) return false;
  let parsed_tags = null;
  try {
    parsed_tags = JSON.parse(item.tags);
  } catch (e) {
    return false;
  }
  if (!parsed_tags || !parsed_tags.length) return false;

  return parsed_tags.some((each_tag) => parseInt(each_tag.id) === parseInt(tag_id));
};

const initState = {
  is_connected: false,
  isLoading: { index_appearance: true, index_download: true, index_reports: true, index_business: true },

  folders: [],
  import_release_url: [],
  permissions: [],
  tags: [],
  release_tags: [],

  modal: null,
  file_permissions: [],

  reports: [],
  downloads: [],
  custom_folder: [],

  note_data: [],
  images: [],

  acquired_media_releases: [],
  appearance_releases: [],
  location_releases: [],
  medical_releases: [],
  material_releases: [],
  misc_releases: [],
  music_releases: [],
  smart_tags: [],
  talent_releases: [],
  business_releases: [],
  email_documents: [],

  selected_import_release: null,
  assets: [],

  active_tab: null,
  currPg: 1,
  last_visited_page: { route_name: null, selected_option: null, active_tab: null },
};

const DocumentManagerModule = {
  namespaced: true,
  state: () => initState,
  mutations: {
    // COMMON
    RESET(state) {
      state.is_connected = false;

      state.folders = [];
      state.import_release_url = [];
      state.permissions = [];
      state.tags = [];
      state.release_tags = [];

      state.modal = null;
      state.file_permissions = [];

      state.reports = [];
      state.downloads = [];
      state.custom_folder = [];

      state.note_data = [];
      state.images = [];

      state.acquired_media_releases = [];
      state.appearance_releases = [];
      state.location_releases = [];
      state.medical_releases = [];
      state.material_releases = [];
      state.misc_releases = [];
      state.music_releases = [];
      state.smart_tags = [];
      state.talent_releases = [];
      state.business_releases = [];
      state.email_documents = [];
      state.assets = [];
      state.currPg = 1;
    },
    connected(state, flag) {
      state.is_connected = flag;
    },

    setLoading(state, { action: action_name, flag = true }) {
      state.isLoading[action_name] = flag;
    },

    // INDEX PAGE LIST
    index(state, { body: data, params }) {
      state.folders = data.folders;
      state.import_release_url = data.import_release_url;
      state.permissions = data.permissions;
      state.tags = data.tags;
    },

    // calling from frontend only
    create_tag(state, { body: data, params }) {
      params.releasable_ids.forEach((releasableId) => {
        let release = state.smart_tags.find((r) => r.id === releasableId);
        if (!release) {
          if (params.for) {
            release = state.email_documents.find((r) => r.id === releasableId);
          } else {
            release = state[params.releasable_name].find((r) => r.id === releasableId);
          }
        }
        if (release) {
          release.tags.unshift(params.name);
        }
      });
    },

    destroy_tag(state, { body: data, params }) {
      params.releasable_ids.forEach((releasableId) => {
        let release = state.smart_tags.find((r) => r.id === releasableId);
        if (!release) {
          if (params.for) {
            release = state.email_documents.find((r) => r.id === releasableId);
          } else {
            release = state[params.releasable_name].find((r) => r.id === releasableId);
          }
        }
        if (release) {
          let index = release.tags.findIndex((r) => r === params.name);
          if (index > -1) {
            release.tags.splice(index, 1);
          }
        }
      });
    },

    show_import_release(state, { body: data, params }) {
      state.selected_import_release = data;
    },

    index_files_by_release(state, { body: data, params }) {
      state.images = data;
    },
    destroy_images(state) {
      state.images = [];
    },
    destroy_release(state, { body: data, params }) {
      // we only destroy release
      const typeOfRelease = data.table.releasable_name.concat("_releases");
      const index = state[typeOfRelease].findIndex((a) => a.id === data.table.id);
      if (index > -1) {
        state[typeOfRelease].splice(index, 1);
      } else {
        // For smart tags
        const smartTagIndex = state.smart_tags.findIndex(
          (a) => a.id === data.table.id && a.release_table_name === typeOfRelease
        );
        if (smartTagIndex > -1) {
          state.smart_tags.splice(smartTagIndex, 1);
        }
      }
    },

    destroy_invite_to_contract(state, { body: data, params }) {
      params.invite_hashids.forEach((invite_hashid) => {
        const index = state.email_documents.findIndex((a) => a.invite_hashid === invite_hashid);
        if (index > -1) {
          state.email_documents.splice(index, 1);
        }
      });
    },

    create_note(state, { body: data, params }) {
      let release = null;
      // 1. Find in releases
      if (params.for) {
        release = state.email_documents.find((r) => r.id === params.releasable_id);
      } else {
        release = state[params.releasable_name].find((r) => r.id === params.releasable_id);
      }

      if (!release) {
        release = state.smart_tags.find((r) => r.id === params.releasable_id);
      }
      // add note to release
      release.notes.unshift(data);
      // rehash for search
      release.hash = `${release.name} ${release.address} ${release.phone} ${release.email} ${
        release.signed_at
      } ${release.notes.map((n) => n.content).join(" ")}`.toUpperCase();
    },

    update_note(state, { body: data, params }) {
      let release = null;
      // 1. Find in releases
      if (params.for) {
        release = state.email_documents.find((r) => r.id === params.releasable_id);
      } else {
        release = state[params.releasable_name].find((r) => r.id === params.releasable_id);
      }
      if (!release) {
        release = state.smart_tags.find((r) => r.id === params.releasable_id);
      }

      // update the note
      let index = release.notes.findIndex((r) => r.id === params.id);
      if (index > -1) {
        release.notes.splice(index, 1, data);
      }
      // rehash for search
      release.hash = `${release.name} ${release.address} ${release.phone} ${release.email} ${
        release.signed_at
      } ${release.notes.map((n) => n.content).join(" ")}`.toUpperCase();
    },

    destroy_note(state, { body: data, params }) {
      let release = null;
      // 1. Find in releases
      if (params.for) {
        release = state.email_documents.find((r) => r.id === params.releasable_id);
      } else {
        release = state[params.releasable_name].find((r) => r.id === params.releasable_id);
      }
      if (!release) {
        release = state.smart_tags.find((r) => r.id === params.releasable_id);
      }
      // delete note in release
      let index = release.notes.findIndex((r) => r.id === params.id);

      if (index > -1) {
        release.notes.splice(index, 1);
      }
      // rehash for search
      release.hash = `${release.name} ${release.address} ${release.phone} ${release.email} ${
        release.signed_at
      } ${release.notes.map((n) => n.content).join(" ")}`.toUpperCase();
    },

    // APPEARANCE LIST
    index_appearance(state, { body: data, params }) {
      let tags = {};
      state.appearance_releases = data.map((r) => {
        let t = {};
        JSON.parse(r.notes)
          .filter((i) => !!i.id)
          .forEach((n) => {
            t[n.id] = n;
          });
        r.notes = Object.values(t).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        t = {};
        JSON.parse(r.tags).filter((i) => {
          if (!!i) {
            t[i] = tags[i] = i;
            return true;
          }
          return false;
        });
        r.tags = Object.keys(t);
        r.hash = `${r.name} ${r.address} ${r.phone} ${r.email} ${r.signed_at} ${r.notes
          .map((n) => n.content)
          .join(" ")}`.toUpperCase();
        r.month = new Date(r.signed_at).getMonth();
        return r;
      });
      state.release_tags = Object.values(tags);
      if (state.modal !== null && state.modal.action === DOCUMENT_MODAL_ACTIONS.ADD_TAG) {
        let ids = state.modal.payload.releases.map((r) => r.id);
        state.modal.payload.releases = state.appearance_releases.filter((r) => ids.includes(r.id));
      }

      state.isLoading.index_appearance = false;
    },

    // LOCATION LIST
    index_location(state, { body: data, params }) {
      let tags = {};
      state.location_releases = data.map((r) => {
        let t = {};
        //removing duplicate note....
        JSON.parse(r.notes)
          .filter((i) => !!i.id)
          .forEach((n) => {
            t[n.id] = n;
          });
        r.notes = Object.values(t);
        r.notes.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        t = {};
        JSON.parse(r.tags).filter((i) => {
          if (!!i) {
            t[i] = tags[i] = i;
            return true;
          }
          return false;
        });
        r.tags = Object.keys(t);
        r.is_contract_attached = false;
        r.image = -2; // default value = -2, loading = -1, no image = null, has image = URL
        r.hash = `${r.name} ${r.address} ${r.phone} ${r.email}.${r.location_name}.${r.location_address} ${
          r.signed_at
        } ${r.notes.map((n) => n.content).join(" ")}`.toUpperCase();
        r.month = new Date(r.signed_at).getMonth();
        return r;
      });
      state.release_tags = Object.values(tags);
      if (state.modal !== null && state.modal.action === DOCUMENT_MODAL_ACTIONS.ADD_TAG) {
        let ids = state.modal.payload.releases.map((r) => r.id);
        state.modal.payload.releases = state.location_releases.filter((r) => ids.includes(r.id));
      }
    },

    // ACQUIRED MEDIA LIST
    index_acquired_media(state, { body: data, params }) {
      let tags = {};
      state.acquired_media_releases = data.map((r) => {
        let t = {};
        JSON.parse(r.notes)
          .filter((i) => !!i.id)
          .forEach((n) => {
            t[n.id] = n;
          });
        r.notes = Object.values(t).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        t = {};
        JSON.parse(r.tags).filter((i) => {
          if (!!i) {
            t[i] = tags[i] = i;
            return true;
          }
          return false;
        });
        r.tags = Object.keys(t);
        r.hash = `${r.name} ${r.address} ${r.phone} ${r.email} ${r.signed_at} ${r.notes
          .map((n) => n.content)
          .join(" ")}`.toUpperCase();
        r.month = new Date(r.signed_at).getMonth();
        return r;
      });
      state.release_tags = Object.values(tags);
      if (state.modal !== null && state.modal.action === DOCUMENT_MODAL_ACTIONS.ADD_TAG) {
        let ids = state.modal.payload.releases.map((r) => r.id);
        state.modal.payload.releases = state.acquired_media_releases.filter((r) => ids.includes(r.id));
      }
    },

    // MATERIALS LIST
    index_material(state, { body: data, params }) {
      let tags = {};
      state.material_releases = data.map((r) => {
        let t = {};
        JSON.parse(r.notes)
          .filter((i) => !!i.id)
          .forEach((n) => {
            t[n.id] = n;
          });
        r.notes = Object.values(t).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        t = {};
        JSON.parse(r.tags).filter((i) => {
          if (!!i) {
            t[i] = tags[i] = i;
            return true;
          }
          return false;
        });
        r.tags = Object.keys(t);
        r.hash = `${r.name} ${r.address} ${r.phone} ${r.email} ${r.signed_at} ${r.notes
          .map((n) => n.content)
          .join(" ")}`.toUpperCase();
        r.month = new Date(r.signed_at).getMonth();
        return r;
      });
      state.release_tags = Object.values(tags);
      if (state.modal !== null && state.modal.action === DOCUMENT_MODAL_ACTIONS.ADD_TAG) {
        let ids = state.modal.payload.releases.map((r) => r.id);
        state.modal.payload.releases = state.material_releases.filter((r) => ids.includes(r.id));
      }
    },
    // MEDICAL LIST
    index_medical(state, { body: data, params }) {
      let tags = {};
      state.medical_releases = data.map((r) => {
        let t = {};
        JSON.parse(r.notes)
          .filter((i) => !!i.id)
          .forEach((n) => {
            t[n.id] = n;
          });
        r.notes = Object.values(t).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        t = {};
        JSON.parse(r.tags).filter((i) => {
          if (!!i) {
            t[i] = tags[i] = i;
            return true;
          }
          return false;
        });
        r.tags = Object.keys(t);
        r.hash = `${r.name} ${r.address} ${r.phone} ${r.email} ${r.signed_at} ${r.notes
          .map((n) => n.content)
          .join(" ")}`.toUpperCase();
        r.month = new Date(r.signed_at).getMonth();
        return r;
      });
      state.release_tags = Object.values(tags);
      if (state.modal !== null && state.modal.action === DOCUMENT_MODAL_ACTIONS.ADD_TAG) {
        let ids = state.modal.payload.releases.map((r) => r.id);
        state.modal.payload.releases = state.medical_releases.filter((r) => ids.includes(r.id));
      }
    },

    // MISC LIST
    index_misc(state, { body: data, params }) {
      let tags = {};
      state.misc_releases = data.map((r) => {
        let t = {};
        JSON.parse(r.notes)
          .filter((i) => !!i.id)
          .forEach((n) => {
            t[n.id] = n;
          });
        r.notes = Object.values(t).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        t = {};
        JSON.parse(r.tags).filter((i) => {
          if (!!i) {
            t[i] = tags[i] = i;
            return true;
          }
          return false;
        });
        r.tags = Object.keys(t);
        r.hash = `${r.name} ${r.address} ${r.phone} ${r.email} ${r.signed_at} ${r.notes
          .map((n) => n.content)
          .join(" ")}`.toUpperCase();
        r.month = new Date(r.signed_at).getMonth();
        return r;
      });
      state.release_tags = Object.values(tags);
      if (state.modal !== null && state.modal.action === DOCUMENT_MODAL_ACTIONS.ADD_TAG) {
        let ids = state.modal.payload.releases.map((r) => r.id);
        state.modal.payload.releases = state.misc_releases.filter((r) => ids.includes(r.id));
      }
    },

    // MUSIC LIST
    index_music(state, { body: data, params }) {
      let tags = {};
      state.music_releases = data.map((r) => {
        let t = {};
        JSON.parse(r.notes)
          .filter((i) => !!i.id)
          .forEach((n) => {
            t[n.id] = n;
          });
        r.notes = Object.values(t).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        t = {};
        JSON.parse(r.tags).filter((i) => {
          if (!!i) {
            t[i] = tags[i] = i;
            return true;
          }
          return false;
        });
        r.tags = Object.keys(t);
        r.hash = `${r.name} ${r.address} ${r.phone} ${r.email} ${r.signed_at} ${r.notes
          .map((n) => n.content)
          .join(" ")}`.toUpperCase();
        r.month = new Date(r.signed_at).getMonth();
        return r;
      });
      state.release_tags = Object.values(tags);
      if (state.modal !== null && state.modal.action === DOCUMENT_MODAL_ACTIONS.ADD_TAG) {
        let ids = state.modal.payload.releases.map((r) => r.id);
        state.modal.payload.releases = state.medical_releases.filter((r) => ids.includes(r.id));
      }
    },

    // TALENT LIST
    index_talent(state, { body: data, params }) {
      let tags = {};
      state.talent_releases = data.map((r) => {
        let t = {};
        JSON.parse(r.notes)
          .filter((i) => !!i.id)
          .forEach((n) => {
            t[n.id] = n;
          });
        r.notes = Object.values(t).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        t = {};
        JSON.parse(r.tags).filter((i) => {
          if (!!i) {
            t[i] = tags[i] = i;
            return true;
          }
          return false;
        });
        r.tags = Object.keys(t);
        r.hash = `${r.name} ${r.address} ${r.phone} ${r.email} ${r.signed_at} ${r.notes
          .map((n) => n.content)
          .join(" ")}`.toUpperCase();
        r.month = new Date(r.signed_at).getMonth();
        return r;
      });
      state.release_tags = Object.values(tags);
      if (state.modal !== null && state.modal.action === DOCUMENT_MODAL_ACTIONS.ADD_TAG) {
        let ids = state.modal.payload.releases.map((r) => r.id);
        state.modal.payload.releases = state.talent_releases.filter((r) => ids.includes(r.id));
      }
    },

    index_invite_to_contracts(state, { body: data, params }) {
      let tags = {};
      state.email_documents = data.map((r) => {
        let t = {};
        if (r.notes) {
          JSON.parse(r.notes)
            .filter((i) => !!i.id)
            .forEach((n) => {
              t[n.id] = n;
            });
          r.notes = Object.values(t).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        }

        t = {};
        if (r.tags) {
          JSON.parse(r.tags).filter((i) => {
            if (!!i) {
              t[i] = tags[i] = i;
              return true;
            }
            return false;
          });
          r.tags = Object.keys(t);
        }

        r.hash = `${r.name} ${r.address} ${r.phone} ${r.email} ${r.signed_at}`.toUpperCase();
        r.month = new Date(r.signed_at).getMonth();
        return r;
      });
      state.release_tags = Object.values(tags);
      if (state.modal !== null && state.modal.action === DOCUMENT_MODAL_ACTIONS.ADD_TAG) {
        let ids = state.modal.payload.releases.map((r) => r.id);
        state.modal.payload.releases = state.email_documents.filter((r) => ids.includes(r.id));
      }
    },

    // BUSINESS LIST
    index_business(state, { body: data, params }) {
      let tags = {};
      state.business_releases = data.map((r) => {
        let t = {};
        JSON.parse(r.notes)
          .filter((i) => !!i.id)
          .forEach((n) => {
            t[n.id] = n;
          });
        r.notes = Object.values(t).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        t = {};
        JSON.parse(r.tags).filter((i) => {
          if (!!i) {
            t[i] = tags[i] = i;
            return true;
          }
          return false;
        });
        r.tags = Object.keys(t);
        r.hash = `${r.name} ${r.address} ${r.phone} ${r.email} ${r.signed_at} ${r.notes
          .map((n) => n.content)
          .join(" ")}`.toUpperCase();
        r.month = new Date(r.signed_at).getMonth();
        return r;
      });
      state.release_tags = Object.values(tags);
      console.log("business documents", state.business_releases);
      if (state.modal !== null && state.modal.action === DOCUMENT_MODAL_ACTIONS.ADD_TAG) {
        let ids = state.modal.payload.releases.map((r) => r.id);
        state.modal.payload.releases = state.business_release.filter((r) => ids.includes(r.id));
      }
      state.isLoading.index_business = false;
    },

    // SMART TAG LIST
    index_by_tag(state, { body: data, params }) {
      let tags = {};
      let t = {};
      let isTagIncluded = false;
      // flatten the array
      state.smart_tags = []
        .concat(...data)
        .map((r) => {
          t = {};
          isTagIncluded = isTagItem(r, params.tag_id);
          if (!isTagIncluded) {
            // if tag not included then remove release from list
            return null;
          } else console.log("Is Tag Item : ", JSON.parse(JSON.stringify(r)));
          //removing duplicate note....
          JSON.parse(r.notes)
            .filter((i) => !!i.id)
            .forEach((n) => {
              t[n.id] = n;
            });
          r.notes = Object.values(t);
          r.notes.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

          // Smart Tags Section
          t = {};
          r.tags = JSON.parse(r.tags).filter((i) => i.id !== null);
          r.tags.forEach((i) => {
            t[i.name] = tags[i.name] = i.name;
            /* if (i.selected) {
              isTagIncluded = true;
            }*/
          });
          r.tags = Object.keys(t);

          //Other Operations
          r.is_contract_attached = false;
          r.image = -2; // default value = -2, loading = -1, no image = null, has image = URL
          r.hash = `${r.name} ${r.address} ${r.phone} ${r.email} ${r.signed_at} ${r.notes
            .map((n) => n.content)
            .join(" ")}`.toUpperCase();
          r.month = new Date(r.signed_at).getMonth();
          return r;
        })
        .filter((r) => r !== null);
      state.release_tags = Object.values(tags);
    },

    get_file_permissions(state, { body: data, params }) {
      state.file_permissions = data;
    },

    index_download(state, { body: data, params }) {
      state.downloads =
        data.map((r) => {
          r.hash = `${r.name} ${r.release_type} ${r.status}`.toUpperCase();
          r.month = new Date(r.created_at).getMonth();
          return r;
        }) || [];

      state.isLoading.index_download = false;
    },

    index_reports(state, { body: data, params }) {
      state.reports =
        data.map((r) => {
          r.hash = `${r.report_name} ${r.name} ${r.ep_number}`.toUpperCase();
          r.month = new Date(r.published_at).getMonth();
          return r;
        }) || [];

      state.isLoading.index_reports = false;
    },

    // ################# FOLDER #################

    index_folder(state, { body: data, params }) {
      state.custom_folder = data;
    },

    create_folder(state, { body: data, params }) {
      state.folders.push(data);
    },

    update_folder(state, { body: data }) {
      const index = state.folders.findIndex((f) => f.directory_id === data.directory_id);
      if (index > -1) {
        state.folders.splice(index, 1, data);
      }
    },

    destroy_folder_file(state, { body: data }) {
      const index = state.custom_folder.files.findIndex((f) => f.file_id === data.table.id);
      if (index > -1) {
        state.custom_folder.files.splice(index, 1);
      }
    },

    releases_live_update(state, { body: data, params }) {
      let tags = [];
      data.forEach((r) => {
        let t = {};
        JSON.parse(r.notes)
          .filter((i) => !!i.id)
          .forEach((n) => {
            t[n.id] = n;
          });
        r.notes = Object.values(t).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        t = {};
        JSON.parse(r.tags).filter((i) => {
          if (!!i) {
            t[i] = tags[i] = i;
            return true;
          }
          return false;
        });
        r.tags = Object.keys(t);
        r.hash = `${r.name} ${r.address} ${r.phone} ${r.email} ${r.signed_at} ${r.notes
          .map((n) => n.content)
          .join(" ")}`.toUpperCase();
        r.month = new Date(r.signed_at).getMonth();

        // check in release
        let index = state[params.name].findIndex((re) => re.id === r.id && re.release_type === r.release_type);
        if (index > -1) {
          state[params.name].splice(index, 1, r);
        } else {
          state[params.name].unshift(r);
        }

        // smart tags
        index = state.smart_tags.findIndex((re) => re.id === r.id && re.release_type === r.release_type);
        if (index > -1) {
          state.smart_tags.splice(index, 1, r);
        } else {
          state.smart_tags.unshift(r);
        }

        // email templates
        index = state.email_documents.findIndex((re) => re.id === r.id && re.release_type === r.release_type);
        if (index > -1) {
          state.email_documents.splice(index, 1, r);
        } else {
          state.email_documents.unshift(r);
        }
      });
    },

    load_media(state, { body: data, params }) {
      if (data.releasable_name === "tag") {
        state.smart_tags = state.smart_tags.map((r) => ({
          ...r,
          ...(data.releases.find((i) => i.id === r.id && i.release_table_name === r.release_table_name) || {}),
        }));
      } else if (!params.for) {
        state[`${data.releasable_name}_releases`] = state[`${data.releasable_name}_releases`].map((r) => ({
          ...r,
          ...(data.releases.find((i) => i.id === r.id) || {}),
        }));
      } else {
        state.email_documents = state.email_documents.map((r) => ({
          ...r,
          ...(data.releases.find((i) => i.id === r.id) || {}),
        }));
      }
    },

    create_import_release(state, { body: data, params }) {
      data = data.map((r) => {
        let t = {};
        JSON.parse(r.notes)
          .filter((i) => !!i.id)
          .forEach((n) => {
            t[n.id] = n;
          });
        r.notes = Object.values(t).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        t = {};
        JSON.parse(r.tags).filter((i) => {
          if (!!i) {
            t[i] = i;
            return true;
          }
          return false;
        });
        r.tags = Object.keys(t);
        r.hash = `${r.name} ${r.address} ${r.phone} ${r.email} ${r.signed_at} ${r.notes
          .map((n) => n.content)
          .join(" ")}`.toUpperCase();
        r.month = new Date(r.signed_at).getMonth();
        return r;
      });
      const typeOfRelease = params.release_type.concat("_releases");
      state[typeOfRelease].unshift(data[0]);
      state.selected_import_release = null;
    },
    create_re_send_invitation(state, { body: data, params }) {
      state.email_documents.unshift({ ...data, tags: [], notes: [] });
    },

    update_import_release(state, { body: data, params }) {
      data = data.map((r) => {
        let t = {};
        JSON.parse(r.notes)
          .filter((i) => !!i.id)
          .forEach((n) => {
            t[n.id] = n;
          });
        r.notes = Object.values(t).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        t = {};
        JSON.parse(r.tags).filter((i) => {
          if (!!i) {
            t[i] = i;
            return true;
          }
          return false;
        });
        r.tags = Object.keys(t);
        // r.tags = [...(r.tags || []), ...Object.keys(t)];
        r.hash = `${r.name} ${r.address} ${r.phone} ${r.email} ${r.signed_at} ${r.notes
          .map((n) => n.content)
          .join(" ")}`.toUpperCase();
        r.month = new Date(r.signed_at).getMonth();
        return r;
      });
      const typeOfRelease = params.release_type.concat("_releases");
      const index = state[typeOfRelease].findIndex((a) => a.id === data[0].id);
      if (index > -1) {
        state[typeOfRelease].splice(index, 1, data[0]);
      }
      state.selected_import_release = null;
    },

    modalData(state, data) {
      state.modal = (typeof data === "string" ? { action: data } : data) || null;
      if (!data) {
        if (state.selected_import_release) {
          state.selected_import_release = null;
        }
      }
    },
    enlarge_media(state, { body: data, params }) {
      state.assets = data;
    },
    reset_assets(state, data) {
      state.assets = data;
    },
    set_active_tab(state, tab) {
      state.active_tab = tab;
    },
    set_last_visited_page(state, { route_name = null, selected_option = null, active_tab = null }) {
      state.last_visited_page = { route_name, selected_option, active_tab };
    },
    set_current_page(state, pg) {
      state.currPg = pg;
    },
  },
  actions: {
    // close - this.manageModal();
    // add - this.manageModal("add")
    // edit - this.manageModal("edit")
    // OR edit - this.manageModal({action: "edit", payload: "dad23dg34"})
    manageModal({ commit }, data) {
      commit("modalData", data);
      $("#document-modal").modal(data ? "show" : "hide");
      // if (!data) {
      //     commit("edit");
      // }
    },
  },

  getters: {
    is_connected: (s) => s.is_connected,
    current_page: (s) => s.currPg,

    folders: (s) => s.folders,
    import_release_url: (s) => s.import_release_url,
    tags: (s) => s.tags,
    release_tags: (s) => s.release_tags,
    permissions: (s) => s.permissions,

    defaults: (s) => [
      {
        name: "Signed Release",
        permissions: s.permissions.signed_release,
        type: Object.keys(s.permissions)[0],
      },
      {
        name: "Downloads",
        permissions: s.permissions.download,
        type: Object.keys(s.permissions)[2],
      },
      {
        name: "Reports",
        permissions: s.permissions.video,
        type: Object.keys(s.permissions)[1],
      },
      {
        name: "Call Sheets",
        permissions: true,
        type: "call_sheets",
      },
      {
        name: "Emailed Documents",
        permissions: true,
        type: "emailed_documents",
      },
      {
        name: "Business Documents",
        permissions: s.permissions.signed_release,
        type: "business_documents",
      },
    ],

    appearance_releases: (s) => s.appearance_releases,
    location_releases: (s) => s.location_releases,
    acquired_media_releases: (s) => s.acquired_media_releases,
    material_releases: (s) => s.material_releases,
    medical_releases: (s) => s.medical_releases,
    misc_releases: (s) => s.misc_releases,
    music_releases: (s) => s.music_releases,
    talent_releases: (s) => s.talent_releases,
    email_documents: (s) => s.email_documents,
    business_releases: (s) => s.business_releases,
    isLoading: (s) => s.isLoading,

    smart_tags: (s) => s.smart_tags, // when user go in tag from main view, it will contain index_by_tag
    downloads: (s) => s.downloads,
    reports: (s) => s.reports,

    custom_folder: (s) => s.custom_folder,
    images: (s) => s.images,

    modal: (s) => s.modal,
    file_permissions: (s) => s.file_permissions,

    selected_import_release: (s) => s.selected_import_release,
    active_tab: (s) => s.active_tab,
    last_visited_page: (s) => s.last_visited_page,

    lock_download: (_, getters) => {
      const currentUser = getters.current_user;
      const isAdmin = currentUser.is_admin;
      const hasPaymentMethod = getters.selected_project.payment_methods !== null;
      const isFreeTrial = getters.selected_project.free_plan;
      const isBillable = getters.selected_project.is_billable;

      if (isAdmin || isFreeTrial || hasPaymentMethod || !isBillable) {
        return false; // Allow download
      } else {
        return true; // Lock download
      }
    },
    assets: (s) => s.assets,
    tag_by_id: (s, g) => (_id) => {
      console.log("Tag by id : ", _id);
      return g.tags.find((e) => e.id === _id);
    },
  },
};

export const mapDocumentManagerActions = (data) => mapActions(DOCUMENT_MANAGER_MODULE_NAME, data);
export const mapDocumentManagerGetters = (data) => mapGetters(DOCUMENT_MANAGER_MODULE_NAME, data);

export const mapDocumentManagerMutations = (data) => mapMutations(DOCUMENT_MANAGER_MODULE_NAME, data);

export default DocumentManagerModule;
