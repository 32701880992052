import {mapActions, mapGetters, mapMutations} from "vuex";

export const CONTACT_MODAL_ACTIONS = {
    ADD_TEAM: "add_team",
    EDIT_TEAM: "edit_team",

    ADD_VENDOR: "add_vendor",
    EDIT_VENDOR: "edit_vendor",

    ADD_GROUP: "add_group",
    EDIT_GROUP: "edit_group",

    ADD_OTHER: "add_other",
    EDIT_OTHER: "edit_other",
};

export const CONTACTMANAGER_MODULE_NAME = "contactmanager";

const initState = {
    is_connected: false,
    teams: [],
    users: [],
    vendors: [],
    groups: [],
    others: [],
    modal: null,
    selected_group: {},
    selected_other: {},
};

const ContactManagerModule = {
    namespaced: true,
    state: () => initState,
    mutations: {
        // TEAM //

        // TEAM LIST
        index_team(state, {body: data, params}) {
            state.teams = data.members.map((c) => {
                c.hash = `${c.first_name} ${c.last_name} ${c.phone_number} ${c.email} ${c.title}`.toUpperCase();
                return c;
            });
            state.users = data.users;
        },
        // Create Team
        create_team(state, {body: payload, params}) {
            state.teams.push(payload);
        },
        // DELETE TEAM
        destroy_team(state, {body, params}) {
            // {body: broadcast, params} way is not working for delete
            let index = state.teams.findIndex((m) => m.project_membership_id === params.project_membership_id);
            if (index > -1) {
                state.teams.splice(index, 1);
            }
        },
        update_team_role(state, {body: m}) {
            const index = state.teams.findIndex(t => t.id === m.id);
            if (index > -1) {
                state.teams.splice(index, 1, m);
            }
        },
        update_team_title(state, {body: m}) {
            const index = state.teams.findIndex(t => t.id === m.id);
            if (index > -1) {
                state.teams.splice(index, 1, m);
            }
        },

        // VENDOR //

        // Create Vendor
        create_vendor(state, {body: payload, params}) {
            state.vendors.push(payload)
        },

        // VENDOR LIST
        index_vendor(state, {body: data, params}) {
            state.vendors = data.map((c) => {
                c.hash =
                    `${c.company_address} ${c.company_name} ${c.company_phone} ${c.company_website} ${c.description}`.toUpperCase();
                return c;
            });
        },

        // VENDOR UPDATE
        update_vendor(state, {body: data}) {
            const index = state.vendors.findIndex((v) => v.id === data.id);
            if (index > -1) {
                state.vendors.splice(index, 1, data);
            }
        },

        // DELETE VENDOR
        destroy_vendor(state, {body: data, params}) {
            // {body: broadcast, params} way is not working for delete
            let index = state.vendors.findIndex((m) => m.id === params.contact_vendor_id);
            if (index > -1) {
                state.vendors.splice(index, 1);
            }
        },

        // Others
        index_other(state, {body: data, params}) {
            state.others = data;
        },
        create_other(state, {body: payload, params}) {
            state.others.push(payload);
        },
        update_other(state, {body: data, params}) {
            const index = state.others.findIndex((v) => v.id === data.id);
            if (index > -1) {
                state.others.splice(index, 1, data);
            }
        },
        destroy_other(state, {body, params}) {
            let index = state.others.findIndex((m) => m.id === params.contact_other_id);
            if (index > -1) {
                state.others.splice(index, 1);
            }
        },

        // Group
        index_group(state, {body: data, params}) {
            console.log("data", data);
            state.groups = data;
        },
        create_group(state, {body: data, params}) {
            state.groups.push(data);
        },
        update_group(state, {body: data, params}) {
            const index = state.groups.findIndex((v) => v.id === data.id);
            if (index > -1) {
                state.groups.splice(index, 1, data);
            }
        },
        destroy_group(state, {body, params}) {
            let index = state.groups.findIndex((m) => m.id === params.group_id);
            if (index > -1) {
                state.groups.splice(index, 1);
            }
        },

        // COMMON
        RESET(state) {
            state.is_connected = false;
            state.teams = [];
            state.users = [];
            state.vendors = [];
            state.groups = [];
            state.others = [];
            state.modal = null;
            state.selected_group = {};
            state.selected_other = {};
        },
        connected(state, flag) {
            state.is_connected = flag;
        },

        modalData(state, data) {
            state.modal = (typeof data === "string" ? {action: data} : data) || null;
        },
    },
    actions: {
        // close - this.manageModal();
        // add - this.manageModal("add")
        // edit - this.manageModal("edit")
        // OR edit - this.manageModal({action: "edit", payload: "dad23dg34"})
        manageModal({commit}, data) {
            commit("modalData", data);
            $("#contact-modal").modal(data ? "show" : "hide");
            // if (!data) {
            //     commit("edit");
            // }
        },
    },
    getters: {
        teams: (s) => {

            let project_admins = [],
                project_managers = [],
                associates = [];

            const contacts = s.teams.map(c => {
                c.role_code = 0;
                switch (c.role) {
                    case 'project_admin':
                        c.role_code = 2;
                        break;
                    case 'project_manager':
                        c.role_code = 1;
                        break;
                    case 'associate':
                        c.role_code = 0;
                        break;
                }
                return c;
            });

            // Implementing sorting by first_name and role both...
            // the order of sorting by role should be like ;
            //    Project Admin ,
            //    Project manager then,
            //    Associate
            const compare = (a, b) => {
                if (a.full_name < b.full_name) {
                    return -1;
                }
                if (a.full_name > b.full_name) {
                    return 1;
                }
                return 0;
            };

            project_admins = contacts
                .filter((c) => (c.role || "").toLowerCase() === "project_admin")
                .sort(compare);

            project_managers = contacts
                .filter((c) => (c.role || "").toLowerCase() === "project_manager")
                .sort(compare);

            associates = contacts
                .filter((c) => (c.role || "").toLowerCase() === "associate")
                .sort(compare);

            return [...project_admins, ...project_managers, ...associates];
        },
        users: (s) => s.users,
        vendors: (s) => s.vendors,
        groups: (s) => s.groups,
        others: (s) => s.others,

        is_connected: (s) => s.is_connected,
        modal: (s) => s.modal,
        selected_group: (s) => s.selected_group,
        selected_other: (s) => s.selected_other,
    },
};

export const mapContactManagerActions = (data) =>
    mapActions(CONTACTMANAGER_MODULE_NAME, data);

export const mapContactManagerGetters = (data) =>
    mapGetters(CONTACTMANAGER_MODULE_NAME, data);

export const mapContactManagerMutations = (data) =>
    mapMutations(CONTACTMANAGER_MODULE_NAME, data);

export default ContactManagerModule;
