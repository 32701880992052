import {mapActions, mapGetters, mapMutations} from "vuex";

export const VIDEOTRANSCRIPT_MODULE_NAME = "video-transcript";

export const VIDEOTRANSCRIPT_MODAL_ACTIONS = {
    VIEW_VIDEOTRANSCRIPT: "view",
};

const initState = {
    is_connected: false,
    modal: null,
    videos: [],
    transcriptions: null,
};

const VideoTranscriptModule = {
    namespaced: true,
    state: () => initState,
    mutations: {
        // COMMON
        connected(state, flag) {
            state.is_connected = flag;
        },
        index(state, {body: data, params}) {
            state.videos = data;
        },
        show(state, {body: data, params}) {
            state.transcriptions = data;
        },

        modalData(state, data) {
            state.modal =
                (typeof data === "string" ? {action: data} : data) || null;
        },
    },
    actions: {
        manageModal({commit}, data) {
            commit("modalData", data);
            $("#video-transcript-modal").modal(data ? "show" : "hide");
        },
    },
    getters: {
        is_connected: (s) => s.is_connected,
        modal: (s) => s.modal,

        videos: (s) => s.videos,

        transcriptions: (s) => s.transcriptions,
    },
};

export const mapVideoTranscriptActions = (data) =>
    mapActions(VIDEOTRANSCRIPT_MODULE_NAME, data);

export const mapVideoTranscriptGetters = (data) =>
    mapGetters(VIDEOTRANSCRIPT_MODULE_NAME, data);

export const mapVideoTranscriptMutations = (data) =>
    mapMutations(VIDEOTRANSCRIPT_MODULE_NAME, data);

export default VideoTranscriptModule;
