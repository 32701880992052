import Logger from "./logger";

const VueLogger = {
    /**
     * VueLogger Entry point
     * @param Vue
     * @param options
     * @param {boolean} options.enabled - Enable logging for Vue and Vuex
     * @param {string} [options.log_level="all"] - Log level required for logging. Either `info`, `error`, or `all`
     * @param {object} options.store - to enable logger in store.
     */
    install(Vue, options = {}) {
        options.log_level = options.log_level || "all"
        const VERSION = Number(Vue.version.split(".")[0]);

        const logger = new Logger(options.enabled, options.log_level);

        if (VERSION === 3) {
            Vue.config.globalProperties.$logger = logger;
        } else {
            Vue.prototype.$logger = logger;
        }

        if (options.store) {
            options.store.$logger = logger
        }
    }
};

export default VueLogger;