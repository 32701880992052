import CAM_1 from '../Icons/camera-pos/cam_1.png';
import CAM_2 from '../Icons/camera-pos/cam_2.png';
import CAM_3 from '../Icons/camera-pos/cam_3.png';
import CAM_4 from '../Icons/camera-pos/cam_4.png';
import CAM_5 from '../Icons/camera-pos/cam_5.png';
import CAM_6 from '../Icons/camera-pos/cam_6.png';
import CAM_8 from '../Icons/camera-pos/cam_8.png';
import CAM_9 from '../Icons/camera-pos/cam_9.png';
import CAM_12 from '../Icons/camera-pos/cam_12.png';
import CAM_15 from '../Icons/camera-pos/cam_15.png';

export const KEYS = {
    CONF: 'conf',
    TAKES: 'takes',
    CONTROLS: 'controls',
    USERS: 'users',
    // CAM_CONFIG: 'cam-config',
    CAMERA: 'camera'
};

export const MODES = {
    LIVE: 'LIVE', HD: 'HD', DVR: 'DVR', TAKES: 'TAKES'
};

export const DirectMeTabs = [
    // {title: 'Conference', key: KEYS.CONF},
    {title: 'Clips', key: KEYS.TAKES},
    {title: "Controls", key: KEYS.CONTROLS}];

export const DirectMeModals = {
    JOIN_MEETING: 'DM_JOIN_MEETING',
    UPDATE_JOIN_MEETING_DATA: 'DM_UPDATE_JOIN_MEETING_DATA',
    CHANGE_CAMERA: 'DM_CHANGE_CAMERA',
    LEFT_MEETING: 'DM_LEFT_MEETING',
    MEETING_ERROR: 'DM_MEETING_ERROR',
    CHANGE_MIC: 'DM_CHANGE_MIC',
    CHANGE_SPEAKER: 'DM_CHANGE_SPEAKER',
    RESET_URL: 'DM_RESET_URL',
    REPORT_BUG: 'DM_REPORT_BUG',
    EDIT_TAKE: 'DM_EDIT_TAKE',
    REQUEST_TRANSCRIPTION_TAKE: 'DM_REQUEST_TRANSCRIPTION_TAKE',
    DOWNLOAD_TRANSCRIPTION_TAKE: 'DM_DOWNLOAD_TRANSCRIPTION_TAKE',
    CONFIRM_BROADCASTING: 'DM_CONFIRM_BROADCASTING',
    CAPTURE_CROP_SCREENGRAB: 'DM_CAPTURE_CROP_SCREENGRAB'
};
//
// export const DirectMeControlsTabs = [
//     {title: 'USERS', key: KEYS.USERS},
//     {title: 'CAM CONFIG', key: KEYS.CAM_CONFIG},
// ];
export const DirectMeControlsTabs = [
    {title: 'Users', key: KEYS.USERS},
    // {title: "Camera", key: KEYS.CAMERA}
];


export const DirectMeElements = {
    modal: () => $('#direct-me-app-modal'), popovers: () => $('[data-toggle="popover"]')
};

export const BroadcastStatus = {
    CREATED: 'created', ACTIVE: 'active', IDLE: 'idle'
};

export const BroadcastStreamStatus = {
    IDLE: 'idle', CONNECTED: 'connected', RECORDING: 'recording', DISCONNECTED: 'disconnected'
};

export const WSActions = {
    DO_SLACK: 'do_slack',

    DO_STAR_TAKE: 'do_update_star_of_take',
    DO_EDIT_TAKE: 'do_edit_take',
    DO_REQUEST_TRANSCRIPTION_TAKE: 'do_request_transcription_take',
    DO_REGENERATE_TRANSCRIPTION_TAKE: 'do_regenerate_transcription_take',
    DO_REQUEST_DOWNLOAD_TRANSCRIPTION_TAKE: 'do_request_download_transcription_take',
    DO_REFRESH_BROADCAST_TOKEN: 'refresh_broadcast_token',
    DO_PUBLISH_BROADCASTER_JOINED: 'publish_broadcaster_joined',
    DO_SEND_CURRENT_TIME_LOG: 'do_send_current_time_log',
    DO_SAVE_FRAMERATE_TIMECODE: 'do_save_framerate_timecode',
    DO_SEND_CASTS: 'go_send_casts',
    DO_SAVE_SEND_LIVE_LOG: 'do_save_send_live_log',
    DO_UPDATE_SEND_TRANSCRIPTION: 'do_update_send_transcription',
    DO_UPDATE_STAR_TRANSCRIPTION: 'do_update_star_transcription',
    DO_UPDATE_CAMERA_DETAILS: 'do_update_camera_details',

    DO_DELETE_LOG: 'do_delete_log',
    DO_UPDATE_LOG: 'do_update_log',

    DO_SEND_TAKE_INFO: 'do_send_take_info',

    DO_LIVE_CAMERA_CONFIG: 'do_live_camera_config',

    DO_INSERT_TRANSCRIPTION: 'do_insert_transcription'
};

export const WSChannel = {
    NEW_BROADCAST: 'NewBroadcastsChannel'
};

export const Version = {
    LOCAL_USER: 202202200000, // FORMAT IS YYYYMMDDHHMM
    FIREBASE: 20210827 // FORMAT IS YYYYMMDD
};

export const Live100msUserRole = {
    HOST: 'host',
    BROADCASTER: 'broadcaster',
    SPECTATOR: 'spectator',
    INVISIBLE: 'invisible'
};

export const LIVE_TAKE_INFO_BOX_TABS = {
     LOGS: 'Logs', CAMERA: 'Camera', CLOSE: 'Close'
};

export const LiveTakeInfoBoxTabs = [
    {key: LIVE_TAKE_INFO_BOX_TABS.LOGS, icon: 'log'},
    {key: LIVE_TAKE_INFO_BOX_TABS.CAMERA, icon: 'camera'},

];

export const TAKE_INFO_BOX_TABS = {
    INFO: 'Info', LOGS: 'Logs', TRANSCRIPTIONS: 'Transcript', DOWNLOAD: 'Download', CLOSE: 'Close', CAMERA: 'Camera'
};

export const TakeInfoBoxTabs = [
    {key: TAKE_INFO_BOX_TABS.LOGS, icon: 'log'},
    {key: TAKE_INFO_BOX_TABS.TRANSCRIPTIONS, icon: 'transcript'},
    {key: TAKE_INFO_BOX_TABS.INFO, icon: 'meinfo'},
    {key: TAKE_INFO_BOX_TABS.CAMERA, icon: 'camera'},
    {key: TAKE_INFO_BOX_TABS.DOWNLOAD, icon: 'cloud-download'},
];

export const ROOT_EVENTS = {
    OPEN_LOG_WINDOW: 1
};

export const CAMERA_POS = {
    CAM_1, CAM_2, CAM_3, CAM_4, CAM_5, CAM_6, CAM_8, CAM_9, CAM_12, CAM_15
};

// retCoords = json: the payload returned from screen_locations API
// frameWidth = int: the width of the cropped image
// frameHeight = int: the height of the cropped image
// refFrameWidth = int: the width of the full image
// refFrameHeight = int: the height of the full image
// refFrameCoords = dict: the top left corner of the cropped image. format: {'x1': int, 'y1': int}
export const adjustCoordScew = (retCoords, cropConfig) => {
    if (!cropConfig || !retCoords) {
        return "";
    }
    if (typeof cropConfig === 'string') {
        cropConfig = JSON.parse(`${cropConfig.replaceAll("=>", ":")}`)
    }
    let frameWidth = cropConfig.crop.width, frameHeight = cropConfig.crop.height,
        refFrameWidth = cropConfig.original.width, refFrameHeight = cropConfig.original.height,
        refFrameCoords = {x1: cropConfig.crop.axis.x1, y1: cropConfig.crop.axis.y1};

    let cropImgX = refFrameCoords["x1"];
    let cropImgY = refFrameCoords["y1"];

    if (typeof retCoords === 'string') {
        retCoords = `${retCoords.replaceAll("=>", ":")}`
    }

    console.log(retCoords);

    let bodyDict = JSON.parse(retCoords);

    switch (Object.values(bodyDict).length) {
        case 2:
        // case 3:
        case 4:
            return retCoords;
    }

    for (const [key, value] of Object.entries(bodyDict)) {
        for (const [k, v] of Object.entries(value)) {
            if (k.startsWith('x')) {
                bodyDict[key][k] = ((v * frameWidth) + cropImgX) / refFrameWidth;
            } else {
                bodyDict[key][k] = ((v * frameHeight) + cropImgY) / refFrameHeight;
            }
        }
    }

    console.log(bodyDict)

    return JSON.stringify(bodyDict);
};

export const railsToJSObj = str => JSON.parse(`${str.replaceAll("=>", ":")}`);

export const getCoOrdOfCamerasLayout = (totalCams, configCrop, oImgWidth, oImgHeight, calc = false) => {
    const c = configCrop, height = oImgHeight, width = oImgWidth;
    switch (totalCams) {
        case 2:
            if (c && !calc) {
                return {
                    "screen_1": {
                        "x1": c.axis.x1 / width,
                        "y1": c.axis.y1 / height,
                        "x2": ((c.width / 2) + c.axis.x1) / width,
                        "y2": c.axis.y2 / height
                    },
                    "screen_2": {
                        "x1": ((c.width / 2) + c.axis.x1) / width,
                        "y1": c.axis.y1 / height,
                        "x2": c.axis.x2 / width,
                        "y2": c.axis.y2 / height
                    },
                };
            }
            return {
                "screen_1": {"x1": 0.0, "y1": 0.0, "x2": 0.5, "y2": 1},
                "screen_2": {"x1": 0.5, "y1": 0.0, "x2": 1, "y2": 1}
            };
        case 3:
            return {
                "screen_1": {"x1": 0.00, "y1": 0.19, "x2": 0.62, "y2": 0.81},
                "screen_2": {"x1": 0.5, "y1": 0.00, "x2": 1.00, "y2": 0.50},
                "screen_3": {"x1": 0.5, "y1": 0.50, "x2": 1.00, "y2": 1.00}
            };
        case 4:
            return calculateCoOrdOfCamerasLayout([2, 2], {w: width, h: height});
        case 6:
            return calculateCoOrdOfCamerasLayout([3, 3], {w: width, h: height});
        case 7:
            return calculateCoOrdOfCamerasLayout([3, 4], {w: width, h: height});
        case 8:
            return calculateCoOrdOfCamerasLayout([4, 4], {w: width, h: height});
        case 12:
            return calculateCoOrdOfCamerasLayout([4, 4, 4], {w: width, h: height});
        default:
            return null;
    }


};

const calculateCoOrdOfCamerasLayout = (layout = [], dim = {w: 1920, h: 1080}, aspectRatio = "16:9") => {
    const [wr, hr] = aspectRatio.split(':');
    const noOfLayers = layout.length;
    const {w, h} = dim;
    let res = {};
    let counter = 0;

    let layerCount = 0;
    for (let layer of layout) {
        for (let i = 0; i < layer; i++) {
            counter++;
            // possible height and width
            const pw = w / layer, ph = h / noOfLayers;
            // viewable height and width
            const vh = pw * hr / wr;
            // black area size top + bottom
            const bh = ph - vh;
            res[`screen_${counter}`] = {
                x1: (pw * (i % layer)) / w,
                y1: ((ph * layerCount) + (bh / 2)) / h,
                x2: (i + 1 % layer === 0 ? 1 : pw * (i + 1 % layer)) / w,
                y2: ((ph * layerCount) + (bh / 2) + vh) / h,
            };
        }
        layerCount++;
    }
    return res;
};