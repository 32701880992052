import {RouteConfig} from "vue-router";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "paymes" <<
 * where ever we mention this, it will group all components in one group.
 */
const PayMERoutes = [
    {
        path: "payme",
        component: () => import(/* webpackChunkName: 'paymes' */ "./PayME.vue"),
        children: [
            {
                path: "",
                name: "paymes.index",
                component: () =>
                    import(/* webpackChunkName: "paymes" */ "./pages/PayMEIndex.vue"),
                meta: { topbar: true, project_title: true }
            },
        ],
    },
];

export default PayMERoutes;
