import { MEDIAME_SORT_OPTIONS, MEDIAME_MODAL_ACTIONS } from "./store/mediame.vuex";

const sourceMap = {
  media_me: "MediaME",
  direct_me: "DirectME",
  deliver_me: "DeliverME",
};

const borderColorMap = {
  media_me: "var(--orange)",
  direct_me: "var(--alien-green)",
  deliver_me: "var(--light-royal-blue)",
};

const classMap = {
  media_me: "bg-orange",
  deliver_me: "bg-light-royal-blue",
  direct_me: "bg-alien-green",
};

const TOOLBAR_COMPONENTS = (_this) => {
  return [
    {
      type: "HeaderDropdown",
      bindings: {
        id: "type_switcher",
        title: "Type",
        options: {
          all: { title: "All" },
          video: { title: "Video" },
          audio: { title: "Audio", disabled: true },
          images: { title: "Images", disabled: true },
        },
        defaultOption: "video",
      },
      events: {
        optionChanged: _this.typeSwitched,
      },
    },
    {
      type: "Spacer",
      classes: ["hide-on-mobile"],
    },
    {
      type: "HeaderDropdown",
      class: ["grow-on-mobile"],
      bindings: {
        id: "sort_options",
        title: "Order",
        options: MEDIAME_SORT_OPTIONS,
        defaultOption: "latest",
      },
      events: {
        optionChanged: _this.sortChanged,
      },
    },
    {
      type: "FilterDropdown",
      bindings: {
        id: "filter_options",
      },
      class: ["grow-on-mobile"],
      events: {
        resetPagination: _this.resetPagination,
        filterChanged: _this.filterChanged,
      },
    },
    {
      type: "div_group",
      class: ["search-with-upload"],
      components: [
        {
          type: "SearchInput",
          bindings: {
            id: "search",
            value: _this.query,
          },
          events: {
            input: _this.updateQuery,
          },
        },
        {
          type: "UploadButton",
          bindings: {
            id: "upload_media_btn",
            class: ["hide-on-desktop"],
            title: "Upload Media!!",
          },
          events: {
            uploadMedia: _this.uploadMedia,
          },
        },
      ],
    },
    {
      type: "ViewTypeSwitcher",
      bindings: {
        id: "view_switcher",
        currentView: _this.currentView,
      },
      events: { toggleView: _this.toggleView },
    },
  ];
};

const GET_STARTED_STEPS = { STEP_1: 1, STEP_1_1: 1.1, STEP_2: 2, STEP_3: 3, STEP_3_1: 3.1, STEP_4: 4, STEP_5: 5 };
const GET_STARTED_ACTIONS = {
  VIEW_TRANSCRIBED_VIDEO: "view_transcribed_video",
  VIEW_UPLOADED_VIDEO: "view_uploaded_video",
  TRANSCRIBE: "transcribe",
  UPLOAD: "upload",
  CREATE_PROJECT: "create_project",
  EXPLORE_SAMPLE_PROJECT: "explore_sample_project",
  WATCH_VIDEO: "watch_video",
  TUTORIAL_VIDEO: "tutorial_video",
  PREFERENCES: "preferences",
  PREFERENCE_CATEGORIES: "preference_categories",
  SHOW_ONBOARDING_COMPLETED: "show_onboarding_completed",
};

const GET_STARTED_ACTIONS_LIST = (_this) => {
  return {
    [GET_STARTED_ACTIONS.VIEW_TRANSCRIBED_VIDEO]: {
      id: GET_STARTED_ACTIONS.VIEW_TRANSCRIBED_VIDEO,
      title: { default: "View transcribed video" },
      show_if: { key: "get_started_metadata.transcribe_id[0]", is_not: null },
      onClick: {
        clickAction: _this.openMedia__custom,
        options: {
          dynamic_params: {
            media_item: "get_started_metadata.transcribe_id[0]",
          },
          id: GET_STARTED_ACTIONS.VIEW_TRANSCRIBED_VIDEO,
        },
      },
    },
    [GET_STARTED_ACTIONS.VIEW_UPLOADED_VIDEO]: {
      id: GET_STARTED_ACTIONS.VIEW_UPLOADED_VIDEO,
      title: { default: "Finish Onboarding" },
      onClick: {
        clickAction: _this.openMedia__custom,
        options: {
          dynamic_params: {
            media_item: "get_started_metadata.upload_id[0]",
          },
          id: GET_STARTED_ACTIONS.VIEW_UPLOADED_VIDEO,
        },
      },
      classes: ["btn", "btn-primary", "border-0", "btn-icon", "text-white"],
    },
    [GET_STARTED_ACTIONS.TRANSCRIBE]: {
      id: GET_STARTED_ACTIONS.TRANSCRIBE,
      title: { default: "Transcribe a video in MediaME", performed: "Transcribe Another Video" },
      onClick: {
        clickAction: _this.manageModal,
        options: { action: MEDIAME_MODAL_ACTIONS.UPLOAD, payload: { transcribe: true, id: "transcribe" } },
      },
    },
    [GET_STARTED_ACTIONS.UPLOAD]: {
      id: GET_STARTED_ACTIONS.UPLOAD,
      title: { default: "Upload & Store a video", performed: "Upload Another Video" },
      onClick: {
        clickAction: _this.manageModal,
        options: {
          action: MEDIAME_MODAL_ACTIONS.UPLOAD,
          payload: { id: "upload" },
        },
      },
    },
    [GET_STARTED_ACTIONS.CREATE_PROJECT]: {
      id: GET_STARTED_ACTIONS.CREATE_PROJECT,
      title: { default: "Get Started" },
      // show_if: { key: "projects.length", is: 1 },
      onClick: { clickAction: _this.createProjectClicked },
    },
    [GET_STARTED_ACTIONS.EXPLORE_SAMPLE_PROJECT]: {
      id: GET_STARTED_ACTIONS.EXPLORE_SAMPLE_PROJECT,
      title: { default: "Explore Project" },
      onClick: { clickAction: _this.setOldUser },
    },
    [GET_STARTED_ACTIONS.WATCH_VIDEO]: {
      id: GET_STARTED_ACTIONS.WATCH_VIDEO,
      title: { default: "Watch Tutorial Video" },
      onClick: { clickAction: _this.goToStep, options: { step: GET_STARTED_STEPS.STEP_1_1 } },
    },
    [GET_STARTED_ACTIONS.TUTORIAL_VIDEO]: {
      id: GET_STARTED_ACTIONS.TUTORIAL_VIDEO,
      title: { default: "Watch Tutorial Video custom" },

      custom_tag: {
        type: "video",
        tag_options: {
          controls: true,
          controlslist: "nodownload noremoteplayback noplaybackrate",
          disablepictureinpicture: true,
          src: "https://easy-release-storage-production.s3.amazonaws.com/intro_to_mediame.mp4",
          poster: "https://easy-release-storage-production.s3.amazonaws.com/mediame_get_started.jpeg",
        },
      },
      onClick: null,
    },
    [GET_STARTED_ACTIONS.PREFERENCE_CATEGORIES]: {
      id: GET_STARTED_ACTIONS.PREFERENCE_CATEGORIES,
      title: { default: "Preference Categories" },
      custom_tag: {
        type: "component",
        component: "PreferencesCategories",
      },
    },
    [GET_STARTED_ACTIONS.PREFERENCES]: {
      id: GET_STARTED_ACTIONS.PREFERENCES,
      title: { default: "Checkboxes" },
      custom_tag: {
        type: "component",
        component: "MediaMEGetStartedPreferences",
      },
    },
    [GET_STARTED_ACTIONS.SHOW_ONBOARDING_COMPLETED]: {
      id: GET_STARTED_ACTIONS.SHOW_ONBOARDING_COMPLETED,
      title: { default: "Onboarding Completed" },
      onClick: {
        clickAction: _this.manageModal,
        options: {
          action: MEDIAME_MODAL_ACTIONS.NEW_USER_BETA_FEATURES,
          payload: { id: "upload" },
        },
      },
    },
  };
};

const GET_STARTED_PREFERENCE_CATEGORIES = {
  EDIT_VIDEO: { id: "edit_video", title: "Edit Video with AI" },
  OPTIMISE_VIDEO: { id: "optimise_video", title: "Optimize Video with AI" },
  COLLABORATE: { id: "collaborate", title: "Collaborate With Others" },
};

const GET_STARTED_PREFERENCES = {
  share_video: {
    title: "Share Video",
    description: "Share the video with others",
    category: GET_STARTED_PREFERENCE_CATEGORIES.COLLABORATE.id,
  },
  ai_video_title: {
    title: "AI Generated Video Titles",
    description: "Generate titles for the video using AI",
    category: GET_STARTED_PREFERENCE_CATEGORIES.OPTIMISE_VIDEO.id,
  },
  ai_video_description: {
    title: "AI Generated Video Descriptions",
    description: "Generate descriptions for the video using AI",
    category: GET_STARTED_PREFERENCE_CATEGORIES.OPTIMISE_VIDEO.id,
  },
  ai_hashtags: {
    title: "AI Generated Hashtags",
    description: "Generate hashtags for the video using AI",
    category: GET_STARTED_PREFERENCE_CATEGORIES.OPTIMISE_VIDEO.id,
  },
  trim_video: {
    title: "Trim Video",
    description: "Trim the video to remove unwanted parts",
    category: GET_STARTED_PREFERENCE_CATEGORIES.EDIT_VIDEO.id,
  },
  resize_and_crop: {
    title: "Resize and Crop",
    description: "Resize and crop the video to a specific size",
    category: GET_STARTED_PREFERENCE_CATEGORIES.EDIT_VIDEO.id,
  },
  auto_clip: {
    title: "AI Generated Clips",
    description: "Generate clips from the video using AI",
    category: GET_STARTED_PREFERENCE_CATEGORIES.EDIT_VIDEO.id,
  },
  add_captions: {
    title: "Add Captions",
    description: "Add captions to the video",
    category: GET_STARTED_PREFERENCE_CATEGORIES.EDIT_VIDEO.id,
  },
  transcribe: {
    title: "Transcribe File",
    description: "Transcribe the file to get a summary of the video",
    category: GET_STARTED_PREFERENCE_CATEGORIES.OPTIMISE_VIDEO.id,
  },
  translate: {
    title: "Translate",
    description: "Translate",
    category: GET_STARTED_PREFERENCE_CATEGORIES.OPTIMISE_VIDEO.id,
  },
};

const FONT_OPTIONS = [
  {
    value: "proportionalSans",
    label: "Proportional Sans-Serif",
    fonts: "Arial, Helvetica, sans-serif",
  },
  {
    value: "proportionalSerif",
    label: "Proportional Serif",
    fonts: "Times New Roman, Georgia, serif",
  },
  {
    value: "monospacedSerif",
    label: "Monospaced Serif",
    fonts: "Courier New, Courier, monospace",
  },
  {
    value: "monospacedSans",
    label: "Monospaced Sans-Serif",
    fonts: "Consolas, Monaco, monospace",
  },
  {
    value: "casual",
    label: "Casual",
    fonts: "Comic Sans MS, Chalkboard, cursive",
  },
  {
    value: "cursive",
    label: "Cursive",
    fonts: "Brush Script MT, Dancing Script, cursive",
  },
];

const TEXT_DECORATION_OPTIONS = [
  { value: "none", label: "None" },
  { value: "underline", label: "Underline" },
  { value: "overline", label: "Overline" },
  { value: "line-through", label: "Strikethrough" },
];

const SIZE_MAP = {
  Small: 0.875,
  Normal: 1,
  Large: 1.25,
  "Extra Large": 1.5,
};

const LINE_HEIGHT_MAP = {
  Tight: 1.0,
  Normal: 1.5,
  Loose: 2.0,
};

const TEXT_ALIGN_OPTIONS = [
  { value: "start", label: "Left" },
  { value: "center", label: "Center" },
  { value: "end", label: "Right" },
];

const DEFAULT_SETTINGS = {
  fontFamily: "proportionalSans",
  fontSize: 1,
  color: "#FFFFFF",
  textOpacity: 100,
  lineHeight: 1.25,
  textShadow: false,
  fontStyle: "normal",
  textAlign: "center",
};

export {
  sourceMap,
  borderColorMap,
  classMap,
  TOOLBAR_COMPONENTS,
  GET_STARTED_STEPS,
  GET_STARTED_ACTIONS,
  GET_STARTED_ACTIONS_LIST,
  GET_STARTED_PREFERENCES,
  GET_STARTED_PREFERENCE_CATEGORIES,
  FONT_OPTIONS,
  TEXT_DECORATION_OPTIONS,
  SIZE_MAP,
  LINE_HEIGHT_MAP,
  TEXT_ALIGN_OPTIONS,
  DEFAULT_SETTINGS,
};
