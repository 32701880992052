//directives
import Vue from "vue";
import SuiteAppUtils from "./utils.suite-app";
import { dragDrop } from "./drag-drop";

Vue.directive("dropdown-close", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // Check if the clicked element is outside the dropdown
      if (!(el === event.target || el.contains(event.target))) {
        // Call the method provided in the directive value
        // vnode.context[binding.expression]();
        $(el).dropdown("hide");
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
});

Vue.directive("autofocus", {
  inserted: async function (el) {
    await SuiteAppUtils.sleep(0.5);
    el.focus();
  },
});

Vue.directive("tooltip", {
  inserted(el) {
    $(el).tooltip();
  },
  componentUpdated(el) {
    $(el).tooltip("dispose").tooltip();
  },
  unbind(el) {
    $(el).tooltip("dispose");
  },
});

Vue.directive("autoresize", {
  inserted: function (el) {
    el.style.height = "auto";
    el.style.height = el.scrollHeight + "px";
    el.addEventListener("input", function () {
      el.style.height = "auto";
      el.style.height = el.scrollHeight + "px";
    });
  },
});

Vue.directive("drag-drop", dragDrop);
