import {RouteConfig} from "vue-router";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "taskmanager" <<
 * where ever we mention this, it will group all components in one group.
 */
const TaskManagerRoutes = [
    {
        path: 'tasks',
        component: () => import(/* webpackChunkName: 'taskmanager' */'./TaskManager.vue'),
        children: [
            {
                path: "",
                name: 'tasks.index',
                component: () => import(/* webpackChunkName: "taskmanager" */'./pages/TaskManagerIndex.vue'),
                meta: { topbar: true, project_title: true }
            },
        ]
    }
]

export default TaskManagerRoutes