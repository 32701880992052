import { WSChannels } from "../../const";
import ProjectModule, {
  mapProjectActions,
  mapProjectGetters,
  mapProjectMutations,
  PROJECT_MODAL_ACTIONS,
  PROJECT_MODULE_NAME,
} from "../store/project.vuex";
import { PROJECT_DASHBOARD_MODULE } from "../../ProjectDashboard/store/projectdashboard.vuex";
import { DOCUMENT_MANAGER_MODULE_NAME } from "../../Documents/store/document.vuex";
import { CONTACTMANAGER_MODULE_NAME } from "../../ContactManager/store/contactmanager.vuex";
import { TASKMANAGER_MODULE_NAME } from "../../TaskManager/store/taskmanager.vuex";
import { RELEASEME_MODULE_NAME } from "../../ReleaseME/store/releaseme.vuex";
import { DIRECTME_MODULE_NAME } from "../../DirectME/store/directme.vuex";
import { DELIVERME_MODULE_NAME } from "../../DeliverME/store/deliverme.vuex";
import { PAYME_MODULE_NAME } from "../../PayME/store/payme.vuex";
import store from "../../store";
import SuiteAppUtils from "../../utils.suite-app";
import { CALENDAR_MODULE_NAME } from "../../Calendar/store/calendar.vuex";
import { MEDIAME_MODULE_NAME } from "../../MediaME/store/mediame.vuex";
import { associate as feature_access_associate } from "../../../../assets/feature_access_associate.json";
import moment from "moment";
import CommonMixin from "../../common.mixin";

const NO_OF_LATEST_PROJECTS = 4;

export const hasProjectFeatureAccess = function (routeName, selected_project = null) {
  if (routeName === "contract.review") return false;
  const _selected_project = selected_project || this.selected_project;
  if (_selected_project && _selected_project.role === "associate") {
    if (feature_access_associate.dont.includes(routeName)) {
      return false;
    }
  }
  return true;
};

const BasicProjectMixin = {
  methods: {
    ...mapProjectMutations(Object.keys(ProjectModule.mutations)),
    ...mapProjectActions(Object.keys(ProjectModule.actions)),
    hasProjectFeatureAccess,
    goToBack() {
      this.$router.back();
    },
    subscribe() {
      this.$cable.subscribe(
        {
          channel: WSChannels.ProjectChannel,
          account_hashid: this.$route.params.account_hashid,
          workspace_hashid: this.$route.params.workspace_hashid,
        },
        PROJECT_MODULE_NAME
      );
    },
    unsubscribe() {
      this.$cable.unsubscribe(PROJECT_MODULE_NAME);
    },
    perform(action, data) {
      this.$logger.debug(PROJECT_MODULE_NAME, "perform", action, data);
      this.$cable.perform({
        channel: PROJECT_MODULE_NAME,
        action,
        data,
      });
    },
    async goToEdit(project) {
      this.manageModal({
        action: PROJECT_MODAL_ACTIONS.EDIT_PROJECT,
        payload: project,
      });
      // await this.$router.push({
      //     name: "projects.edit",
      //     params: {
      //         project_hashid: project.project_hashid,
      //         account_hashid: project.account_hashid,
      //         workspace_hashid:
      //             project.workspace && project.workspace.workspace_hashid,
      //     },
      // });
    },
    async goToProject(project, force = false, query = null) {
      if (project.archived && this.current_user.is_admin === false) {
        return;
      }
      let has = false;

      event?.target?.classList?.forEach((c) => {
        if (
          c.includes("dropdown") ||
          c.includes("color") ||
          c.includes("setting-icon") ||
          c.includes("payment-method")
        ) {
          has = true;
        }
      });
      if (has) {
        return;
      }

      const filesBeingUploaded = store.getters[`${MEDIAME_MODULE_NAME}/filesBeingUploaded`];
      if (force && filesBeingUploaded.length > 0) {
        await this.gotToProjectWhenUploading(project, force, query);
        return;
      }

      await this.goToProjectFinally(project, force, query);
    },
    async gotToProjectWhenUploading(project, force, query) {
      this.$awn.confirm("Some files are still being uploaded, are you sure you want to switch project?", () => {
        this.goToProjectFinally(project, force, query);
      });
    },
    async goToProjectFinally(project, force, query) {
      if (!(project.status === null || project.status === "accept")) {
        return;
      }
      [
        PROJECT_DASHBOARD_MODULE,
        DOCUMENT_MANAGER_MODULE_NAME,
        CONTACTMANAGER_MODULE_NAME,
        TASKMANAGER_MODULE_NAME,
        RELEASEME_MODULE_NAME,
        DIRECTME_MODULE_NAME,
        DELIVERME_MODULE_NAME,
        CALENDAR_MODULE_NAME,
        PAYME_MODULE_NAME,
        MEDIAME_MODULE_NAME,
      ].forEach((m) => {
        store.commit(`${m}/RESET`, null);
      });

      if (project.archived) {
        this.$awn.info("You are accessing archived project!");
      }
      if (!project.workspace) {
        this.$awn.alert("Project has not assigned to Workspace, please contact support.");
        return;
      }
      const options = {
        name: "dashboard.index",
        params: {
          account_hashid: project.account_hashid,
          workspace_hashid: project.workspace.workspace_hashid,
          project_hashid: project.project_hashid,
        },
      };
      if (query) options.query = query;

      await this.$router.push(options);
      if (force) {
        await SuiteAppUtils.sleep(0.25);
        window.location.reload();
      }
    },
    async goToCreateProjectFromAccount() {
      this.manageModal({
        action: PROJECT_MODAL_ACTIONS.ADD_PROJECT,
        payload: {
          account_hashid: this.current_account.account_hashid,
          workspace_hashid: this.current_workspace?.workspace_hashid,
        },
      });
    },
    async goToCreateProject() {
      this.manageModal({
        action: PROJECT_MODAL_ACTIONS.ADD_PROJECT,
        payload: {
          account_hashid: this.current_account.account_hashid,
          workspace_hashid: this.current_workspace?.workspace_hashid,
        },
      });
    },
    openWorkspaceModal(workspace) {
      if (workspace) {
        this.perform("show_workspace", {
          account_hashid: this.current_account.account_hashid,
          workspace_hashid: workspace.workspace_hashid,
        });
      } else {
        this.manageModal({
          action: PROJECT_MODAL_ACTIONS.CREATE_SUBSCRIPTION,
          payload: null,
        });
      }
    },

    openWorkspaceEditModal(workspace, action) {
      this.perform("show_workspace", {
        account_hashid: this.current_account.account_hashid,
        workspace_hashid: workspace.workspace_hashid,
        modal_action: action,
      });
    },

    openManagePaymentMethodsModal(workspace) {
      if (!workspace.subscription) {
        this.$awn.warning("Can't change Payment method. No active subscription found for this Workspace.", {
          durations: { info: 0 },
        });
        return;
      }
      this.manageModal({
        action: PROJECT_MODAL_ACTIONS.PAYMENT_METHOD,
        payload: workspace,
      });
    },

    openViewPaymentHistoryModal(workspace) {
      this.manageModal({
        action: PROJECT_MODAL_ACTIONS.PAYMENT_HISTORY,
        payload: workspace,
      });
    },

    openUnArchiveWorkspaceSelectionModal(project) {
      this.manageModal({
        action: PROJECT_MODAL_ACTIONS.UN_ARCHIVE_PROJECTS_WORKSPACE_SELECTION,
        payload: project,
      });
    },

    colorChange(project, color_code) {
      this.perform("update_color", {
        project_hashid: project.project_hashid,
        color_code: color_code,
      });
    },

    updateInvitation(project, status) {
      this.perform("update_invitation", {
        project_hashid: project.project_hashid,
        status: status,
      });
    },
    destroyInvitation(project) {
      this.perform("destroy_invitation", {
        project_hashid: project.project_hashid,
      });
    },

    archiveOrUnarchiveProject(project) {
      const archived = !project.archived;
      this.$awn.confirm(
        `Are you sure, You want to ${archived ? "Archive" : "Unarchive"} the project called "${project.name}" ?`,
        () => {
          this.perform("archive", {
            project_hashid: project.project_hashid,
            account_hashid: project.account_hashid,
            archived: archived,
          });
        },
        () => {},
        {
          labels: {
            confirm: `Confirm ${archived ? "Archive" : "Unarchive"} !`,
          },
        }
      );
    },
    deleteWorkspace(workspace) {
      this.$awn.confirm(
        `Are you sure, you want to delete the workspace called "${workspace.name}" ?`,
        () => {
          this.perform("delete_workspace", {
            workspace_hashid: workspace.workspace_hashid,
            account_hashid: workspace.account_hashid,
          });
        },
        () => {},
        {
          labels: {
            confirm: `Confirm Delete !`,
          },
        }
      );
    },
    openSubscriptionUpdateModal(workspace) {},
    trialPeriodEnded(workspace, key) {
      let subscription = workspace.subscription;
      let product = subscription.products.filter((p) => p.key === key);

      if (product.is_trial) {
        if (product.trial_active) {
          return true;
        }
      }
      return false;
    },
    ...CommonMixin.methods,
    ...SuiteAppUtils.useKeysFromObj(CommonMixin.methods, ["goToWorkspaceSettings"]),
  },
  computed: {
    ...mapProjectGetters(Object.keys(ProjectModule.getters)),
    isUserNew() {
      return this.$route.query?.new_user || false;
    },
    projectPinnedItemsKey() {
      return (hashid) => `project.${hashid}.pinned.items`;
    },
    selected_project_hashid() {
      return this.$route.params.project_hashid;
    },
    hasManagerAccess() {
      return (project) => {
        return (project.status === null || project.status === "accept") && project.role === "project_admin";
      };
    },
    productPricingData(product) {
      return [];
    },
    // use only required computed property from common mixin
    ...SuiteAppUtils.useKeysFromObj(CommonMixin.computed, [
      "workspaces",
      "current_account",
      "current_workspace",
      "projects_by_account",
      "projects_by_routes",
    ]),
    latest_projects() {
      return this.projects_by_routes.filter((p) => !p.archived).slice(0, NO_OF_LATEST_PROJECTS);
    },
    archive_projects_by_account() {
      return this.projects_by_account.filter((p) => p.archived);
    },
    archive_projects() {
      return this.projects_by_routes.filter((p) => p.archived);
    },
    isWorkspaceThresholdReached() {
      return (type) => {
        if (window.location.hostname.includes("localhost")) {
          return false;
        }
        if (this.current_workspace && this.current_workspace.calculate_usage && this.current_workspace.threshold) {
          // ONE HOUR cool down period
          if (moment().diff(moment(this.current_workspace.created_at)) <= 1000 * 60 * 60) {
            return false;
          }
          if (type) {
            return !!this.current_workspace.threshold[type];
          } else {
            return (
              this.current_workspace.threshold["sign_me"] >= 80 ||
              this.current_workspace.threshold["deliver_me"] >= 80 ||
              this.current_workspace.threshold["direct_me"] >= 80 ||
              this.current_workspace.threshold["media_me"] > 80
            );
          }
        }
        return false;
      };
    },
    maxUsageProduct(workspace, action = null) {
      if (workspace && workspace.threshold) {
        let product_usages = [
          {
            name: "SignME",
            usage: workspace.threshold["sign_me"],
          },
          {
            name: "DeliverME",
            usage: workspace.threshold["deliver_me"],
          },
          {
            name: "DirectME",
            usage: workspace.threshold["direct_me"],
          },
          {
            name: "MediaME",
            usage: workspace.threshold["media_me"],
          },
        ];

        // ONE HOUR cool down period
        // if (type) {
        //     return !!workspace.threshold[type];
        // } else {
        return product_usages.reduce((highest, current) => {
          return highest.usage >= current.usage ? highest : current;
        }, product_usages[0]);

        // return this.current_workspace.threshold['sign_me'] || this.current_workspace.threshold['deliver_me'] || this.current_workspace.threshold['direct_me'];
        // }
      }
    },
    showRestrictUI() {
      if (window.location.hostname.includes("localhost")) {
        return false;
      }

      if (!(this.current_workspace && this.current_user)) {
        return false;
      }

      // ONE HOUR cool down period
      if (moment().diff(moment(this.current_workspace.created_at)) <= 1000 * 60 * 60) {
        return false;
      }

      // for devs
      if (this.current_user?.is_admin) {
        return false;
      }

      if (this.current_workspace?.subscription !== null) {
        return false;
      }

      if (this.current_workspace?.pricing_plan !== null) {
        if (this.current_workspace?.pricing_plan?.same_as_website === false) {
          return false;
        }
      }
      return true;
    },

    is_all_projects_active() {
      return !this.$route?.params?.workspace_hashid;
    },
  },
};

export const ProjectMixin = {
  channels: {
    [PROJECT_MODULE_NAME]: {
      async connected() {
        this.connected(true);
        this.$logger.debug(PROJECT_MODULE_NAME, "connected");
      },
      disconnected() {
        this.connected(false);
        this.$logger.debug(PROJECT_MODULE_NAME, "disconnected");
      },
      rejected() {
        this.connected(false);
        this.$logger.debug(PROJECT_MODULE_NAME, "rejected");
      },
      async received({ event, body, params }) {
        this.$logger.debug(PROJECT_MODULE_NAME, "received", event, body, params);
        switch (event) {
          case "notification":
            this.$awn[body.type](body.message);
            break;
          case "validation_error":
            console.error(body);
            break;
          case "project_updated":
            return this.perform("index");
          default:
            if (this[event]) {
              this[event]({ body, params });
            }

            // This will call respected `action` or `mutation`
            switch (event) {
              case "create":
                const route_obj = {
                  name: "dashboard.index",
                  params: {
                    account_hashid: body.account_hashid,
                    workspace_hashid: body.workspace.workspace_hashid,
                    project_hashid: body.project_hashid,
                  },
                };
                if (this.is_new_user) {
                  const signup_product =
                    this.selected_project?.account?.signup_product || this.$route.query?.product || null;
                  const module_name = signup_product
                    ? signup_product === "mediame"
                      ? MEDIAME_MODULE_NAME
                      : RELEASEME_MODULE_NAME
                    : PROJECT_MODULE_NAME;
                  const goToStep = signup_product === "mediame" || signup_product === "signme" ? 3 : null;

                  const mutation =
                    signup_product === "signme"
                      ? `${module_name}/addOnboardingActionPerformed`
                      : `${module_name}/addGetStartedActionPerformed`;

                  this.$store.commit(mutation, "create_project");
                  console.log("Product", signup_product, goToStep);

                  if (goToStep) this.$store.commit(`${module_name}/goToStep`, { step: goToStep });

                  route_obj.query = { new_user: true };

                  if (signup_product === "mediame" || signup_product === "signme") {
                    this.perform("slack_notification", {
                      message: `User Name : ${body.account_name}\nProject Name : ${body.name}\nMessage : User has created a ${signup_product} project`,
                    });
                  }
                }
                await this.goToProject(body, false, { new_user: this.is_new_user });
                break;
              case "index_accounts":
                if (body.accounts.length > 0) {
                  let account_hashid = body.accounts[0].account_hashid;
                  if (typeof this.$route.params.account_hashid === "string") {
                    account_hashid = this.$route.params.account_hashid;
                  } else {
                    // if route has admin work then we can't navigate to accounts page
                    if (
                      ["signer", "admin", "profilesettings"].filter((r) => (this.$route.name || "").includes(r))
                        .length === 0
                    ) {
                      console.log("Navigate to Account Show");
                      await this.$router.replace({
                        name: "accounts.show",
                        params: { account_hashid: account_hashid },
                      });
                    }
                  }
                  this.perform("index_projects_by_account", {
                    account_hashid: account_hashid,
                  });
                  this.perform("index_workspaces_by_account", {
                    account_hashid: account_hashid,
                  });
                }
                break;
              case "show_notification":
                if (body) {
                  this.manageModal(PROJECT_MODAL_ACTIONS.NOTIFICATION);
                }
                break;

              case "index_workspaces_by_account":
                if (this.$route.name && this.$route.name.includes("documents")) {
                  if (this.showRestrictUI) {
                    await this.$router.replace({ name: "dashboard.index" });
                    this.manageModal(PROJECT_MODAL_ACTIONS.WORKSPACE_RESTRICTED_ACCESS);
                  }
                }
                break;
              case "index_projects_by_account":
                // const project = body[0];
                // const account_hashid = project.account_hashid;
                // const workspace_hashid = project.workspace.workspace_hashid;
                // await this.$router.replace({
                //     name: 'accounts.workspaces.show',
                //     params: {
                //         account_hashid: account_hashid,
                //         workspace_hashid: workspace_hashid
                //     }
                // });
                break;
              case "index_project":
                if (this.current_workspace) {
                  this.perform("show_workspace_usages", {
                    account_hashid: this.current_account.account_hashid,
                    workspace_hashid: this.current_workspace.workspace_hashid,
                  });
                }
                if (body.project.archived) {
                  this.$awn.alert(body.project.name + " project is no longer active!");
                  if (!body.is_admin) {
                    // await this.$router.replace({name: "home"});
                    this.goToBack();
                    return;
                  }
                }
                break;
              case "show_workspace":
                let params = body.params.table;
                if (params.modal_action) {
                  if (params.modal_action === "MOVE_PROJECT_WORKSPACE") {
                    this.manageModal({
                      action: PROJECT_MODAL_ACTIONS.MOVE_PROJECTS_WORKSPACES,
                      payload: body,
                    });
                  } else {
                    this.manageModal({
                      action: PROJECT_MODAL_ACTIONS.EDIT_WORKSPACE,
                      payload: body,
                    });
                  }
                } else {
                  this.manageModal({
                    action: PROJECT_MODAL_ACTIONS.CREATE_UPDATE_WORKSPACE,
                    payload: body,
                  });
                }

                break;
              case "create_workspace":
              case "update_workspace":
              case "create_subscription":
              case "update_subscription":
              case "verify_email":
                this.manageModal();
                break;

              case "delete_workspace":
                console.log(body);
                await this.$router.replace({
                  name: "accounts.show",
                  params: { account_hashid: body.account_hashid },
                });
                break;
              case "update":
                this.manageModal();
                break;
            }
            break;
        }
      },
    },
  },
  beforeMount() {
    this.subscribe();
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    create_clip({ body, params }) {
      console.log("Create Clip Action 2 In Project Mixin", body, params);
      this.$store.commit(`${MEDIAME_MODULE_NAME}/create`, { body, params });
    },
    ffprobe({ body: video_ffprobe, params }) {
      console.log("FFprobe Action In Project Mixin", body, params);

      if (video_ffprobe) {
        video_ffprobe = JSON.parse(video_ffprobe);
        let data = {
          timecode_in: null,
          video_codec: null,
          audio_codec: null,
          frame_rate: null,
          total_frames: null,
          valid_for_transcribe: false,
          signed_id: params.signed_id,
        };
        data.timecode_in = video_ffprobe.format.tags.timecode || null;
        video_ffprobe.streams.forEach((stream) => {
          switch (stream.codec_type) {
            case "video":
              data.video_codec = stream.codec_name;
              data.total_frames = stream.nb_frames;
              data.frame_rate = stream.avg_frame_rate;
              break;
            case "audio":
              data.audio_codec = stream.codec_name;
              break;
            case "data":
              if (!data.timecode_in) {
                data.timecode_in = stream.tags.timecode || null;
              }
              break;
          }
        });

        // assign default value when it's not present
        if (!data.timecode_in) {
          data.timecode_in = "00:00:00:00";
        }

        if (data.frame_rate) {
          if (typeof data.frame_rate === "string") {
            const matches = data.frame_rate.split("/");
            if (matches.length === 2) {
              data.frame_rate = parseFloat((parseFloat(matches[0]) / parseFloat(matches[1])).toFixed(2));
            }
          }
        } else {
          data.frame_rate = 29.967;
        }

        // check validation for transcription
        data.valid_for_transcribe = !!(data.timecode_in && data.frame_rate);

        const uploadedFiles = this.$store.getters[`${MEDIAME_MODULE_NAME}/uploadedFiles`];
        const item = uploadedFiles.find((f) => f.signed_id === params.signed_id);
        if (item) {
          data.id = item.id;
          this.$store.commit(`${MEDIAME_MODULE_NAME}/updateUploadedFile`, data);
          this.perform("create_clip", {
            files: [{ ...item, ...data }],
            project_hashid: this.selected_project.project_hashid,
          });
        }
      }
    },
    async onConnectionChanged(is_connected) {
      if (is_connected) {
        await SuiteAppUtils.sleep(1);
        if (typeof this.$route.params.project_hashid === "string") {
          this.perform("index_project", {
            project_hashid: this.selected_project_hashid,
          });
        }
        if (
          ["signer", "documents.call_sheets.view", "admin"].filter((r) => (this.$route.name || "").includes(r))
            .length === 0
        ) {
          this.perform("index");
          this.perform("index_accounts", {
            account_hashid: this.$route.params.account_hashid,
          });
          // await SuiteAppUtils.sleep(1);
          // this.perform("index_notifications");
        }
      }
    },
    ...BasicProjectMixin.methods,
    ...mapProjectActions(Object.keys(ProjectModule.actions)),
    ...mapProjectMutations(Object.keys(ProjectModule.mutations)),
  },
  computed: BasicProjectMixin.computed,
  watch: {
    async is_connected(flag) {
      await this.onConnectionChanged(flag);
    },
  },
};

export default BasicProjectMixin;
