export const ASCENDING = (a, b) => (a.name < b.name ? -1 : 1);

export const DESCENDING = (a, b) => {
  if (a.name < b.name) return 1;
  if (a.name > b.name) return -1;
  return 0;
};

export const OLDEST = (a, b) => new Date(a.created_at) - new Date(b.created_at);

export const LATEST = (a, b) => new Date(b.created_at) - new Date(a.created_at);