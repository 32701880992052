import {RouteConfig} from "vue-router";
import ProjectShow from "./pages/ProjectShow.vue";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "projects" <<
 * where ever we mention this, it will group all components in one group.
 */
const ProjectRoutes = [
    {
        path: "/accounts/:account_hashid/workspaces/:workspace_hashid/projects",
        name: "projects",
        component: () => import(/* webpackChunkName: "app" */ "./Project.vue"),
        children: [
            // {
            //     path: "",
            //     name: 'projects.index',
            //     component: () => import(/* webpackChunkName: "app" */'./pages/ProjectIndex.vue'),
            //     meta: {
            //         sidebar: false
            //     }
            // },
            {
                path: "new",
                name: "projects.new",
                component: () =>
                    import(/* webpackChunkName: "app" */ "./pages/main/ProjectNew.vue"),
                meta: {
                    sidebar: "AllProjectsSidebar",
                },
            },
            {
                path: "edit/:project_hashid",
                name: "projects.edit",
                component: () =>
                    import(/* webpackChunkName: "app" */ "./pages/main/ProjectEdit.vue"),
                meta: {
                    sidebar: "AllProjectsSidebar",
                },
            },
            {
                path: ":project_hashid",
                component: ProjectShow,
                name: "projects.show",
                children: [],
            },
            {
                path: "/notification",
                name: "notification",
                component: () =>
                    import(/* webpackChunkName: "app" */ "./pages/main/Notification.vue"),
                meta: {
                    sidebar: "AllProjectsSidebar",
                },
            },
        ],
    },
];

export default ProjectRoutes;
