import {mapActions, mapGetters, mapMutations} from "vuex";

export const ANNOUNCEMENT_MODULE_NAME = "announcement";

export const ANNOUNCEMENT_MODAL_ACTIONS = {
    VIEW_ANNOUNCEMENT: "view",
};

const initState = {
    is_connected: false,
    modal: null,
    announcements: [],
    selected_announcement: null,
    accounts: [],
    projects: [],
    users: [],
};

const AnnouncementModule = {
    namespaced: true,
    state: () => initState,
    mutations: {
        // COMMON
        connected(state, flag) {
            state.is_connected = flag;
        },

        // Announcement module
        index(state, {body: data, params}) {
            state.announcements = data;
        },
        // create anouncement
        create(state, {body: data, params}) {
            state.announcements.push(data);
        },

        // List of accounts
        index_accounts(state, {body: data, params}) {
            state.accounts = data.map((a) => {
                a.hash = `${a.name}`.toUpperCase();
                return a;
            });
        },

        // List of Projects
        index_projects(state, {body: data, params}) {
            state.projects = data.map((p) => {
                p.hash = `${p.name}`.toUpperCase();
                return p;
            });
        },

        //FETCH single Announcement data
        show(state, {body: announcement, params}) {
            if (state.modal && state.modal.action === ANNOUNCEMENT_MODAL_ACTIONS.VIEW_ANNOUNCEMENT) {
                state.selected_announcement = null;
            }
            state.selected_announcement = announcement || null;
        },

        modalData(state, data) {
            state.modal =
                (typeof data === "string" ? {action: data} : data) || null;
        },

        // List of Users
        index_users(state, {body: data, params}) {
            state.users = data.map((u) => {
                u.hash = `${u.first_name ? u.first_name : ""}${
                    u.last_name ? u.last_name : ""
                }${u.email}${u.role ? u.role : ""}`.toUpperCase();
                u.first_name = u.first_name ? u.first_name : "";
                u.last_name = u.last_name ? u.last_name : "";
                return u;
            });
        },
    },
    actions: {
        manageModal({commit}, data) {
            commit("modalData", data);
            $("#announcement-modal").modal(data ? "show" : "hide");
        },
    },
    getters: {
        is_connected: (s) => s.is_connected,
        announcements: (s) => s.announcements,
        selected_announcement: (s) => s.selected_announcement,
        modal: (s) => s.modal,
        accounts: (s) => s.accounts,
        projects: (s) => s.projects,
        users: (s) => s.users,
    },
};

export const mapAnnouncementActions = (data) =>
    mapActions(ANNOUNCEMENT_MODULE_NAME, data);

export const mapAnnouncementGetters = (data) =>
    mapGetters(ANNOUNCEMENT_MODULE_NAME, data);

export const mapAnnouncementMutations = (data) =>
    mapMutations(ANNOUNCEMENT_MODULE_NAME, data);

export default AnnouncementModule;
