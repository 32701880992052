/**
 * Follow this link for more documentations
 * @link https://vee-validate.logaretm.com/v3/guide/basics.html#adding-rules
 */
import { extend, localize } from "vee-validate";
import * as vrules from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";

const strongPass = new RegExp(
  "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})"
);

const rules = () => {
  localize({ en });
  Object.keys(vrules).forEach((rule) => {
    extend(rule, vrules[rule]);
  });
  extend("positive", {
    validate: (value) => {
      return value > 0;
    },
    message: "The {_field_} field must be a positive number",
  });
  extend("alpha_num_spaces", {
    validate: (value) => /^[A-Za-z0-9\s]*$/.test(value),
    message: "The {_field_} field must be a valid name",
  });
  extend("password_strength", {
    validate: (value) => strongPass.test(value),
    message:
      "The {_field_} must be strong (at least one lowercase, one uppercase, one digit, one special character, and minimum 6 characters).",
  });
  extend("password_match", {
    params: ["password"],
    validate(value, { password }) {
      return value === password;
    },
    message: "Password confirmation does not match!",
  });
};

export default rules;
