import {RouteConfig} from "vue-router";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "calendar" <<
 * where ever we mention this, it will group all components in one group.
 */
const CalendarRoutes = [
    {
        path: "calendar",
        component: () => import(/* webpackChunkName: 'calendar' */ "./Calendar.vue"),
        children: [
            {
                path: "",
                name: "calendar.index",
                component: () =>
                    import(/* webpackChunkName: "calendar" */ "./pages/CalendarIndex.vue"),
                meta: { topbar: true, project_title: true }
            },
            // {
            //     path: "",
            //     name: "calendar.index",
            //     component: () =>
            //         import(/* webpackChunkName: "calendar" */ "./components/CreateEvent.vue"),
            // },
        ],
    },
];

export default CalendarRoutes;
