import localforage from "localforage";

let cache = localforage.createInstance({
    name: "SuiteApp",
    storeName: window.location.hostname,
    size: 1024 * 1024 * 1024 * 50 // 50MB
});

export const CACHE_KEYS = {
    ADMIN_CACHE_TIME: 'admin.cache.time',
    ADMIN_PROJECTS: 'admin.projects',
    ADMIN_USERS: 'admin.users',
    ADMIN_ACCOUNTS: 'admin.accounts',
};

export const CacheUtils = {
    async clearAdmin() {
        // data has expired so remove it
        await cache.removeItem(CACHE_KEYS.ADMIN_PROJECTS);
        await cache.removeItem(CACHE_KEYS.ADMIN_USERS);
        await cache.removeItem(CACHE_KEYS.ADMIN_ACCOUNTS);
        await cache.removeItem(CACHE_KEYS.ADMIN_CACHE_TIME);
    }
};

export default cache;