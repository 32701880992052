import {RouteConfig} from "vue-router";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "delivermes" <<
 * where ever we mention this, it will group all components in one group.
 */
const DeliverMERoutes = [
    {
        path: "deliverme",
        // name: 'delivermes',
        component: () => import(/* webpackChunkName: "delivermes" */'./DeliverME.vue'),
        children: [
            {
                path: "",
                name: 'delivermes.index',
                component: () => import(/* webpackChunkName: "delivermes" */'./pages/DeliverMEIndex.vue'),
                meta: { topbar: true, project_title: true }
            },

        ]
    }
];

export default DeliverMERoutes;