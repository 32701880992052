import { RouteConfig } from "vue-router";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "signer" <<
 * where ever we mention this, it will group all components in one group.
 */
const SignerRoutes = [
  {
    path: "/signer",
    name: "signer",
    redirect: { name: "signer.form.new" },
    component: () => import(/* webpackChunkName: "signer" */ "./Signer.vue"),
    meta: { sidebar: false },
    children: [
      {
        name: "signer.form.new",
        path: "/signer/:contract_template_hashid/new",
        meta: { sidebar: false },
        component: () => import(/* webpackChunkName: "signer" */ "./pages/NewSignerFormPage.vue"),
      },
      {
        name: "signer.form.edit",
        path: "/signer/:type/release/:id/edit",
        meta: { sidebar: false },
        component: () => import(/* webpackChunkName: "signer" */ "./pages/EditSignerFormPage.vue"),
      },
      {
        name: "signer.view",
        path: "/signer/:type/release/:id/view",
        meta: { sidebar: false },
        component: () => import(/* webpackChunkName: "signer" */ "./pages/ViewSignerPage.vue"),
      },
    ],
  },
];

export default SignerRoutes;
