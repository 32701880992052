import {mapActions, mapGetters, mapMutations} from "vuex";

export const TASKMANAGER_MODULE_NAME = "taskmanager";
export const TASKMANAGER_MODAL_ACTIONS = {
    ADD: "add",
    EDIT: "edit",
};

const initState = {
    is_connected: false,
    tasks: [],
    modal: null,
    users: [],
    config: {}
};

const TaskManagerModule = {
    namespaced: true,
    state: () => initState,
    mutations: {
        // COMMON
        RESET(state) {
            state.is_connected = false;
            state.tasks = [];
            state.modal = null;
            state.users = [];
            state.config = {};
        },
        connected(state, flag) {
            state.is_connected = flag;
        },


        setData(state, data) {
            state.config = data;
        },

        create(state, {body: data, params}) {
            state.tasks.push(data);
        },

        //LIST of tasks & users
        index(state, {body: data, params}) {
            state.tasks = data.tasks

            // create user's list
            let copyUsersList = structuredClone(data.user_group.users);

            let newGroupList = data.user_group.groups.map((g) => {
                g.users = copyUsersList.filter((u) => g.members.includes(u.id));
                g.type = "Group";
                g.hash = g.users.map((u) => u.hash);
                return g;
            });

            //combine List of users & groups into one ...
            let newUserList = [
                ...data.user_group.users.map((u) => {
                    u.type = "User";
                    return u;
                }),
                ...newGroupList,
            ];

            state.users = newUserList.map((_new) => {
                _new.globalHash = `${_new.name} ${_new.type}`.toLowerCase();
                return _new;
            });

        },
        comment_on_task(state, {body: task, params}) {
            if (task) {
                const index = state.tasks.findIndex(
                    (b) => b.task_hashid === task.task_hashid
                );
                if (index > -1) {
                    state.tasks[index].logs.push(task.log);
                }
            }
        },
        change_status_of_task(state, {body: task, params}) {
            if (task) {
                const index = state.tasks.findIndex(
                    (b) => b.task_hashid === task.task_hashid
                );
                if (index > -1) {
                    state.tasks.splice(index, 1, task);
                }
            }
        },

        update(state, {body: task}) {
            if (task) {
                const index = state.tasks.findIndex(
                    (b) => b.task_hashid === task.task_hashid
                );
                if (index > -1) {
                    state.tasks.splice(index, 1, task);
                }
            }
        },

        destroy(state, task) {
            const index = state.tasks.findIndex(
                (b) => b.task_hashid === task.params.task_hashid
            );
            if (index > -1) {
                state.tasks.splice(index, 1);
            }
        },

        modalData(state, data) {
            state.modal =
                (typeof data === "string" ? {action: data} : data) || null;
        },
    },
    actions: {
        init({commit, rootGetters}) {
            let data = {
                user: rootGetters["project/current_user"],
                project_id: rootGetters["project/selected_project_id"],
                account_slug: rootGetters["project/selected_account_slug"],
            };
            commit("setData", data);
        },
        // close - this.manageModal();
        // add - this.manageModal("add")
        // edit - this.manageModal("edit")
        // OR edit - this.manageModal({action: "edit", payload: "dad23dg34"})
        manageModal({commit}, data) {
            commit("modalData", data);
            $("#taskmanager-modal").modal(data ? "show" : "hide");
            // if (!data) {
            //     commit("edit");
            // }
        },
    },
    getters: {
        is_connected: (s) => s.is_connected,
        tasks: (s) => s.tasks,
        users: (s) => s.users,
        modal: (s) => s.modal
    },
};

export const mapTaskManagerActions = (data) =>
    mapActions(TASKMANAGER_MODULE_NAME, data);
export const mapTaskManagerGetters = (data) =>
    mapGetters(TASKMANAGER_MODULE_NAME, data);

export const mapTaskManagerMutations = (data) =>
    mapMutations(TASKMANAGER_MODULE_NAME, data);

export default TaskManagerModule;
