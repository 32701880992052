export default class Logger {
    /**
     * Enable logging for debug
     */
    _debug;
    /**
     * Debug level required for logging. Either `info`, `error`, or `all`
     */
    _debugLevel;
    _tag = 'Log';

    /**
     * Vue logger entry point
     * @param {boolean} debug - Enable logging for debug
     * @param {string} debugLevel - Debug level required for logging. Either `info`, `error`, or `all`
     * @param {string|null} tag - tag name
     */
    constructor(debug, debugLevel, tag = null) {
        this._debug = debug;
        this._debugLevel = debugLevel;
        this._tag = tag;
        if (debug) {
            console.log("----------- Logger Enabled -----------")
        }
    }

    /**
     * Logs a message out to the console
     * @param {[string]} messages - The message to log out to the console
     * @example
     *  log("app.vuex", "log A")
     */
    log(...messages) {
        if (this._debug) {
            if (this._debugLevel === "all") {
                console.log(...messages)
            } else {
                console[this._debugLevel](...messages)
            }
        }
    }

    /**
     * info method with log level info
     * @param {[string]} messages
     */
    info(...messages) {
        if (this._debug) {
            if (this._debugLevel === "all" || this._debugLevel === "info")
                console.info(...messages)
        }
    }

    /**
     * debug method with log level debug
     * @param {[string]} messages
     */
    debug(...messages) {
        if (this._debug) {
            if (this._debugLevel === "all" || this._debugLevel === "debug")
                console.debug(...messages)
        }
    }

    /**
     * error method with log level error
     * @param {[string]} messages
     */
    error(...messages) {
        if (this._debug) {
            if (this._debugLevel === "all" || this._debugLevel === "error")
                console.error(...messages)
        }
    }
}