import { RouteConfig } from "vue-router";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "app" <<
 * where ever we mention this, it will group all components in one group.
 */
const AccountRoutes = [
  {
    path: "/accounts",
    name: "accounts",
    component: () => import(/* webpackChunkName: "app" */ "./Account.vue"),
    children: [
      {
        path: "projects/new",
        name: "account_projects.new",
        component: () => import(/* webpackChunkName: "app" */ "./pages/main/ProjectNew.vue"),
        meta: {
          sidebar: "none",
        },
      },
      {
        path: "",
        name: "accounts.index",
        component: () => import(/* webpackChunkName: "app" */ "./pages/MainPageV2.vue"),
        meta: { topbar: true, sidebar: "HomePageSidebar" },
      },
      {
        path: ":account_hashid",
        name: "accounts.show",
        component: () => import(/* webpackChunkName: "app" */ "./pages/MainPageV2.vue"),
        meta: { topbar: true, sidebar: "HomePageSidebar" },
      },
      {
        path: ":account_hashid/workspaces/:workspace_hashid",
        name: "accounts.workspaces.show",
        component: () => import(/* webpackChunkName: "app" */ "./pages/MainPageV2.vue"),
        meta: { topbar: true, sidebar: "HomePageSidebar" },
      },
      {
        path: ":account_hashid/settings",
        name: "accounts.settings.show",
        // component: () => import(/* webpackChunkName: "app" */'./pages/AccountSettings.vue'),
        component: () => import(/* webpackChunkName: "app" */ "./pages/AccountSettingsv2.vue"),
        meta: { sidebar: "none", topbar: true, mesuite_logo: true },
      },
      {
        path: ":account_hashid/archived-projects",
        name: "accounts.archived_projects.show",
        component: () => import(/* webpackChunkName: "app" */ "./pages/ArchivedProjectByAccount.vue"),
        meta: { sidebar: "none", topbar: true, mesuite_logo: true },
      },
    ],
  },
];

export default AccountRoutes;
