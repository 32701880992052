import { RouteConfig } from "vue-router";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "mediames" <<
 * where ever we mention this, it will group all components in one group.
 */
const MediaMERoutes = [
  {
    path: "mediame",
    component: () => import(/* webpackChunkName: 'mediames' */ "./MediaME.vue"),
    children: [
      {
        path: "",
        name: "mediames.index",
        component: () => import(/* webpackChunkName: "mediames" */ "./pages/MediaMeIndexv2.vue"),
        meta: { topbar: true, project_title: true, sub_sidebar: "MediaMeSubSidebar" },
      },
      {
        path: ":id",
        name: "mediames.video",
        component: () => import(/* webpackChunkName: "mediames" */ "./pages/VideoIndexV2.vue"),
        meta: {
          topbar: true,
          sidebar: false,
          isMediaMeIcon: true,
          project_title: true,
          hideTopbarInMobile: true,
        },
      },
      {
        path: "stream/:stream_id",
        name: "mediames.stream.new",
        component: () => import(/* webpackChunkName: "mediames" */ "./pages/StreamSession.vue"),
        meta: {
          topbar: true,
          sidebar: false,
          isMediaMeIcon: true,
          project_title: true,
          hideTopbarInMobile: true,
        },
      },
      {
        path: ":id/:type/guest",
        name: "mediames.guest.video",
        component: () => import(/* webpackChunkName: "mediames" */ "./pages/GuestVideoIndex.vue"),
        meta: {
          sidebar: false,
          hideTopbarInMobile: true,
        },
      },
      {
        path: "smart-tags/:id",
        name: "mediames.smart-tags.index",
        component: () => import("./pages/MediaMeIndexv2.vue"),
        meta: { topbar: true, project_title: true, sub_sidebar: "MediaMeSubSidebar" },
      },
    ],
  },
];

export default MediaMERoutes;
