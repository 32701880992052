<template>
  <transition name="fade" mode="out-in" v-if="skip_extra_ui">
    <router-view></router-view>
  </transition>
  <div v-else class="main-page" :style="{ '--topbar-height': `${topbarHeight}px` }">
    <SidebarSwitcher />
    <div class="sans-sidebar">
      <div class="topbar-wrapper" id="topbar-wrapper">
        <transition name="fade" mode="out-in">
          <component :is="whichTopbar" />
        </transition>
      </div>

      <div class="sans-topbar">
        <SubSidebar />
        <div class="main-content">
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </div>
    <!-- <FilesBeingUploaded /> -->
  </div>
</template>

<script>
import "./assets/styles/css/bootstrap.css";
import "./assets/styles/css/variables.css";
import "./assets/styles/css/buttons.css";
import "./assets/styles/css/inputfield.css";
import "./assets/styles/css/card.css";
import "./assets/styles/css/table.css";
import "./assets/styles/css/scrollbar.css";
import "./assets/styles/css/style.css";

import "./assets/styles/styles.css";

import "./assets/styles/notifications/style.scss";
import "./assets/styles/css/animation.css";

import "./assets/styles/project_custom_styles.css";

import { mapProjectMutations, PROJECT_MODULE_NAME } from "./Project/store/project.vuex";

import { ProjectMixin } from "./Project/mixin/project.mixin";
import AuthApi from "./Auth/auth.api";

import SuiteAppUtils from "./utils.suite-app";

export default {
  name: "App",
  components: {
    SidebarSwitcher: () => import(/* webpackChunkName: "app" */ "./Common/Sidebar/SidebarSwitcher.vue"),
    TopBar: () => import(/* webpackChunkName: "app" */ "./Common/TopBarv2.vue"),
    TopbarSignup: () => import(/* webpackChunkName: "app" */ "./Common/TopbarSignup.vue"),
    SubSidebar: () => import(/* webpackChunkName: "app" */ "./Common/Sidebar/SubSidebar.vue"),
    // FilesBeingUploaded: () => import(/* webpackChunkName: "app" */ "./Common/FilesBeingUploded.vue"),
  },
  mixins: [ProjectMixin],
  data() {
    return {
      project_hashid: null,
      skip_auth: ["auth", "signer.form.new", "signer.view", "documents.call_sheets.view", "mediames.guest.video"],
      defaultTopbar: "TopBar",
      topbarHeight: 55,
    };
  },
  async mounted() {
    this.$router.onError((e) => {
      console.error("Route ERROR: ", e);
    });
    this.$router.onReady(async () => {
      console.log("ROUTER IS READY:", this.$route.name);
      const data = await AuthApi.check();
      this.init_data(data);
      this.$logger.log(data);
      if (data.signed_in) {
        if (["auth", "home"].filter((r) => (this.$route.name || "").includes(r)).length > 0) {
          const to_route = { name: "accounts.index" };
          if (this.$route.query) to_route.query = this.$route.query;
          await this.$router.replace(to_route);
        }
        await this.setActiveProject();
      } else {
        this.redirectToLogin();
      }
    });
    await Notification.requestPermission();
  },
  methods: {
    ...mapProjectMutations(["set_current_route", "init"]),
    redirectToLogin() {
      if (this.skip_auth.filter((r) => (this.$route.name || "").includes(r)).length === 0) {
        console.log("Redirect to Login.....", this.$route.name);
        this.$router.replace({ name: "auth.login" });
      }
    },
    async setActiveProject() {
      if (this.project_hashid !== (this.$route.params.project_hashid || null)) {
        await AuthApi.setActive((this.project_hashid = this.$route.params.project_hashid || null));
      }
    },
    initSignupProduct(to_route) {
      if (to_route.query.new_user) {
        this.$store.commit(`${PROJECT_MODULE_NAME}/set_is_new_user`, true);
      }
    },
  },
  watch: {
    $route(to, from) {
      console.log("Route changed from " + from.name + " to " + to.name);

      if (to) {
        this.initSignupProduct(to);
        this.set_current_route(to);
      }
      this.setActiveProject();

      $('[data-toggle="tooltip"]').tooltip("dispose");
      setTimeout(() => {
        $('[data-toggle="tooltip"]').tooltip();
      }, 1000);
    },
    async whichTopbar() {
      await SuiteAppUtils.sleep(1);
      const el = document.getElementById("topbar-wrapper");
      if (el) this.topbarHeight = el.getBoundingClientRect().height || 55;
    },
  },
  computed: {
    isTopBarPresent() {
      if (!"topbar" in this.$route.meta) return true;
      return this.$route.meta?.topbar;
    },
    skip_extra_ui() {
      if (!this.$route?.name) {
        return true;
      }
      return ["auth", "signer", "documents.call_sheets.view"].filter((r) => this.$route?.name?.includes(r)).length > 0;
    },
    whichTopbar() {
      if (!"topbar" in this.$route.meta) return null;
      else if (!this.$route.meta.topbar || this.$route.meta.topbar === "none") return null;
      else if (this.$route.meta.topbar === true) return this.defaultTopbar;

      return this.$route.meta.topbar;
    },
  },
};
</script>

<style scoped>
.main-page {
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
  overflow: hidden;
  background: #f4f4f4;
}

.sans-sidebar {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  flex-grow: 1;
  overflow: hidden;
}

@media (min-width: 0px) and (max-width: 591px) {
  .sans-topbar {
    flex-flow: column nowrap !important;
  }
}

.sans-topbar {
  height: calc(100vh - var(--topbar-height));
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  border-left: var(--sans-topbar-border-left-width, 1px) solid var(--sans-topbar-border-left-color, #a8a8a8);
  border-top: 1px solid var(--sans-topbar-border-top-color, #a8a8a8);
  overflow: hidden;

  @media (min-width: 591px) {
    border-top-left-radius: 5px;
  }

  @media (0 < width < 591px) {
    border-left: 0;
    border-right: 0;
  }
}

.main-content {
  overflow: auto;
  flex-grow: 1;
  background: #fff;
}
</style>

<style>
.fa-fw {
  font-size: 12px;
}

.section-main {
  padding-top: 0 !important;
}

.custom-left {
  transform: translate3d(-144px, 22px, 0px) !important;
}

.expand-dropdown-icon {
  background: #e2e5fb;
  padding: 0 3px;
  cursor: pointer;
  border-radius: 2px;
}

.expand-dropdown-icon:active,
.expand-dropdown-icon:hover {
  background: #6f89ff;
}

.dropdown-item {
  font-size: 13px;
}

.fs-13 {
  font-size: 13px;
}

/* media queries to adjust payment method related warning in mobile view*/
@media screen and (max-device-width: 480px) {
  .payment-warning-m {
    margin-top: 4.5em;
    width: 93%;
  }

  .section-main {
    padding-top: 0 !important;
  }
}

@media only screen and (min-width: 481px) {
  .payment-warning {
    margin-left: 1em;
    margin-top: 4.5em;
    width: 93%;
  }
}

@media only screen and (min-width: 1024px) {
  .payment-warning {
    width: calc(100% - 295px);
    margin-left: 17em;
    margin-top: 1em;
  }

  .section-breadcrumbs {
    margin-left: auto;
  }
}

.breadcrumb-links {
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}
</style>
