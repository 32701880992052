import {RouteConfig} from "vue-router";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "announcement" <<
 * where ever we mention this, it will group all components in one group.
 */
const AnnouncementRoutes = [
    {
        path: "transcripts",
        name: "admin.transcripts",
        component: () =>
            import(/* webpackChunkName: "announcement" */ "./VideoTranscript.vue"),
        meta:{sidebar:"AdminSidebar"},
        children: [
            {
                path: "",
                name: "admin.transcripts.index",
                component: () =>
                    import(
                        /* webpackChunkName: "announcement" */ "./pages/VideoTranscriptIndex.vue"
                        ),
                meta:{sidebar:"AdminSidebar"},
            },
            {
                path: "new",
                name: "admin.transcripts.new",
                component: () =>
                    import(
                        /* webpackChunkName: "announcement" */ "./pages/CreateVideoTranscript.vue"
                        ),
                meta:{sidebar:"AdminSidebar"},
            },
        ],
    },
];

export default AnnouncementRoutes;
