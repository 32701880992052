import {RouteConfig} from "vue-router";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "app" <<
 * where ever we mention this, it will group all components in one group.
 * NOTE: This route has directly children of the account sub route, because workspaces are children of the account
 */
const WorkspaceRoutes = [
    {
        path: ":account_hashid/workspace",
        name: "accounts.workspaces",
        component: () => import(/* webpackChunkName: "app" */'./Workspace.vue'),
        children: [
            {
                path: "",
                name: "workspace.index",
                component: () => import(/* webpackChunkName: "app" */'./components/WorkspaceDashboard.vue'),
                meta: {sidebar: "none", topbar: true, mesuite_logo: true},
            },
            {
                path: "manager",
                name: "accounts.workspace.settings",
                component: () => import(/* webpackChunkName: "app" */'./components/WorkspaceSettings.vue'),
                meta: {sidebar: "none", topbar: true, mesuite_logo: true},
            },
        ]
    }
];

export default WorkspaceRoutes;