import {mapActions, mapGetters, mapMutations} from "vuex";

export const CALL_SHEET_MODULE_NAME = "callsheets";

const initState = {
    is_connected: false,
    call_sheets: [],
    metadata: [],
    call_sheet: null
};


const CallSheetModule = {
    namespaced: true,
    state: () => initState,
    mutations: {
        index(state, data) {
            state.call_sheets = data.callsheets;
        },
        create(state, call_sheet) {
            state.call_sheets.unshift(call_sheet);
        },
        update(state, call_sheet) {
            const index = state.call_sheets.findIndex(o => o.callsheet_hashid === call_sheet.callsheet_hashid);
            if (index > -1) {
                state.call_sheets.splice(index, 1, call_sheet);
            }
        },
        show(state, data) {
            state.call_sheet = data;
        },
        duplicate(state, data) {
            state.call_sheets.unshift(data);
        },
        update_status(state, call_sheet) {
            const index = state.call_sheets.findIndex(o => o.callsheet_hashid === call_sheet.table.callsheet_hashid);
            if (index > -1) {
                state.call_sheets[index].status = call_sheet.table.status;
            }
        },
        destroy(state, callsheet_hashid) {
            const index = state.call_sheets.findIndex(
                (b) => b.callsheet_hashid === callsheet_hashid.table.callsheet_hashid
            );
            if (index > -1) {
                state.call_sheets.splice(index, 1);
            }
        },

        // meta data
        form_metadata(state, data)  {
            state.metadata = data

        },
        connected(state, flag) {
            state.is_connected = flag;
        },
        SET_CALL_SHEET(state, data) {
            state.call_sheet = data
          },
    },
    actions: {},
    getters: {
        call_sheets: s => s.call_sheets,
        is_connected: s => s.is_connected,
        metadata: s => s.metadata,
        call_sheet: s => s.call_sheet
    }
};

export const mapCallSheetActions = data => mapActions(CALL_SHEET_MODULE_NAME, data);

export const mapCallSheetGetters = data => mapGetters(CALL_SHEET_MODULE_NAME, data);

export const mapCallSheetMutations = data => mapMutations(CALL_SHEET_MODULE_NAME, data);

export default CallSheetModule;