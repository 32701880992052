import { mapActions, mapGetters, mapMutations } from "vuex";
import { MEDIAME_MODULE_NAME } from "../../MediaME/store/mediame.vuex";

export const PROJECT_MODULE_NAME = "project";

export const PROJECT_MODAL_ACTIONS = {
  ADDRESS: "address",
  NOTIFICATION: "notification",
  BILLING: "billing",
  PLAN_SELECTION: "plan_selection",
  PLAN_UPGRADE: "plan_upgrade",
  EMAILS: "emails",
  CREATE_UPDATE_WORKSPACE: "create.update.workspace",
  ADD_ACCOUNT_MANAGERS: "add_account_managers",
  WORKSPACE_INTRO: "WORKSPACE_INTRO",
  WORKSPACE_RESTRICTED_ACCESS: "WORKSPACE_RESTRICTED_ACCESS",
  EDIT_WORKSPACE: "EDIT_WORKSPACE",
  MOVE_PROJECTS_WORKSPACES: "MOVE_PROJECTS_WORKSPACES",
  UN_ARCHIVE_PROJECTS_WORKSPACE_SELECTION: "UN_ARCHIVE_PROJECTS_WORKSPACE_SELECTION",
  PAYMENT_METHOD: "PAYMENT_METHOD",
  PAYMENT_HISTORY: "PAYMENT_HISTORY",
  EDIT_PROJECT: "EDIT_PROJECT",
  ADD_PROJECT: "ADD_PROJECT",
  CREATE_SUBSCRIPTION: "CREATE_SUBSCRIPTION",
  EMAIL_VERIFICATION: "EMAIL_VERIFICATION",
  GET_STARTED: "GET_STARTED",
};

export const WORKSPACE_UPDATE_OPTIONS = {
  NAME: "NAME",
  PLAN: "PLAN",
  MIGRATE_ONE: "MIGRATE_ONE",
  MIGRATE_MANY: "MIGRATE_MANY",
  CANCEL: "CANCEL",
};

const initState = {
  is_connected: false,
  isLoading: { index_accounts: true, index_workspaces_by_account: true, index_projects_by_account: true },
  init_data: null,
  selected_notification: null,

  accounts: [],

  workspaces: [],

  projects: [],

  users: [],

  notifications: [],
  notification_unread_count: null,
  pricing_plans: [],
  stripe_customers: [],

  modal: null,

  current_user: null,
  env: null,

  current_route: null,
  project_selected: null, // for edit only,

  selected_project: {},
  last_account_used_hashid: "",
  products: [],
  pinned_Items: [],

  sign_up_product: null,
  is_new_user: false,
};

const ProjectModule = {
  namespaced: true,
  state: () => initState,
  mutations: {
    init(state, data) {
      state.current_user = data;
    },

    init_data(state, data) {
      state.init_data = data;
    },

    // PROJECT
    index(state, { body: data, params }) {
      state.current_user = data.current_user;
      state.env = data.env;
      state.pricing_plans = data.pricing_plans;
    },
    index_accounts(state, { body }) {
      state.accounts = body.accounts.map((a) => {
        a.is_current_user_account_manager = body.account_manager_ids.includes(a.account_hashid);
        return a;
      });
      state.isLoading.index_accounts = false;
    },

    show_account(state, { body: data, params }) {
      const index = state.accounts.findIndex((a) => a.account_hashid === data.account_hashid);
      if (index > -1) {
        data.payment_methods = data.payment_methods.map((m) => {
          let res = [m.response.brand, m.response.funding, m.response.ending, m.response.last4];
          m.title = res.filter((i) => !!i).join(" ");
          return m;
        });
        state.accounts.splice(index, 1, { ...state.accounts[index], ...data });
      }
    },

    index_workspaces_by_account(state, { body }) {
      const ids = state.workspaces.map((p) => p.workspace_hashid);
      body.forEach((workspace) => {
        // add product keys
        if (!workspace.is_legacy && workspace.subscription) {
          workspace.product_keys = workspace.subscription.products.map((p) => p.key);
        }
        if (ids.includes(workspace.workspace_hashid)) {
          const index = state.workspaces.findIndex((w) => w.workspace_hashid === workspace.workspace_hashid);
          if (index > -1) {
            state.workspaces.splice(index, 1, workspace);
          }
        } else {
          state.workspaces.push(workspace);
        }
      });
      state.isLoading.index_workspaces_by_account = false;
    },

    index_projects_by_account(state, { body }) {
      const ids = state.projects.map((p) => p.id);
      body.forEach((p) => {
        // if already has project then update entire project else add the project
        if (ids.includes(p.id)) {
          const index = state.projects.findIndex((project) => project.id === p.id);
          if (index > -1) {
            state.projects.splice(index, 1, p);
          }
        } else {
          state.projects.unshift(p);
        }
      });
      state.isLoading.index_projects_by_account = false;
    },

    create_workspace(state, { body }) {
      state.workspaces.push(body);
    },
    set_workspaces(state, w) {
      const index = state.workspaces.findIndex((ws) => ws.workspace_hashid === w.workspace_hashid);
      if (index !== -1) {
        // Update the workspace at the found index
        state.workspaces.splice(index, 1, w);
      }
    },
    update_workspace(state, { body }) {
      const index = state.workspaces.findIndex((w) => w.workspace_hashid === body.workspace_hashid);
      if (index > -1) {
        state.workspaces.splice(index, 1, body);
      }
    },
    show_workspace_usages(state, { body, params }) {
      const index = state.workspaces.findIndex((w) => w.workspace_hashid === params.workspace_hashid);
      if (index > -1) {
        state.workspaces.splice(index, 1, { ...state.workspaces[index], threshold: body });
      }
    },

    create(state, { body: project }) {
      state.projects.unshift(project);
    },

    show(state, { body: data, params }) {
      state.project_selected = data;
    },

    update(state, { body: project }) {
      const index = state.projects.findIndex((p) => p.project_hashid === project.project_hashid);
      if (index > -1) {
        state.projects.splice(index, 1, project);
      }
      state.projects.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

      //update Selected Project
      if (state.project_selected.project.project_hashid === project.project_hashid)
        state.project_selected = { ...state.project_selected, project: { ...project } };
      if (state.selected_project.project_hashid === project.project_hashid) state.selected_project = { ...project };
    },

    // Single Project
    index_project(state, { body: data }) {
      const { project } = data;
      let hash = `${project.name} ${project.account_name} ${project.client_name}`.toLowerCase();
      let role_code = 0;
      switch (project.role) {
        case "project_admin":
          role_code = 2;
          break;
        case "project_manager":
          role_code = 1;
          break;
        case "associate":
          role_code = 0;
          break;
      }

      state.selected_project = { ...project, role_code, hash };
      let single_project = state.projects.filter((p) => p.project_hashid === state.selected_project.project_hashid);
      if (!single_project) {
        state.projects.push(state.selected_project);
      }
    },

    // USERS
    index_users(state, { body: users }) {
      state.users = users;
    },

    destroy_invitation(state, { params }) {
      const index = state.projects.findIndex((p) => p.project_hashid === params.project_hashid);
      if (index > -1) {
        state.projects.splice(index, 1);
      }
    },

    index_stripe_customers(state, { body }) {
      state.stripe_customers = body || [];
    },

    set_current_user(state, user) {
      state.current_user = user;
    },

    // Notification
    index_notifications(state, { body: data, params }) {
      state.notifications = data.notifications;
      state.unread_count = data.unread_count;
    },
    show_notification(state, { body: data, params }) {
      state.selected_notification = data;
    },

    // COMMON
    connected(state, flag) {
      state.is_connected = flag;
    },
    set_current_route(state, route) {
      state.current_route = route;
    },
    modalData(state, data) {
      if (!data) {
        state.selected_notification = null;
      }
      state.modal = (typeof data === "string" ? { action: data } : data) || null;
    },
    show_account_workspaces_usages(state, { body }) {
      const combined = [];
      const usagesMap = new Map(); // Efficient for frequent lookups

      // Create a map for efficient lookup by usage id
      body.data.forEach((usage) => {
        usagesMap.set(usage.workspace_hashid, usage);
      });

      state.workspaces.forEach((workspace) => {
        const matchingUsage = usagesMap.get(workspace.workspace_hashid);
        combined.push({
          ...workspace, // Spread workspace properties
          usage: matchingUsage ? matchingUsage.usage : [],
          invoices: matchingUsage ? matchingUsage.invoices : [],
        });
      });
      state.workspaces = combined;
    },
    set_last_account_used_hashid(state, body) {
      state.last_account_used_hashid = body;
      console.log("set_last_account_used_hashid called");
    },
    get_product_pricings(state, { body }) {
      state.products = body;
    },
    get_workspace_usage_details(state, { body, params }) {
      const index = state.workspaces.findIndex((w) => w.workspace_hashid == params.workspace_hashid);

      if (index > -1) {
        state.workspaces.splice(index, 1, { ...state.workspaces[index], ...body });
      }
    },
    setLoading(state, { action: action_name, flag = true }) {
      state.isLoading[action_name] = flag;
    },
    ADD_PINNED_ITEM(state, page) {
      state.pinned_Items.push({ key: page.key }); // Store the item
    },
    REMOVE_PINNED_ITEM(state, page) {
      const index = state.pinned_Items.findIndex((item) => item.key === page.key);
      if (index !== -1) {
        state.pinned_Items.splice(index, 1); // Remove the item
      }
    },
    verify_email(state, { body: data }) {
      console.log(data);
      state.current_user = data.current_user;
    },
    set_sign_up_product(state, product) {
      state.sign_up_product = product;
    },
    set_is_new_user(state, flag) {
      state.is_new_user = flag;
    },
  },
  actions: {
    manageModal({ commit }, data) {
      console.log(data);
      commit("modalData", data);
      $("#project-modal").modal(data ? "show" : "hide");
    },
    addPinnedItem({ commit }, page) {
      commit("ADD_PINNED_ITEM", page);
    },
    removePinnedItem({ commit }, page) {
      commit("REMOVE_PINNED_ITEM", page);
    },
    updateWorkspaces({ commit }, w) {
      commit("set_workspaces", w);
    },
  },
  getters: {
    accounts: (s) => s.accounts,
    all_workspaces: (s) => s.workspaces,
    modal: (s) => s.modal,
    sign_up_product: (s) => s.sign_up_product,
    pricing_plans: (s) => s.pricing_plans || [],
    projects: (s) =>
      s.projects.map((p) => {
        p.hash = `${p.name} ${p.account_name} ${p.client_name}`.toLowerCase();
        p.role_code = 0;
        switch (p.role) {
          case "project_admin":
            p.role_code = 2;
            break;
          case "project_manager":
            p.role_code = 1;
            break;
          case "associate":
            p.role_code = 0;
            break;
        }
        return p;
      }),

    project_selected: (s) => s.project_selected, // for edit project only
    products: (s) => s.products,

    stripe_customers: (s) => s.stripe_customers,

    users: (s) => s.users,
    unread_count: (s) => s.unread_count,
    notifications: (s) => s.notifications,
    selected_notification: (s) => s.selected_notification,

    is_user_logged_in: (s) => !!s.current_user,
    current_user: (s) => {
      const is_masked = (s.init_data || {}).is_masked;
      return (
        {
          ...s.current_user,
          is_masked: is_masked,
          is_admin: is_masked || (s.current_user || {}).is_admin,
          isUserImage: String(s?.current_user?.avatar).includes("<img"),
        } || {}
      );
    },
    env: (s) => s.env || {},

    is_connected: (s) => s.is_connected,
    isLoading: (s) => s.isLoading,

    current_route: (s) => s.current_route || {},
    selected_project: (state, getters) =>
      state.selected_project || getters.projects.find((p) => p.project_hashid === getters.selected_project_hashid),
    selected_project_id: (_, getters) => getters.selected_project.id,
    selected_account: (state, getters) =>
      state.accounts.find((a) => a.account_hashid === getters.selected_project.account_hashid) || {},
    selected_account_slug: (_, getters) => getters.selected_account.slug,
    lock_download: (_, getters) => {
      return false;
      // const currentUser = getters.current_user;
      // const isAdmin = currentUser.is_admin;
      // const hasPaymentMethod = getters.selected_project.payment_methods !== null;
      // const isFreeTrial = getters.selected_project.free_plan;
      // const isBillable = getters.selected_project.is_billable;
      //
      // if (isAdmin || isFreeTrial || hasPaymentMethod || !isBillable) {
      //     return false; // Allow download
      // } else {
      //     return true; // Lock download
      // }
    },
    last_account_used_hashid: (s) => s.last_account_used_hashid,
    pinned_Items: (s) => s.pinned_Items,
    is_new_user: (s) => s.is_new_user,
  },
};

export const mapProjectActions = (data) => mapActions(PROJECT_MODULE_NAME, data);

export const mapProjectGetters = (data) => mapGetters(PROJECT_MODULE_NAME, data);

export const mapProjectMutations = (data) => mapMutations(PROJECT_MODULE_NAME, data);

export const withCurrentUser = (getter) => ({
  ...getter,
  ...mapProjectGetters(["current_user", "is_user_logged_in"]),
});

export const withSelectedProject = (getter) => ({
  ...getter,
  ...mapProjectGetters(["selected_project_id", "selected_account_slug", "selected_project", "lock_download"]),
});

export default ProjectModule;
