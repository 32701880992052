import {mapActions, mapGetters, mapMutations} from "vuex";

export const PRODUCT_MODULE_NAME = "product-settings";

export const PRODUCT_MODAL_ACTIONS = {
    PRODUCT_CREATE_MODAL: 'product_create_modal'
};


export const PRODUCTS = [
    {title: "SignME", key: "signme"},
    {title: "DeliverME", key: "deliverme"},
    {title: "MediaME", key: "mediame"},
    {title: "DirectME", key: "directme"},
    {title: "PayME", key: "payme"},
];

const initState = {
    is_connected: false,
    modal: null,
    products: [],
    accounts: []
};




const ProductModule = {
    namespaced: true,
    state: () => initState,
    mutations: {
        connected(state, flag) {
            state.is_connected = flag;
        },
        modalData(state, data) {
            state.modal =
                (typeof data === "string" ? {action: data} : data) || null;

        },
        index_products(state, {body: data, params}) {
            console.log(data);
            state.products = data.products;
            state.accounts = data.accounts;
        },
    },
    actions: {
        manageModal({commit}, data) {
            commit("modalData", data);
            $("#product-modal").modal(data ? "show" : "hide");
        },
    },
    getters:{
        is_connected: (s) => s.is_connected,
        modal: (s) => s.modal,
        products: (s) => s.products,
        accounts: (s) => s.accounts,
    }

};


export const mapProductActions = (data) => mapActions(PRODUCT_MODULE_NAME, data);

export const mapProductGetters = (data) => mapGetters(PRODUCT_MODULE_NAME, data);

export const mapProductMutations = (data) =>
    mapMutations(PRODUCT_MODULE_NAME, data);

export default ProductModule;