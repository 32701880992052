import {mapActions, mapGetters, mapMutations} from 'vuex';

export const DIRECTME_MODULE_NAME = 'directme';
export const DIRECTME_MODAL_ACTIONS = {
    ADD: 'add',
    EDIT: 'edit'
};

const initState = {
    is_connected: false,
    broadcasts: [],
    modal: null,
    selected_broadcast: null
};

const DirectMEModule = {
    namespaced: true,
    state: () => initState,
    mutations: {
        // COMMON
        RESET(state) {
            state.is_connected = false;
            state.broadcasts = [];
            state.modal = null;
            state.selected_broadcast = null;
        },
        connected(state, flag) {
            state.is_connected = flag;
        },

        //LIST Broadcast
        index(state, {body: broadcasts, params}) {
            state.broadcasts = broadcasts;
        },

        //CREATE Broadcast
        create(state, {body: broadcast, params}) {
            state.broadcasts.push(broadcast);
            //Sorting
            state.broadcasts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        },

        //FETCH single Broadcast data
        edit(state, {body: broadcast, params}) {
            state.selected_broadcast = broadcast || null;
        },

        //DELETE Broadcast
        destroy(state, {body: broadcast, params}) {
            const index = state.broadcasts.findIndex(b => b.broadcast_hashid === broadcast.table.broadcast_hashid);
            if (index > -1) {
                state.broadcasts.splice(index, 1)
            }
        },

        //UPDATE Broadcast
        update(state, {body: broadcast, params}) {
            const index = state.broadcasts.findIndex(b => b.broadcast_hashid === broadcast.broadcast_hashid);
            if (index > -1) {
                state.broadcasts.splice(index, 1, broadcast)
            }
        },

        //RESET URL
        reset_url(state, {body: data, params}) {
            const index = state.broadcasts.findIndex(b => b.broadcast_hashid === data.broadcast_hashid);
            if (index > -1) {
                state.broadcasts.splice(index, 1, {live_stream_url: data.live_stream_url, ...data})
            }
        },
        modalData(state, data) {
            if (state.modal && state.modal.action === DIRECTME_MODAL_ACTIONS.EDIT) {
                state.selected_broadcast = null;
            }
            state.modal = (typeof data === "string" ? {action: data} : data) || null;
        },
    },
    actions: {
        // close - this.manageModal();
        // add - this.manageModal("add")
        // edit - this.manageModal("edit")
        // OR edit - this.manageModal({action: "edit", payload: "dad23dg34"})
        manageModal({commit}, data) {
            commit("modalData", data);
            $('#directme-modal').modal(data ? 'show' : 'hide');
        },
    },
    getters: {
        is_connected: s => s.is_connected,
        broadcasts: s => s.broadcasts,
        selected_broadcast: s => s.selected_broadcast,
        modal: s => s.modal,
    }
};

export const mapDirectMEActions = data => mapActions(DIRECTME_MODULE_NAME, data);
export const mapDirectMEGetters = data => mapGetters(DIRECTME_MODULE_NAME, data);

export const mapDirectMEMutations = data => mapMutations(DIRECTME_MODULE_NAME, data);

export default DirectMEModule