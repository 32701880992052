import Vue from "vue";
import App from "./../SuiteApp/app";
import store from "./../SuiteApp/store";
import ActionCableVue from "actioncable-vue";
import LazyLoadDirective from "../Shared/LazyLoadDirective";
import SuiteAppUtils from "../SuiteApp/utils.suite-app";
import router from "../SuiteApp/router";
import {
    ValidationObserver,
    ValidationProvider,
} from "vee-validate";
import rules from "../SuiteApp/validation.rules";
import VueLogger from "../Libs/Logger";
import VueAWN from "vue-awesome-notifications";
import axios from 'axios';
import '../SuiteApp/filters.js';
import '../SuiteApp/directives.js';
import VueTheMask from 'vue-the-mask';
import {v4 as uuidv4} from 'uuid';

const mount = () => {

    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
    window.URL = window.URL || window.webkitURL || window.mozURL || window.msURL;


    rules();

    // axios
    axios.defaults.headers['Content-Type'] = 'application/json';
    axios.defaults.headers['Accept'] = 'application/json';

    // config
    Vue.config.productionTip = SuiteAppUtils.IS_DEV;
    Vue.config.devtools = SuiteAppUtils.IS_DEV;
    Vue.config.debug = SuiteAppUtils.IS_DEV;
    Vue.config.silent = !SuiteAppUtils.IS_DEV;

    // components
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);

    // plugins
    Vue.use(VueTheMask);
    Vue.use(ActionCableVue, {
        debug: false,
        debugLevel: SuiteAppUtils.IS_DEV ? "all" : "error",
        connectionUrl: `/cable?peer_id=${uuidv4()}`,
        connectImmediately: false,
        store: store,
    });
    Vue.use(VueLogger, {
        enabled: SuiteAppUtils.IS_DEV,
        log_level: "debug",
        store: store,
    });
    Vue.use(VueAWN, {
        position: "top-right",
        maxNotifications: 5,
        durations: {
            success: 2000,
        },
        icons: {
            enabled: false,
            prefix: "<i class='fas fa-fw fa-solid",
            suffix: "'></i>",
        },
        labels: {
            info: "<i class='fas fa-info-circle'></i>  Notification <i class='fas fa-fw fa-solid fa-times float-right'></i>",
            alert: "<i class='fas fa-exclamation-circle'></i>  Alert <i class='fas fa-fw fa-solid fa-times float-right'></i>",
            success:
                "<i class='fas fa-fw fa-solid fa-bell'></i>  Notification <i class='fas fa-fw fa-solid fa-times float-right'></i>",
            warning: "<i class='fas fa-exclamation-triangle'></i>  Warning <i class='fas fa-fw fa-solid fa-times float-right'></i>",
        },
    });

    // Render component with props
    const app = new Vue({
        render: (h) => h(App),
        router,
        store,
    });

    app.$mount("#suite-app");

    Vue.directive("lazyload", LazyLoadDirective);
};

document.addEventListener("DOMContentLoaded", () => {
    mount();
});
