const CommonMixin = {
  computed: {
    workspaces() {
      if (typeof this.$route.params.account_hashid === "string") {
        return this.all_workspaces
          .filter((w) => {
            // Filter workspaces based on account_hashid and where archived_at is null
            return w.account_hashid === this.$route.params.account_hashid && w.archived_at === null;
          })
          .map((w) => {
            // Update projects for each workspace
            w.projects = this.projects
              .sort((a, b) => a.name.localeCompare(b.name))
              .filter((p) => !p.archived && p.workspace && p.workspace.workspace_hashid === w.workspace_hashid);
            return w;
          });
      }
      return [];
    },
    current_account() {
      return this.accounts.find((a) => a.account_hashid === this.$route.params.account_hashid) || null;
    },
    current_workspace() {
      if (!this.workspaces || this.workspaces.length === 0) return null;
      return (
        this.workspaces.find((w) => w.workspace_hashid === this.$route.params.workspace_hashid) || this.workspaces[0]
      );
    },
    projects_by_account() {
      const account_hashid = this.$route.params.account_hashid;
      if (typeof account_hashid === "string") {
        return this.projects.filter((p) => p.account_hashid === account_hashid);
      }
      return [];
    },
    projects_by_routes() {
      const workspace_hashid = this.$route.params.workspace_hashid;
      if (typeof workspace_hashid === "string") {
        return this.projects_by_account.filter((p) => (p.workspace || {}).workspace_hashid === workspace_hashid);
      }
      return this.projects_by_account;
    },
  },
  methods: {
    async goToWorkspaceSettings() {
      await this.$router.push({
        name: "accounts.workspace.settings",
        params: {
          account_hashid: this.current_account.account_hashid,
        },
      });
    },
  },
};

export default CommonMixin;
