export const dragDrop = {
  bind(el, binding) {
    const state = {
      dragCounter: 0,
    };

    const handleDragEnter = (e) => {
      e.preventDefault();
      state.dragCounter++;

      if (e.dataTransfer?.types.includes("Files")) {
        binding.value?.onDragEnter?.(true);
      }
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      state.dragCounter--;

      if (state.dragCounter === 0) {
        binding.value?.onDragLeave?.(false);
      }
    };

    const handleDragOver = (e) => {
      e.preventDefault();
    };

    const handleDrop = (e) => {
      e.preventDefault();
      state.dragCounter = 0;
      binding.value?.onDragLeave?.(false);

      const files = e.dataTransfer?.files;
      if (files?.length) {
        binding.value?.onDrop?.(files);
      }
    };

    // Add listeners
    document.addEventListener("dragenter", handleDragEnter);
    document.addEventListener("dragleave", handleDragLeave);
    document.addEventListener("dragover", handleDragOver);
    document.addEventListener("drop", handleDrop);

    // Store cleanup function on element
    el._dragDropCleanup = () => {
      document.removeEventListener("dragenter", handleDragEnter);
      document.removeEventListener("dragleave", handleDragLeave);
      document.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("drop", handleDrop);
    };
  },
  unbind(el) {
    // Cleanup
    el._dragDropCleanup?.();
  },
};
