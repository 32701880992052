import {RouteConfig} from "vue-router";

/**
 * An array of route configuration objects
 * @type {RouteConfig[]}
 *  >> webpackChunkName: "dashboard" <<
 * where ever we mention this, it will group all components in one group.
 */
const DashboardRoutes = [
    {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: 'dashboard' */'./ProjectDashboard.vue'),
        children: [
            {
                path: "",
                name: 'dashboard.index',
                component: () => import(/* webpackChunkName: "dashboard" */'./pages/ProjectDashboardIndex.vue'),
                meta: { topbar: true, project_title: true}
            },
        ]
    }
]

export default DashboardRoutes