/**
 * List of WebSocket Channels
 * - NOTE: other-than `ProjectChannel` must need to pass `project_hashid` params while connected channels
 */
export const WSChannels = {


    ProjectChannel: "SuiteApp::ProjectChannel",
    ReleaseMEChannel: "SuiteApp::ContractTemplateChannel",
    CallSheetChannel: "SuiteApp::Documents::CallsheetChannel",
    DirectMEChannel: "SuiteApp::BroadcastChannel",
    DeliverMEChannel: "SuiteApp::VideoChannel",
    PaymeChannel: "SuiteApp::PaymeChannel",
    DocumentChannel: "SuiteApp::Documents::DocumentChannel",
    TaskManagerChannel: "SuiteApp::TaskChannel",
    ContactManagerChannel: "SuiteApp::ContactChannel",
    ProjectDashboard: 'SuiteApp::DashboardChannel',
    AdminChannel: "SuiteApp::Admin::AdminViewChannel",
    AnnouncementChannel: "SuiteApp::Admin::AnnouncementChannel",
    ProfileChannel: 'SuiteApp::ProfileChannel',
    TranscriptChannel: "SuiteApp::Admin::TranscriptChannel",
    CalendarEventChannel: "SuiteApp::CalendarEventChannel",
    ClipChannel: "SuiteApp::ClipChannel",
    ProductChannel: "SuiteApp::Admin::ProductSettingsChannel",
    WorkspaceChannel: "SuiteApp::WorkspaceChannel"

};

export const QuickSetupKeys = {
    CONTACT_MANAGER: 'contact_manager',
    RELEASE_ME: 'releaseme',
    DIRECT_ME: 'directme',
    DELIVER_ME: 'deliverme',
    CALLSHEET: 'callsheet'
};


export const QuickSetupActions = {
    [QuickSetupKeys.CONTACT_MANAGER]: "open.modal.team.new",
    [QuickSetupKeys.RELEASE_ME]: "open.modal.page.new",
    [QuickSetupKeys.DIRECT_ME]: "open.modal.new",
    [QuickSetupKeys.DELIVER_ME]: "open.modal.new",
    [QuickSetupKeys.CALLSHEET]: "open.modal.page.new",
}

export const MESSAGES = {
    CONFIRM_DURATION_CHANGE : "This will change the subscription billing cycle, are you sure you want to continue?",
    CONFIRM_SUBSCRIPTION_CANCEL : "This will cancel the subscription, are you sure you want to continue?"
}
// export const publishDownloadsProgress = per => $(".progress-bar").css({"width": `${per}%`}).text(`${per}%`)

